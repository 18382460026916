import { useEffect, useState } from 'react';
import { doc, setDoc, getDoc, runTransaction, getFirestore, updateDoc, query, collection, where, getDocs } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
//@ts-ignore
import { db, app } from "../firebase.ts";
//@ts-ignore
import { getAuth, signInWithEmailAndPassword, signInWithRedirect, onAuthStateChanged, getRedirectResult, OAuthProvider } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import conceptLogo from '../images/dity.events.png';
import { useData } from '../DataContext';
import '../styles/designs.css'
import { TbAxe } from 'react-icons/tb';

const pulse = `
    @keyframes pulse {
        0% { transform: scale(1); opacity: 0.4; }
        50% { transform: scale(1); opacity: 0.8; }
        100% { transform: scale(1); opacity: 1; }
    }
`;

const Authentication = () => {
    const { userData } = useData();
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [signInFlow, setSignInFlow] = useState(false)

    const auth = getAuth(app)
    auth.useDeviceLanguage()
    const firestore = getFirestore();

    const googleProvider = new GoogleAuthProvider();
    googleProvider.addScope('https://www.googleapis.com/auth/calendar');
    googleProvider.addScope('https://www.googleapis.com/auth/admin.directory.user.readonly');

    const microsoftProvider = new OAuthProvider('microsoft.com');
    microsoftProvider.addScope('User.Read');
    microsoftProvider.addScope('User.ReadBasic.All');
    microsoftProvider.addScope('Calendars.ReadWrite');

    const microsoftLogin = () => {
        signInWithRedirect(auth, microsoftProvider);
    };

    const googleLogin = () => {
      signInWithRedirect(auth, googleProvider).then(() => {
      });
  }

  useEffect(() => {
    getRedirectResult(auth).then(async (result) => {
      // Check if user is already signed in
      if (localStorage.getItem('uid')) {
        window.location.href= '/home';
      } else {
        setSignInFlow(true);
      }
  
      if (result) {
        setSignInFlow(false);
  
        const user = result.user;
        console.log("SIGNED IN USER: ", user);
  
        const userDocRef = doc(firestore, "users", user.uid);
  
        const userDocSnap = await getDoc(userDocRef);
  
        if (!userDocSnap.exists() || !userDocSnap.data().technicalInfo?.registrationDate) {
          const newUserData = {
            personalInfo: {
              name: user.displayName,
              email: user.email,
              phone: user.phoneNumber,
              profilePicture: user.photoURL
            },
            settings: {
              language: 'Norsk',
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Oslo'
            },
            technicalInfo: {
              lastActive: new Date().toISOString(),
              registrationDate: user.metadata.creationTime
            }
          };

        await setDoc(userDocRef, newUserData, { merge: true });
        console.log('User document updated with provider data');
      } else {
        const updateUserData = {
        technicalInfo: {
          lastActive: new Date().toISOString(),
          registrationDate: user.metadata.creationTime
        }}
        await setDoc(userDocRef, updateUserData, { merge: true });
        console.log('Brukeren er allerede registrert, oppdaterer ikke registreringsdata.');
      }

      localStorage.setItem('uid', user.uid);
  
        const providerId = result.providerId;
        let accessToken;
  
        if (providerId === 'google.com') {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          accessToken = credential?.accessToken;
          console.log("Google");
          window.location.href= '/home';
          // Handle Google-specific logic, if any
        } else if (providerId === 'microsoft.com') {
          const credential = OAuthProvider.credentialFromResult(result);
          accessToken = credential?.accessToken;
          console.log("Microsoft");
          // Handle Microsoft-specific logic, if any
        }

        console.log("Fortsettelse følger!!")
        if (accessToken) {
          console.log("VI KJØRER HER!!!")
          const functions = getFunctions(app, 'europe-west1');
          const getUserInfo = httpsCallable(functions, 'getUsersAndEventsMs');
        
          getUserInfo({ accessToken }).then(async (functionResult: any) => {
            console.log(functionResult.data);

                // Håndter oppdatering av brukerens kalenderhendelser
              if (functionResult.data.calendarEvents) {
                await updateDoc(userDocRef, {
                  calendarEvents: functionResult.data.calendarEvents
                });
                console.log("User's calendar events updated successfully.");
              }
        
            // Splitter brukerens e-post for å hente domenet
            const emailDomain = user.email?.split('@')[1];
            //const q = query(collection(db, "organizations"), where("domain", "==", emailDomain));
            const q = query(collection(db, "organizations"), where("domains", "array-contains", emailDomain));
            
            const querySnapshot = await getDocs(q);
            console.log("QUERY ", querySnapshot)
        
            if (querySnapshot.empty) {
              console.log("Ingen organisasjoner funnet med dette domenet.");
              return; 
            }
        
            const organizationDoc = querySnapshot.docs[0];
            const organizationId = organizationDoc.id;
            console.log("Organization ID found: ", organizationId);
        
            if (functionResult.data.users) {
              const orgDocRef = doc(firestore, "organizations", organizationId); 
              const orgDocSnap = await getDoc(orgDocRef);
              if (!orgDocSnap.exists()) {
                console.error("Organisasjonsdokumentet eksisterer ikke.");
                window.location.href = '/home';
                return;
              }
        
              const orgData = orgDocSnap.data();
              // Sikrer at vi alltid har et array å arbeide med, selv om membersMicrosoft ikke eksisterer
              const currentMembers = orgData.membersMicrosoft || [];
              const currentMemberIds = currentMembers.map((member: any) => member.id);
              const newMembers = functionResult.data.users.filter((user: any) => !currentMemberIds.includes(user.id));
        
              if (newMembers.length > 0) {
                // Oppdaterer dokumentet med den nye medlemslisten
                await updateDoc(orgDocRef, { membersMicrosoft: [...currentMembers, ...newMembers] });
                console.log("Organization document updated successfully.");
              } else {
                console.log("Ingen nye medlemmer å legge til.");
              }
              window.location.href = '/home';
            }
          }).catch((error: any) => {
            console.error("Error fetching additional user info:", error);
          });
        }
        
      }
    }).catch((error) => {
      console.error("Error during sign-in:", error.message);
      window.location.href = '/home';
    });
  }, [auth, firestore]);

    /*
    const loginUser = () => {
        signInWithEmailAndPassword(auth, email, password)
            .then((user) => {
                console.log("SIGNED IN USER: ", user)

                window.location.href = "/home"
                
            }).catch(error => {
                console.log("error signing in user: ", error.message)
            })

    }
    */
    
    return (
      <>
      {!signInFlow?
        <div className="bg-gray-100 flex min-h-full flex-1 mt-10">
        <div className="flex justify-center mx-auto pb-40 items-center min-h-screen bg-gray-100">
              <dity-loader size="120" color="#257e7b"></dity-loader>
            </div>
              </div>:
              
              

       
          <div> 
          <div className="bg-gray-100 flex min-h-full flex-1 mt-10">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
            <img
                    className="-ml-4 h-40 w-40"
                    src={conceptLogo}
                    alt="Your Company"
                    style={{
                        animation: 'pulse 8s infinite',
                        animationName: `${pulse}`,
                        transformOrigin: 'center'
                    }}
                />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Logg inn på din konto
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500">
                Ingen konto?{' '}
                <a href="/demo" className="font-semibold text-[#257E7B] hover:text-gray-500">
                  Book demo
                </a>
              </p>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">E-post</label>
                            <div className="mt-2">
                                <input id="email" name="email" type="email" autoComplete="email" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#257E7B] sm:text-sm sm:leading-6"
                                    onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Passord</label>
                            </div>
                            <div className="mt-2">
                                <input id="password" name="password" type="password" autoComplete="current-password" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#257E7B] sm:text-sm sm:leading-6"
                                    onChange={(e) => setPassword(e.target.value)} />
                            </div>
                        </div>

                        <div>
                        </div>
                    </form>
                    <button className="mb-4 flex w-full justify-center rounded-md bg-[#257E7B] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#257E7B]"
                       // onClick={() => loginUser()}
                    >Logg inn</button>


              <div className="relative mt-10">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="bg-white rounded-lg px-6 text-gray-900">Eller fortsett med</span>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-2 gap-4">
                <a
                  onClick={() => googleLogin()}
                  className="flex cursor-pointer w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
                >
                  <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                    <path
                      d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                      fill="#EA4335"
                    />
                    <path
                      d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                      fill="#34A853"
                    />
                  </svg>
                  <span className="text-sm font-semibold leading-6">Google</span>
                </a>
                <a
                  onClick={microsoftLogin}
                  className="flex cursor-pointer w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
                >
                  <img className="h-5 w-5" src="https://www.vectorlogo.zone/logos/microsoft/microsoft-icon.svg"></img>
                  <span className="text-sm font-semibold leading-6">Microsoft</span>
                </a>
              </div>
                </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />
        </div>
      </div>


          </div>
        }
        </>
    )

}

export default Authentication