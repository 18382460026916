import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'

export default function PrivacyPolicyPage() {
  return (
    <div className="relative bg-white">
      <div className="mx-auto max-w-7xl lg:flex lg:justify-between lg:px-8 xl:justify-end">
        <div className="lg:flex lg:w-1/2 lg:shrink lg:grow-0 xl:absolute xl:inset-y-0 xl:right-1/2 xl:w-1/2">
          <div className="relative h-80 lg:-ml-8 lg:h-auto lg:w-full lg:grow xl:ml-0">
            <img
              className="absolute inset-0 h-full w-full bg-gray-50 object-cover"
              src="https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=2560&h=3413&&q=80"
              alt=""
            />
          </div>
        </div>
        <div className="px-6 lg:contents">
          <div className="mx-auto max-w-2xl pb-24 pt-16 sm:pb-32 sm:pt-20 lg:ml-8 lg:mr-0 lg:w-full lg:max-w-lg lg:flex-none lg:pt-32 xl:w-1/2">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Vårt engasjement for ditt personvern</h1>
            <p className="mt-6 text-xl leading-8 text-gray-700">
              Din personvernrettigheter er viktige for oss. Her er en kort oversikt over dine rettigheter:
            </p>
            <ul role="list" className="mt-8 space-y-8 text-gray-600">
              <li className="flex gap-x-3">
                <CloudArrowUpIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                <span>
                  <strong className="font-semibold text-gray-900">Tilgang:</strong> Du har rett til å få tilgang til personopplysningene dine.
                </span>
              </li>
              <li className="flex gap-x-3">
                <LockClosedIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                <span>
                  <strong className="font-semibold text-gray-900">Retting:</strong> Du har rett til å få rettet feilaktige personopplysninger om deg.
                </span>
              </li>
              <li className="flex gap-x-3">
                <ServerIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                <span>
                  <strong className="font-semibold text-gray-900">Sletting:</strong> Du har rett til å få slettet personopplysninger om deg.
                </span>
              </li>
              {//Flere rettigheter kan legges til her
            }
            </ul>
            <p className="mt-8">
              For å utøve disse rettighetene, vennligst kontakt oss på e-post: <a href="mailto:haavard@dity.no" className="text-indigo-600">haavard@dity.no</a> eller via post: Sem Sælands vei 1, 7034 Trondheim, Norge. 
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Sikkerhet og internasjonale dataoverføringer</h2>
            <p className="mt-6">
              Vi tar sikkerheten for dine persondata alvorlig og har implementert tiltak for å beskytte mot uautorisert tilgang, bruk, endring og avsløring. Alle data overføres sikkert, lagres på servere innenfor EU/EØS og behandles i henhold til gjeldende personvernlovgivning.
                </p>
                <p className="mt-6">
                Personopplysningene vi samler inn vil bli oppbevart så lenge det er nødvendig for de formålene de ble samlet inn for. Vi vurderer regelmessig mengden, naturen og sensitiviteten av dataene vi holder, og vi sletter eller anonymiserer alle personopplysninger som ikke lenger er nødvendige.
                </p>
                <p className="mt-6">
                Vi forbeholder oss retten til å oppdatere vår personvernerklæring. Eventuelle endringer vil bli kommunisert tydelig på vår nettside eller direkte til deg, slik at du alltid er oppdatert om hvilken informasjon vi samler inn, hvordan vi bruker den, og under hvilke omstendigheter, om noen, vi deler den med andre parter.
                </p>
                <div className="mt-10">
                <p className="text-base leading-7 text-gray-700">
                Ditt personvern er viktig for oss. Dersom du har spørsmål eller bekymringer angående vår behandling av dine personopplysninger, eller hvis du ønsker å utøve noen av dine rettigheter, ikke nøl med å kontakte oss.
                </p>
                <p className="mt-8">
                Denne personvernerklæringen ble sist oppdatert den 25.03.2024.
                </p>
                </div>
                </div>
                </div>
                </div>
                </div>
                )
                }