import { InformationCircleIcon } from '@heroicons/react/20/solid'

export default function TermsOfServicePage() {
  return (
    <div className="bg-gray-100 px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Terms of Service - DITY EVENTS</h1>
        <p className="mt-4">These terms ("Terms of Service") govern the use of DITY's websites (dity.no) and mobile applications. The terms apply to all users of DITY's channels, including private individuals, companies, and other professional entities, whether you are just browsing or using the services to plan or purchase products or services. The channels are owned by DITY AS, organization number XXXX XX XXXX.</p>
        
        <h2 className="mt-12 text-2xl font-bold text-gray-900">User Account</h2>
        <p>To access DITY's services, you must create and log in with a Microsoft or Google account.</p>
        
        <h2 className="mt-12 text-2xl font-bold text-gray-900">Privacy</h2>
        <p>For us to provide you access to our services, such as planning an event on dity.no or contacting suppliers, we need to collect and process certain personal data about you. See our privacy statement for information on how we process personal data.</p>
        
        <h2 className="mt-12 text-2xl font-bold text-gray-900">Consent to Marketing</h2>
        <p>As a registered user of DITY's channels, you will be able to receive newsletters/marketing via registered email.</p>
        
        <h2 className="mt-12 text-2xl font-bold text-gray-900">User Generated Content</h2>
        <p>User-generated content includes all content you as a user of DITY's services create and/or post in DITY's channels, such as pictures, videos, music, ad texts, and other texts, graphic representations, logos, or similar. You guarantee that you have the necessary rights to the user-generated content, either because you are the author, or because the author has given you permission to use the user-generated content in DITY's channels.</p>
        
        <h2 className="mt-12 text-2xl font-bold text-gray-900">Intellectual Property</h2>
        <p>DITY owns the intellectual property rights to text, images, design, user interface, and other materials and information made available to you on DITY's services, as well as the underlying source code for the services. Such material may not be used in any way other than what a normal use of DITY's services necessitates.</p>
        
        <h2 className="mt-12 text-2xl font-bold text-gray-900">Liability Limitation</h2>
        <p>DITY does not guarantee continuous, uninterrupted, or secure access to DITY's channels and services. DITY's channels and services may occasionally be wholly or partially unavailable due to maintenance or for other reasons.</p>
        
        <h2 className="mt-12 text-2xl font-bold text-gray-900">Changes to the Services</h2>
        <p>DITY may at any time and without prior notice make continuous changes, including design and layout, search and presentation logic, and category structure. For significant changes, DITY will do its best to notify you in advance.</p>
        
        <h2 className="mt-12 text-2xl font-bold text-gray-900">Governing Law and Disputes</h2>
        <p>Norwegian law applies to the use of DITY's channels and the interpretation of these terms of service. In the event of any disputes between DITY and you as a user, you may bring the dispute to the Consumer Council for handling: <a href="https://www.forbrukerradet.no/klageguide/" className="text-indigo-600">https://www.forbrukerradet.no/klageguide/</a>.</p>
        
        <figcaption className="mt-12 flex gap-x-2 text-sm leading-6 text-gray-500">
          <InformationCircleIcon className="mt-0.5 h-5 w-5 flex-none text-gray-300" aria-hidden="true" />
          Please read these terms carefully as they contain important information regarding your legal rights and obligations.
        </figcaption>
      </div>
    </div>
  )
}