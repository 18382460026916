import React, { useState, useEffect } from 'react';
import { db, app } from "../../../firebase";
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { Dialog, Transition } from '@headlessui/react';
import { useData } from '../../../DataContext';

// Anta at dette er din oppdaterte Guest type
type Guest = {
    id: string;
    firstName: string;
    lastName: string;
    name: string;
    phone: string;
    email: string;
    specialNeeds: string;
    confirmed: boolean;
};

type AddGuestModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

export default function AddGuestModal({ isOpen, onClose }: AddGuestModalProps) {
    const [guests, setGuests] = useState<Guest[]>([]);
    const { eventData, eventId, setEventId } = useData();

    const [newGuest, setNewGuest] = useState<Guest>({
        id: Date.now().toString(),
        firstName: '',
        lastName: '',
        name: '',
        phone: '',
        email: '',
        specialNeeds: '',
        confirmed: false
    });

    const handleAddGuest = async () => {

        // Opprett en ny gjest med en unik id og alle nødvendige feltene
        const updatedGuest = { ...newGuest, name: newGuest.firstName + ' ' + newGuest.lastName , id: Date.now().toString() };
        console.log("UpdatedGuest ",updatedGuest)


        const eventRef = doc(db, "events", eventId); // Referanse til dokumentet i Firestore
        try {
            const eventDoc = await getDoc(eventRef);
            console.log("Nå prøves det")

            if (eventDoc.exists()) {
            const existingGuestList = eventDoc.data().guestList || [];
            const updatedGuestList = [...existingGuestList, updatedGuest];

            await updateDoc(eventRef, { guestList: updatedGuestList });

            } else {
            console.log("Event dokumentet finnes ikke!");
            }
        } catch (error) {
            console.error("Feil ved oppdatering av event:", error);
        }
        
        // Tilbakestill newGuest med en ny unik id og tømme andre felt
        setNewGuest({
            id: Date.now().toString(),
            firstName: '',
            lastName: '',
            name: '',
            phone: '',
            email: '',
            specialNeeds: '',
            confirmed: false
        });
    
       onClose();
    };

    return (        
        <Transition show={isOpen}>
        <Dialog open={isOpen} onClose={onClose}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Legg til ny gjest
                        </h3>

                        {/* Navn */}
                        <div className="mt-4">
                            <input
                                type="text"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
                                placeholder="Fornavn på gjest"
                                value={newGuest.firstName}
                                onChange={(e) => setNewGuest({ ...newGuest, firstName: e.target.value })}
                            />
                        </div>
                        <div className="mt-4">
                            <input
                                type="text"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
                                placeholder="Etternavn på gjest"
                                value={newGuest.lastName}
                                onChange={(e) => setNewGuest({ ...newGuest, lastName: e.target.value })}
                            />
                        </div>

                        {/* Kontaktinformasjon */}
                        <div className="mt-4">
                            <input
                                type="text"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
                                placeholder="Telefonnummer"
                                value={newGuest.phone}
                                onChange={(e) => setNewGuest({ ...newGuest, phone: e.target.value })}
                            />
                            <input
                                type="email"
                                className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
                                placeholder="E-postadresse"
                                value={newGuest.email}
                                onChange={(e) => setNewGuest({ ...newGuest, email: e.target.value })}
                            />
                        </div>

                        {/* Spesielle behov */}
                        <div className="mt-4">
                            <textarea
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
                                placeholder="Spesielle behov (f.eks. diett, tilgang)"
                                value={newGuest.specialNeeds}
                                onChange={(e) => setNewGuest({ ...newGuest, specialNeeds: e.target.value })}
                            />
                        </div>

                        {/* Bekreftet deltakelse */}
                        <div className="mt-4">
                            <label className="flex items-center">
                                <input
                                    type="checkbox"
                                    className="form-checkbox h-5 w-5 text-[#257E7B]"
                                    checked={newGuest.confirmed}
                                    onChange={(e) => setNewGuest({ ...newGuest, confirmed: e.target.checked })}
                                />
                                <span className="ml-2 text-sm text-gray-900 ">Bekreftet deltakelse</span>
                            </label>
                        </div>

                        {/* Knapper for å legge til og avbryte */}
                        <div className="mt-5 sm:mt-6 flex justify-end">
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-[#257E7B] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                onClick={handleAddGuest}
                            >
                                Legg til
                            </button>
                            <button
                                type="button"
                                className="ml-3 inline-flex justify-center rounded-md bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-[#257E7B] focus:ring-offset-2 focus:ring-offset-gray-100"
                                onClick={onClose}
                            >
                                Lukk
                            </button>
                        </div>
                    </div>
                </Dialog.Panel>
            </div>
        </div>
    </Dialog>
</Transition>            
    );
}