import React, { useState } from 'react';


// Test
import { Fragment, useEffect, useRef } from 'react'
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { Dialog, Menu, Transition } from '@headlessui/react'

type Allergy = {
    id: number;
    name: string;
    description: string;
};

interface Invitation {
    id: string;
    invitationName: string;
    eventId: string;
    description: string;
    // Du kan legge til flere felt her basert på din invitasjonsstruktur
}

interface EditInvitationModalProps {
    isOpen: boolean;
    invitation: Invitation | null;
    setEditInvitation: React.Dispatch<React.SetStateAction<{ id: string; invitationName: string; description: string;}>>;
    setIsOpen: (open: boolean) => void;
    closeButton: () => void;
    handleUpdateInvitation: (updatedInvitation: Invitation) => void;

}



/* function EditInvitationModal: React.FC<EditInvitationModalProps> = ({ isOpen, setIsOpen, invitationId }) => {
 */
function EditInvitationModal({  isOpen, setEditInvitation, setIsOpen, invitation, closeButton, handleUpdateInvitation}: EditInvitationModalProps) {
    // Hooks og funksjoner som hører til denne komponenten
    const commonAllergies: Allergy[] = [
        { id: 1, name: 'Peanøtter', description: '' },
        { id: 2, name: 'Trenøtter', description: '' },
        { id: 3, name: 'Melk', description: '' },
        { id: 4, name: 'Egg', description: '' },
        { id: 5, name: 'Fisk', description: '' },
        { id: 6, name: 'Skalldyr', description: '' }
    ];

    const [editedInvitation, setEditedInvitation] = useState({
        id: '',
        invitationName: '',
        eventId: '',
        description: '',
    });

    useEffect(() => {
        if (invitation) {
            setEditedInvitation({
                id: invitation.id,
                invitationName: invitation.invitationName,
                eventId: invitation.eventId,
                description: invitation.description,
            });
        }
    }, [invitation]);

    const resetInvitation = () => {
        if (invitation) {
            setEditedInvitation({
                id: invitation.id,
                invitationName: invitation.invitationName,
                eventId: invitation.eventId,
                description: invitation.description,
            });
        }
    };

    const onSave = () => {
        if (editedInvitation.id) {
            handleUpdateInvitation(editedInvitation);
        } else {
            console.error('Oppdatering feilet: Ingen gyldig ID for invitasjonen');
        }
        closeButton()
    };
    

    const [selectedAllergies, setSelectedAllergies] = useState<Set<number>>(new Set());
    const [otherAllergy, setOtherAllergy] = useState<string>('');

    const toggleAllergy = (id: number) => {
        const newSelectedAllergies = new Set(selectedAllergies);
        if (newSelectedAllergies.has(id)) {
            newSelectedAllergies.delete(id);
        } else {
            newSelectedAllergies.add(id);
        }
        setSelectedAllergies(newSelectedAllergies);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        // Logikk for å oppdatere invitasjonen
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        // Sjekk om 'Enter'-tasten ble trykket
        if (event.key === 'Enter') {
            event.preventDefault(); // Forhindre standard 'Enter'-tast oppførsel
            onSave(); // Kall din onSave funksjon
        }
    };

    if (!isOpen) return null;

    return (  
            <Dialog open={isOpen} onClose={() => {closeButton(); resetInvitation(); setIsOpen(false);}}>
                {/*
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-lg max-h-[80vh] overflow-y-auto">
            </div>
        </div> */}
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                        <div onKeyDown={handleKeyDown}>
                            <div className="mt-2">
                            <h3 className="text-center text-2xl font-medium leading-6 text-gray-900 mb-10">
                                {invitation?.invitationName}
                            </h3>


                                <h3 className="text-lg font-medium leading-6 text-gray-900 mb-5">
                                    Endre navn 
                                </h3>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
                                        value={editedInvitation.invitationName}
                                        onChange={(e) => setEditedInvitation({ ...editedInvitation, invitationName: e.target.value })}

                                    />
                                </div>

                                {/* Tekstfelt for beskrivelse */}
                                <h3 className="text-lg font-medium leading-6 text-gray-900 mt-5 mb-5">
                                    Endre beskrivelse
                                </h3>
                                <div className="mt-2">
                                    <textarea
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm h-32" // h-32 for høyere tekstfelt
                                        value={editedInvitation.description}
                                        onChange={(e) => setEditedInvitation({ ...editedInvitation, description: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6 flex justify-between">
                            {/* <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-[#257E7B] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                onClick={handleAddInvitation}
                            >
                                Legg til 
                            </button> */}
                            <button
                                type="button"
                                className={`inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100 ${
                                    editedInvitation.invitationName ? 'bg-[#257E7B] hover:bg-gray-700' : 'bg-gray-400'
                                }`}
                                onClick={onSave}
                               /*  onClick={() => setEditInvitation({ ...invitation, ...editedInvitation })} */
                                 // Knappen er deaktivert hvis navnet er tomt
                                
                            >
                                Lagre
                            </button>
                            <div>
                                <button
                                    type="button"
                                    onClick={() => {
                                        closeButton();
                                        resetInvitation();
                                    }}
                                    className="ml-3 inline-flex justify-center rounded-md bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                >
                                    Lukk
                                </button>
                            </div>
                        </div>
                        {/* <form onSubmit={handleSubmit}>
                            
                                <div className="space-y-12">
                                <div className="border-b border-gray-900/10 pb-12">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900"> Personlig Informasjon </h2>
                                    <p className="mt-1 text-sm leading-6 text-gray-600">Vennligst fyll inn feltene under.</p>

                                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-3">
                                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                        Fornavn
                                        </label>
                                        <div className="mt-2">
                                        <input
                                            type="text"
                                            name="first-name"
                                            id="first-name"
                                            autoComplete="given-name"
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                        Etternavn
                                        </label>
                                        <div className="mt-2">
                                        <input
                                            type="text"
                                            name="last-name"
                                            id="last-name"
                                            autoComplete="family-name"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-4">
                                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                        Email address
                                        </label>
                                        <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-4">
                                        <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                                        Telefon
                                        </label>
                                        <div className="mt-2 flex">
                                        <select
                                            id="country-code"
                                            name="country-code"
                                            autoComplete="country-code"
                                            className="rounded-l-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        >
                                            <option value="+47">+47</option>
                                            <option value="+1">+1</option>
                                            <option value="+44">+44</option>   
                                        </select>
                                        <input
                                            id="phone"
                                            name="phone"
                                            type="tel"
                                            autoComplete="tel"
                                            required
                                            pattern="[0-9]{8}"
                                            title="Telefonnummeret må bestå av 8 siffer"
                                            className="flex-1 rounded-r-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        </div>
                                    </div>
                                    </div>
                                    <div className="border-b border-gray-900/10 pb-12"> </div>
                                    <fieldset>
                                    <legend className="text-base font-semibold leading-6 text-gray-900 pt-5">Allergier</legend>
                                        <div className="space-y-5 pt-5">
                                        {commonAllergies.map((allergy) => (
                                            <div key={allergy.id} className="relative flex items-start">
                                            <div className="flex h-6 items-center">
                                                <input
                                                id={`allergy-${allergy.id}`}
                                                aria-describedby={`allergy-${allergy.id}-description`}
                                                name={`allergy-${allergy.id}`}
                                                type="checkbox"
                                                checked={selectedAllergies.has(allergy.id)}
                                                onChange={() => toggleAllergy(allergy.id)}
                                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                />
                                            </div>
                                            <div className="ml-3 text-sm leading-6">
                                                <label htmlFor={`allergy-${allergy.id}`} className="font-medium text-gray-900">
                                                {allergy.name}
                                                </label>{' '}
                                                <span id={`allergy-${allergy.id}-description`} className="text-gray-500">
                                                <span className="sr-only">{allergy.name} </span>{allergy.description}
                                                </span>
                                            </div>
                                            </div>
                                        ))}
                                        </div>
                                    </fieldset>
                                    <div className="mt-4">
                                        <label htmlFor="other-allergy" className="block text-sm font-medium leading-6 text-gray-900">
                                        Andre allergier
                                        </label>
                                        <div className="mt-2">
                                        <input
                                            type="text"
                                            name="other-allergy"
                                            id="other-allergy"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            placeholder="Skriv inn andre allergier"
                                            value={otherAllergy}
                                            onChange={(e) => setOtherAllergy(e.target.value)}
                                        />
                                        </div>
                                    </div>
                                    </div>
                                </div>         
                                <div className="mb-10 mt-6 flex items-center justify-end gap-x-6">
                                <button  type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={() => setIsOpen(false)}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Save
                                </button>
                                </div>
                            
                        </form>  */}
                        </Dialog.Panel>
                    </div>
                </div>
            </Dialog>
        
        
    );
};

export default EditInvitationModal;
