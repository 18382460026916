import React, { useState, useEffect } from 'react';
import { ChevronRightIcon, CheckIcon } from '@heroicons/react/20/solid';
import person from '../images/icons/Group26.png';
import Personal from '../components/config/Personal';
import Preferences from '../components/config/Preferences';
import Professional from '../components/config/Professional';
import { useData } from '../DataContext';
import { db, storage } from "../firebase";
import { doc, updateDoc, getDoc } from "firebase/firestore";

export default function ConfigPage() {
    const { userId, userData } = useData();
  const [steps, setSteps] = useState([
    { id: '01', name: 'Personlig informasjon', status: 'upcoming' },
    { id: '02', name: 'Organisasjon', status: 'upcoming' },
    { id: '03', name: 'Preferanser', status: 'upcoming' },
  ]);

  const handleCompleteSetup = async () => {
    try {
      // Step 1: Retrieve the organization document
      const orgId = userData.professionalInfo.organizationId;
      const orgRef = doc(db, "organizations", orgId);
      const orgDoc = await getDoc(orgRef);
      
      // Proceed only if the organization document exists
      if (orgDoc.exists()) {
        const orgData = orgDoc.data();
        // Step 2: Check if userId is already in the members array
        const members = orgData.members || [];
        if (!members.includes(userId)) {
          // Step 3: Update the organization document if userId is not in members
          await updateDoc(orgRef, {
            members: [...members, userId],
          });
        }
      }
  
      // Step 4: Update the user document as originally intended
      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, {
        configured: true,
        organizations: [orgId],
        selectedOrganization: orgId,
        selectedTeam: userData.professionalInfo.defaultTeamId,
        eventInfo: {
            currentEvents: [],
            pastEvents: []
          },
          alerts: [],
      });
  
      console.log("Kontoen er konfigurert");
    } catch (error) {
      console.error("Det oppstod en feil under oppdatering av brukerdokumentet: ", error);
      alert("Kunne ikke fullføre oppsettet.");
    }
  };

  const markStepAsComplete = (stepIndex: any) => {
    setSteps((prevSteps) =>
      prevSteps.map((step, index) => {
        if (index < stepIndex) {
          // Beholder status for tidligere steg som allerede kan være "complete"
          return step.status === 'complete' ? step : { ...step, status: 'upcoming' };
        } else if (index === stepIndex) {
          // Markerer det nåværende steget som 'complete'
          return { ...step, status: 'complete' };
        } else if (index === stepIndex + 1) {
          // Setter neste steg til 'current' kun hvis det nåværende steget nettopp ble fullført
          return { ...step, status: 'current' };
        }
        return step;
      })
    );
};

    useEffect(()=> {
        if(userData.personalInfo?.experience){
            markStepAsComplete(0)
        }

        if(userData.professionalInfo?.license){
            markStepAsComplete(1)
        }
        if(userData.settings.notifications?.pushNotifications){
            markStepAsComplete(2)
        }
    },[])

  const handleStepClick = (stepIndex: any) => {
    setSteps((prevSteps) => {
      const isCurrentStepComplete = prevSteps[stepIndex].status === 'complete';
      if (isCurrentStepComplete) {
        return prevSteps;
      }
      return prevSteps.map((step, index) => {
        if (index === stepIndex) {
          return { ...step, status: 'current' };
        }
        else if (step.status !== 'complete') {
          return { ...step, status: 'upcoming' };
        }
        return step;
      });
    });
  };

  const handleSetupAccount = () => {
    setSteps((prevSteps) =>
      prevSteps.map((step, index) =>
        index === 0 ? { ...step, status: 'current' } : step
      )
    );
};

  const getCurrentStepComponent = () => {
    const currentStep = steps.find(step => step.status === 'current');
    switch (currentStep?.id) {
        case '01':
            return <Personal markStepAsComplete={() => markStepAsComplete(0)} />;
          case '02':
            return <Professional markStepAsComplete={() => markStepAsComplete(1)} />;
          case '03':
            return <Preferences markStepAsComplete={() => markStepAsComplete(2)} />;
          default:
            return null;
    }
  };



  return (
    <>
    <nav aria-label="Progress">
      <ol role="list" className="mt-20 divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex md:flex-1">
            {step.status === 'complete' ? (
              <a onClick={() => handleStepClick(stepIdx)} className="group flex w-full items-center">
                <span className="cursor-pointer flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-[#257E7B] group-hover:bg-gray-800">
                    <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                </span>
              </a>
            ) : step.status === 'current' ? (
              <a onClick={() => handleStepClick(stepIdx)} className="cursor-pointer flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-[#257E7B]">
                  <span className="text-[#257E7B]">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-[#257E7B]">{step.name}</span>
              </a>
            ) : (
              <a onClick={() => handleStepClick(stepIdx)} className="cursor-pointer group flex items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                    <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                </span>
              </a>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
    <div className="relative isolate pt-7">
        <svg
          className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
        </svg>
        
        {steps.every(step => step.status === 'upcoming')?
        <div className="mx-auto max-w-7xl px-6 py-12 sm:py-16 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-20">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
            <div className="flex">
              <div className="relative flex items-center gap-x-4 rounded-full px-4 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                <span className="font-semibold text-[#EF9B84]">Versjon 1.1</span>
                <span className="h-4 w-px bg-gray-900/10" aria-hidden="true" />
                <a href="#" className="flex items-center gap-x-1">
                  <span className="absolute inset-0" aria-hidden="true" />
                  Oppdatert 01.04.2024
                  <ChevronRightIcon className="-mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                </a>
              </div>
            </div>
            <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Kom i gang med Dity Events!
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Nå trenger vi bare litt informasjon fra deg for å sette opp kontoen din. Du kan veldig snart komme i gang med å opprette eventer for din organisasjon!
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <a
                className="cursor-pointer rounded-md bg-[#EF9B84] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#EF9B84]"
                onClick={handleSetupAccount}
              >
                Sett opp konto
              </a>
              <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                Finn ut mer <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">

                <img src={person} alt="" className="w-60 h-auto" />
          </div>
        </div>
            :""}

{steps.every(step => step.status === 'complete')?
        <div className="mx-auto max-w-7xl px-6 py-12 sm:py-16 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-20">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
            <div className="flex">
             
            </div>
            <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Gratulerer, kontoen er klar!
            </h1>
            <div className="mt-10 flex items-center gap-x-6">
              <a
                className="cursor-pointer rounded-md bg-[#EF9B84] px-5 py-3 text-md font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#EF9B84]"
                onClick={handleCompleteSetup}
              >
                Kom i gang nå!
              </a>
            </div>
          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">

                <img src={person} alt="" className="w-60 h-auto" />
          </div>
        </div>
            :""}


             {getCurrentStepComponent()}
      </div>
    </>
  )
}
