import logo from '../images/dity.concept.black.png'
import React, { useState } from 'react';
import { useData } from '../DataContext';
import { SparklesIcon, ChartBarSquareIcon } from '@heroicons/react/24/outline'
import person from '../images/icons/Group26.png'
import { db, app } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";


import '../styles/designs.css'

export default function TipsPage() {
  const { userData } = useData();
  const functions = getFunctions(app, 'europe-west1');
  const [eventChoice, setEventChoice] = useState('');
  const [helpTopic, setHelpTopic] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [awaitingGeneration, setAwaitingGeneration] = useState(false);

  interface GeneratedAdviceResponse {
    generatedAdvice: string[]; // Anta at svaret er et array av strenger
  }
  
  const callDityGpt = async () => {
    const dityGpt = httpsCallable(functions, 'dityGpt');
    try {
      setAwaitingGeneration(true)
      const result = await dityGpt({ eventChoice, helpTopic, additionalInfo });
      // Bruk type assertion her
      const data = result.data as GeneratedAdviceResponse;
      if (Array.isArray(data.generatedAdvice)) {
        const formattedResponse = data.generatedAdvice.join('\n');
        setResponseMessage(formattedResponse);
        setAwaitingGeneration(false)
      } else {
        setResponseMessage(data.generatedAdvice || 'Ingen råd tilgjengelig.');
      }
    } catch (error) {
      console.error('Error calling dityGpt:', error);
      setResponseMessage('Beklager, det oppsto en feil. Prøv igjen senere.');
    }
  };
  
  const callDityGptEnhanced = async () => {
    const dityGptEnhanced = httpsCallable(functions, 'dityGptEnhanced');
    try {
      const result = await dityGptEnhanced({ eventChoice, helpTopic, additionalInfo });
      // Gjenta samme logikk som for callDityGpt
      const data = result.data as GeneratedAdviceResponse;
      if (Array.isArray(data.generatedAdvice)) {
        const formattedResponse = data.generatedAdvice.join('\n');
        setResponseMessage(formattedResponse);
      } else {
        setResponseMessage(data.generatedAdvice || 'Ingen råd tilgjengelig.');
      }
    } catch (error) {
      console.error('Error calling dityGpt:', error);
      setResponseMessage('Beklager, det oppsto en feil. Prøv igjen senere.');
    }
  };

    // Funksjon for å håndtere endringer i 'event-choice' select-feltet
    const handleEventChoiceChange = (e: any) => {
      setEventChoice(e.target.value);
    };
  
    // Funksjon for å håndtere endringer i 'help-topic' select-feltet
    const handleHelpTopicChange = (e: any) => {
      setHelpTopic(e.target.value);
    };
  
    // Funksjon for å håndtere endringer i 'message' textarea-feltet
    const handleAdditionalInfoChange = (e: any) => {
      setAdditionalInfo(e.target.value);
    };

    return (
      <>
        <div className=''> {/* Div with specifications for the "Main page" imported from designs.css */}
          <div className="relative isolate px-6 py-14 sm:py-20 lg:px-8">
            <svg
              className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                  width={200}
                  height={200}
                  x="50%"
                  y={-64}
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M100 200V.5M.5 .5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y={-64} className="overflow-visible fill-gray-50">
                <path
                  d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M299.5 800h201v201h-201Z"
                  strokeWidth={0}
                />
              </svg>
              <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
            </svg>
            <div className="mx-20">
              
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 sm:gap-y-20 ">
                <form  action="#"
                  method="POST"
                  className="lg:flex-auto"
                  onSubmit={(e) => e.preventDefault()}>
                <h2 className="text-4xl font-bold tracking-tight text-gray-900">Dity GPT</h2>
                <p className="mt-4 mb-8 text-lg leading-8 text-gray-600">
                <span className='font-semibold'>La oss snakke om eventet ditt.</span> Dity's AI-assistent hjelper deg umiddelbart med veien videre.
              </p>
                  <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                  <div>
                    <label htmlFor="event-choice" className="block text-sm font-semibold leading-6 text-gray-900">
                      Velg et event
                    </label>
                    <div className="mt-2.5">
                    <select
                        id="event-choice"
                        name="event-choice"
                        value={eventChoice}
                        onChange={handleEventChoiceChange}
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#217E7A] sm:text-sm sm:leading-6"
                      >
                    <option value="">Velg et event...</option>
                    {userData.eventInfo.currentEvents.map((event: any, index: any) => (
                      <option key={index} value={event.id}>{event.title}</option>
                    ))}
                  </select>
                    </div>
                    </div>
                    <div>
                      <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
                        Hva ønsker du hjelp til?
                      </label>
                      <div className="mt-2.5">
                      <select
                          id="help-topic"
                          name="help-topic"
                          value={helpTopic}
                          onChange={handleHelpTopicChange}
                          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#217E7A] sm:text-sm sm:leading-6"
                        >
                        <option value="">Velg et alternativ...</option>
                        <option value="planlegging">Planlegging av eventet</option>
                        <option value="inspirasjon">Inspirasjon og ideer</option>
                        <option value="taler">Hjelp til å lage taler</option>
                        <option value="markedsføring">Markedsføringsråd</option>
                        <option value="logistikk">Logistikk og koordinering</option>
                        <option value="annet">Annet</option>
                      </select>
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                        Mer informasjon
                      </label>
                      <div className="mt-2.5">
                      <textarea
                          id="message"
                          name="message"
                          rows={4}
                          value={additionalInfo}
                          onChange={handleAdditionalInfoChange}
                          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#217E7A] sm:text-sm sm:leading-6"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="mt-10 flex gap-8">
                    <button
                      onClick={callDityGpt}
                        type="submit"
                        /* className="flex items-center justify-center block w-full rounded-md bg-[#217E7A] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#217E7A]"
                         */
                        className='buttonSecondaryGreen justify-center w-full'
                    >
                      {awaitingGeneration ? (
                            <div role="status" className="flex justify-center items-center">
                            Vennligst vent <message-animation
                              size="70"
                              speed="3" 
                              color="white" 
                            ></message-animation>
                            </div>
                          ) : (
                          <>
                            <SparklesIcon className='text-white w-5 h-5 mr-4'></SparklesIcon>
                            <span>Få raskt svar</span>
                    
                          </>
                      )}
                    </button>
                  {false?  <button
                    onClick={callDityGptEnhanced}
                      type="submit"
                      className="flex mt-auto items-center justify-center block w-full rounded-md bg-gray-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#217E7A]"
                    >
                    <ChartBarSquareIcon className='text-white w-5 h-5 mr-4'></ChartBarSquareIcon>
                      <span>Få bedre svar</span>
                    </button>:""}
                  </div>
                  <p className="mt-4 text-sm leading-6 text-gray-500">
                    Ved å sende henvendelse samtykker du til{' '}
                    <a href="/terms-of-service" className="font-semibold text-[#217E7A]">
                      våre&nbsp;vilkår
                    </a>
                    .
                  </p>
                </form>
                <div className=" lg:flex-none">
                  <figure className="mt-10">
                    <blockquote className="text-md text-[#217E7A] leading-8 text-[#217E7A]">
                      
                        {responseMessage?<p>{responseMessage.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br /><br />
                        </React.Fragment>
                      ))}</p>:<p>
                        - Hei {userData.personalInfo.name}! Jeg kan svare på alt om ditt event, gi deg inspirasjon, hjelp til å lage taler, og generelt hjelpe deg videre med planleggingen!
                        </p>}
                    </blockquote>
                    <figcaption className="mt-10 flex gap-x-6">
                      <img
                        src={person}
                        alt=""
                        className="h-40 w-auto flex-none"
                      />
                      <div>
                        <div className="text-base font-semibold text-gray-900">Dity GPT</div>
                        <div className="text-sm leading-6 text-gray-600">Din AI-assistent</div>
                      </div>
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  