import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import {
  PencilIcon, PlusCircleIcon
} from '@heroicons/react/20/solid'
import AddTeammate from './AddTeammate'
import AddEvent from './AddEvent'
import { useData } from '../../DataContext'

  
  export default function Team() {
    const [teamMembers, setTeamMembers] = useState<Array<any>>([]);
    const [teamEvents, setTeamEvents] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAddTeammateOpen, setIsAddTeammateOpen] = useState(false);
    const [isAddEventOpen, setIsAddEventOpen] = useState(false);
    const { selectedTeam } = useData();

    const handleAddTeammateClick = () => {
      setIsAddTeammateOpen(true);
    };

  const handleCloseAddTeammate = () => {
    setIsAddTeammateOpen(false);
  };

  const handleAddEventClick = () => {
    setIsAddEventOpen(true);
  };

const handleCloseAddEvent = () => {
  setIsAddEventOpen(false);
};

    useEffect(() => {
      const fetchData = async () => {
        // Henter teammedlemmer
        if (selectedTeam?.users) {
          const users = await Promise.all(
            selectedTeam.users.map(async (userId: string) => {
              const userRef = doc(db, "users", userId);
              const userSnap = await getDoc(userRef);
              return userSnap.exists() ? { id: userSnap.id, ...userSnap.data() } : null;
            })
          );
          setTeamMembers(users.filter(user => user !== null));
        }
  
        // Henter events
        if (selectedTeam?.events) {
          const events = await Promise.all(
            selectedTeam.events.map(async (eventId: string) => {
              const eventRef = doc(db, "events", eventId);
              const eventSnap = await getDoc(eventRef);
              return eventSnap.exists() ? { id: eventSnap.id, ...eventSnap.data() } : null;
            })
          );
          setTeamEvents(events.filter(event => event !== null));
        }
  
        setIsLoading(false);
      };
  
      fetchData();
    }, [selectedTeam]);

  
    if (isLoading) {
      return <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <dity-loader size="60" color="#257e7b"></dity-loader>
    </div>;
    }

    return (
      <div className="bg-white py-6 sm:py-8 mb-10 rounded-xl">
        {isAddTeammateOpen && <AddTeammate onClose={()=>handleCloseAddTeammate()} />}
        {isAddEventOpen && <AddEvent onClose={()=>handleCloseAddEvent()} />}
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <div className="flex">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{selectedTeam?.name}</h2>
            
            <div className="ml-auto flex">
                    <PencilIcon className="h-5 w-5 text-gray-600 hover:text-gray-800 cursor-pointer" />
             </div>
             </div>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              {selectedTeam?.desc}
            </p>
            
          </div>
          <ul
            role="list"
            className="mx-auto mt-12 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-3 lg:mx-0 lg:max-w-none lg:grid-cols-3 "
          >
            {teamMembers?.map((person) => (
              <li key={person.UserId}>
                <img className="mx-auto h-24 w-24 rounded-full" src={person.personalInfo.profilePicture} alt="" />
                <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900">{person.personalInfo.name}</h3>
                <p className="text-sm leading-6 text-gray-600">{person.professionalInfo.position}</p>
              </li>
            ))}
            <PlusCircleIcon onClick={handleAddTeammateClick} className="m-auto h-12 w-12 text-gray-600 hover:text-gray-800 cursor-pointer mr-8" />
          </ul>

          <div className="mt-10">
        <h3 className="text-xl font-semibold text-gray-900">{selectedTeam?.name} sine events</h3>
        <ul role="list" className="divide-y divide-gray-200 grid grid-cols-3 gap-4">
          {teamEvents.map((event) => (
            <a href="/event-configuration/">
            <li key={event.id} className="my-4 py-4 rounded-xl bg-gray-100">    
              <p className="text-center text-sm font-bold text-gray-900">{event.title}</p>
              <p className="text-center py-2 text-sm text-gray-500">{event.eventDescription}</p>
              <img className="mx-auto h-24 w-24 object-cover rounded-lg mt-4" src={event.headerImage} alt="" />
            </li>
            </a>
          ))}
           <PlusCircleIcon onClick={handleAddEventClick} className="m-auto h-12 w-12 text-gray-600 hover:text-gray-800 cursor-pointer mr-8" />
        </ul>
      </div>
        </div>
        
      </div>
    )
  }
  