import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { text } from 'stream/consumers';

interface DeleteModalProps {
    /* invitationToDelete: string; */
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
    handleDeleteInvitation: () => Promise<void>; // Oppdatert til å være en asynkron funksjon
    closeButton: () => void;
    modalTitle: string;
    modalText: string;

}
/* invitationToDelete,
 */function DeleteModal({  isOpen, setIsOpen, handleDeleteInvitation, closeButton, modalText, modalTitle }: DeleteModalProps) {
    return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                        <div>
                            <div className="mt-2">
                                {/* <h3 className="text-lg font-medium leading-6 text-gray-900 text-center pb-2">Slett Invitasjon</h3> */}
                                <h3 className="text-lg font-medium leading-6 text-gray-900 text-center pb-2">{modalTitle}</h3>
                                <div className="mt-2 text-center p-2">
                                    {/* <p>Er du sikker på at du vil slette invitasjonen?</p> */}
                                    <p>{modalText}</p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6 flex justify-between">
                            <button
                                type="button"
                                onClick={closeButton}
                                className="ml-3 inline-flex justify-center rounded-md bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                            >
                                Avbryt
                            </button>
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                onClick={handleDeleteInvitation}
                            >
                                Slett
                            </button> 
                        </div>
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    );
}

export default DeleteModal;


/* 
exports.sendConfirmationEmail = functions.region("europe-west1").https.onCall(async (data, context) => {

  console.log("Ærlig forsøk på å sende epost")

  const { guestData, invitationData } = data;

  const msg = {
    to: guestData.email,
    from: 'noreply@dity.no',
    templateId: 'd-a24f381d20944a9dbe13f6774670bd45',
    dynamicTemplateData: {
      subject: `Bekreftelse: ${invitationData.invitationName}`,
      name: guestData.name,
      content: `Hei ${guestData.name}, Takk for at du svarte på invitasjonen til ${invitationData.invitationName}! Vi ser frem til din deltakelse.`
    },
  };

  console.log("Rett før vi sender epost");

  try {
    await sgMail.send(msg);
    console.log(`E-post sendt suksessfullt til: ${guestData.email}`);
    return { result: `E-post sendt suksessfullt til ${guestData.email}` };
  } catch (error) {
    console.error(`Feil ved sending av e-post til: ${guestData.email}`, error);
    return { error: `Feil ved sending av e-post til: ${guestData.email}` };
  }
});

*/