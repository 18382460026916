import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'; // React-icons for ikoner
import { CloseButton } from 'react-bootstrap';

interface AddInvitationModalProps {
    newInvitation: { id: string; name: string; description: string };
    setNewInvitation: React.Dispatch<React.SetStateAction<{ id: string; name: string; description: string }>>;
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
    handleAddInvitation: () => void;
    closeButton: () => void;
}

export default function AddInvitationModal({ 
    newInvitation, 
    setNewInvitation, 
    isOpen, 
    setIsOpen, 
    handleAddInvitation, 
    closeButton 
    }: AddInvitationModalProps) {

    return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                        <div>
                            <div className="mt-2">
                            <h3 className="text-center text-2xl font-medium leading-6 text-gray-900 mb-10">
                                Ny invitasjon
                            </h3>


                                <h3 className="text-lg font-medium leading-6 text-gray-900 mb-5">
                                    Legg til navn på arrangement
                                </h3>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
                                        value={newInvitation.name}
                                        onChange={(e) => setNewInvitation({ ...newInvitation, name: e.target.value })}
                                        placeholder='e.g "Bursdagsfeiring"'
                                    />
                                </div>

                                {/* Tekstfelt for beskrivelse */}
                                <h3 className="text-lg font-medium leading-6 text-gray-900 mt-5 mb-5">
                                    Beskrivelse
                                </h3>
                                <div className="mt-2">
                                    <textarea
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm h-32" // h-32 for høyere tekstfelt
                                        value={newInvitation.description}
                                        onChange={(e) => setNewInvitation({ ...newInvitation, description: e.target.value })}
                                        placeholder="Legg til en beskrivelse av arrangementet"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6 flex justify-between">
                            {/* <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-[#257E7B] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                onClick={handleAddInvitation}
                            >
                                Legg til 
                            </button> */}
                            <button
                                type="button"
                                className={`inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100 ${
                                    newInvitation.name ? 'bg-[#257E7B] hover:bg-gray-700' : 'bg-gray-400'
                                }`}
                                disabled={!newInvitation.name} // Knappen er deaktivert hvis navnet er tomt
                                onClick={handleAddInvitation}
                            >
                                Legg til
                            </button>
                            <div>
                                <button
                                    type="button"
                                    onClick={closeButton} 
                                    className="ml-3 inline-flex justify-center rounded-md bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                >
                                    Lukk
                                </button>
                            </div>
                        </div>
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    );
}

