import { useEffect, useState } from "react"
//@ts-ignore
import { db, app } from "../firebase.ts";
//@ts-ignore
import {
    query,
    collection,
    getDocs,
    where,
    DocumentData,
} from "firebase/firestore";
import { getAuth, signOut, onAuthStateChanged, getRedirectResult } from "firebase/auth";


import '../styles/designs.css';

const LandingPage = () => {

    const [user, setUser] = useState<any>() 

    const auth = getAuth(app)

    onAuthStateChanged(auth, (userlogin) => {
        if (userlogin) { 
            const uid = userlogin.uid
            
            setUser(userlogin.providerData)
        }
        else { 
            console.log("NO USER")
            setUser(null)
        }
    })

    

    
    useEffect(() => {
        
        getRedirectResult(auth)
        .then((usercallback) => {
        console.log("GOOGLE LOGIN RESULT: ", user)
    
            setUser(usercallback)
        
    })

    }, [])

    


    const logout = () => {

        
        signOut(auth)
        .then((signedOut) => { 
            
            window.location.href = "/authentication"
        }).catch((error) => {
            console.error("error signing out: ", error.message)
        })
        
    }

    

    return ( 

        <>
            <div> {/* Div with specifications for the "Main page" imported from designs.css */}
                {user ? <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8" style={{background: "linear-gradient(to right, rgb(58, 28, 113), rgb(215, 109, 119), rgb(255, 175, 123))", height:"100vh", width:"100vw"}}>
                    LANDINGSSIDE
                <button
                onClick={()=>logout()}>
                    logg ut
                </button>
                </div> : null}
            </div>
        </>
    )

}

export default LandingPage