import {
  BellIcon,
  CreditCardIcon,
  CubeIcon,
  FingerPrintIcon,
  UserCircleIcon,
  UsersIcon,
  ArrowRightOnRectangleIcon
} from '@heroicons/react/24/outline'
import { useData } from '../DataContext';
import '../styles/designs.css'
import { collection, addDoc, doc, getDoc, updateDoc } from "firebase/firestore"; 
import { db } from "../firebase";
import React, { useState } from 'react';
import { UpdateModal } from '../components/profile/UpdateModal';

const secondaryNavigation = [
  { name: 'Generelt', href: '#', icon: UserCircleIcon, current: true, disabled: false },
 // { name: 'Sikkerhet', href: '#', icon: FingerPrintIcon, current: false, disabled: true },
 // { name: 'Varslinger', href: '#', icon: BellIcon, current: false, disabled: true },
 // { name: 'Plan', href: '#', icon: CubeIcon, current: false, disabled: true },
 // { name: 'Fakturering', href: '#', icon: CreditCardIcon, current: false, disabled: true },
 // { name: 'Team', href: '#', icon: UsersIcon, current: false, disabled: true },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function Profile() {
  const { eventData, eventId, setEventData, setEventId, userData, userId } = useData();
  const [isModalOpen, setModalOpen] = useState(false);
  const [updateField, setUpdateField] = useState('');

  const showModal = (field: string) => {
    setUpdateField(field);
    setModalOpen(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('uid');
    window.location.href = '/authentication';
  };

  const duplicateEvent = async (originalEventId: string) => {
    try {
      // Hent det originale eventet fra Firestore
      const originalEventRef = doc(db, "events", originalEventId);
      const originalEventSnap = await getDoc(originalEventRef);
  
      if (!originalEventSnap.exists()) {
        console.log("Ingen event funnet!");
        return;
      }
  
      const originalEventData = originalEventSnap.data();
  
      // Opprett en kopi av eventet i 'events'-samlingen
      const newEventRef = await addDoc(collection(db, "events"), {
        ...originalEventData,
        title: `Kopi av ${originalEventData.title}`,
      });
  
      // Oppdater brukerens 'currentEvents' med det nye eventet
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);
  
      if (userSnap.exists()) {
        const userData = userSnap.data();
        const updatedEvents = [
          ...userData.eventInfo.currentEvents,
          { title: `Kopi av ${originalEventData.title}`, id: newEventRef.id },
        ];
  
        await updateDoc(userRef, {
          "eventInfo.currentEvents": updatedEvents,
        });
  
        console.log("Event duplisert og lagt til brukerens liste");
      }
    } catch (error) {
      console.error("Feil ved duplisering av event:", error);
    }
  };
  

  return (
    <div> {/* Div with specifications for the "Main page" imported from designs.css */}
      <div className="bg-gray-100">
      <UpdateModal
        userId={userId}
        field={updateField}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      />
      <div className="mx-auto max-w-7xl pt-16 lg:flex lg:gap-x-16 lg:px-8">
        <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
          <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
  {secondaryNavigation.map((item) => (
    <li key={item.name}>
      <a
        href={item.href}
        className={classNames(
          item.current
            ? 'bg-gray-50 text-[#227E7B]'
            : 'text-gray-700 hover:text-[#227E7B] hover:bg-gray-50',
          'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold',
          item.disabled ? 'pointer-events-none text-gray-300' : ''
        )}
      >
        <item.icon
          className={classNames(
            item.current ? 'text-[#227E7B]' : 'text-gray-400 group-hover:text-gray-600',
            'h-6 w-6 shrink-0',
            item.disabled ? 'text-gray-500' : ''
          )}
          aria-hidden="true"
        />
        {item.name}
      </a>
    </li>
  ))}
  <li className="flex border-t border-gray-100 pt-6 pl-3">
  <ArrowRightOnRectangleIcon
          className={classNames(
            'text-gray-400 group-hover:text-gray-600',
            'h-6 w-6 shrink-0',
          )}
          aria-hidden="true"
        />
        <button
          type="button"
          className="text-sm font-semibold leading-6 text-[#227E7B] hover:text-gray-500 pl-2"
          onClick={handleLogout} // Legg til onClick event her
        >
          Logg ut
        </button>
        </li>
</ul>
          </nav>
        </aside>

        <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Profil</h2>
              <p className="mt-1 text-sm leading-6 text-gray-500">
                Denne informasjonen vil være offentlig synlig så pass på hva du deler.
              </p>

              <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Fullt navn</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{userData.personalInfo.name}</div>
                    <button type="button" className="font-semibold text-[#227E7B] hover:text-gray-500" onClick={() => showModal('personalInfo.name')}>
                      Oppdater
                    </button>
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">E-post</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{userData.personalInfo.email}</div>
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Telefonnummer</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{userData.personalInfo.phone}</div>
                    <button type="button" className="font-semibold text-[#227E7B] hover:text-gray-500" onClick={() => showModal('personalInfo.phone')}>
                      Oppdater
                    </button>
                  </dd>
                </div>
              </dl>
            </div>

            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Organisasjon</h2>
              <p className="mt-1 text-sm leading-6 text-gray-500">Se valgt organisasjon.</p>
              <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Navn</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{userData.professionalInfo.organizationName}</div>
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Stilling</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{userData.professionalInfo.position}</div>
                    <button type="button" className="font-semibold text-[#227E7B] hover:text-gray-500" onClick={() => showModal('professionalInfo.role')}>
                      Oppdater
                    </button>
                  </dd>
                </div>
              </dl>
            </div>

            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Events</h2>
              <p className="mt-1 text-sm leading-6 text-gray-500">Se alle dine events.</p>

              <ul role="list" className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                {userData.eventInfo?.currentEvents?.map((event: any) => (
                  <li key={event.id} className="flex justify-end gap-x-6 py-6">
                    <div className="font-medium text-gray-900 mr-auto">{event.title}</div>
                    <button
                      type="button"
                      className="font-semibold text-[#227E7B] hover:text-gray-500"
                      onClick={() => {
                        duplicateEvent(event.id)
                      }}
                    >
                      Dupliser
                    </button>
                    <button
                      type="button"
                      className="font-semibold text-[#227E7B] hover:text-gray-500"
                      onClick={() => {
                        localStorage.setItem('currentEventId', event.id);
                        window.location.href='/event-configuration/'+event.id
                      }}
                    >
                      Til event
                    </button>
                  </li>
                ))}
              </ul>

              <div className="flex border-t border-gray-100 pt-6">
                <button type="button" className="text-sm font-semibold leading-6 text-[#227E7B] hover:text-gray-500"
                onClick={() => {
                  window.location.href='/create-event'
                }}>
                  <span aria-hidden="true">+</span> Legg til nytt event
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>

    </div>
  )
}
