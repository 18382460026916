import { ClockIcon } from '@heroicons/react/20/solid';
import { useData } from '../../DataContext';
import { useEffect, useState } from 'react';

interface Event {
  id: number;
  name: string;
  time: string;
  datetime: string;
  href: string;
}

interface Day {
  date: string;
  isCurrentMonth: boolean;
  isToday?: boolean;
  isSelected?: boolean;
  events: Event[];
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function MonthView() {
  const { userData } = useData();
  const [days, setDays] = useState<Day[]>([]);
  const [selectedDay, setSelectedDay] = useState<Day | null>(null);

  useEffect(() => {
    setDays(generateCalendarDays());
    setSelectedDay(days[0] || null); // Sets the first day or null if there are no days
  }, [userData]);

  const formatTime = (dateTime: string) => {
    try {
      // Parse the dateTime string to a Date object
      let date = new Date(dateTime);
      
      // Add two hours to adjust for the timezone offset (e.g., CEST UTC+2)
      date.setHours(date.getHours() + 2);
  
      // Return the time in 'HH:mm' format
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } catch (error) {
      console.error("Failed to parse dateTime", error);
      return "Invalid time";
    }
  };

  const generateCalendarDays = (): Day[] => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    let date = new Date(currentYear, currentMonth, 1);
    const daysInMonth: Day[] = [];

    while (date.getMonth() === currentMonth) {
      const dateStr = date.toISOString().split('T')[0];
      const dayEvents = userData.calendarEvents.filter((event: any) => 
        event.start?.dateTime.startsWith(dateStr)
      ).map((event: any) => ({
        id: event.id,
        name: event.subject || 'No Title',
        time: event.start?.dateTime ? formatTime(event.start.dateTime) : 'No Time',
        datetime: event.start?.dateTime,
        href: event.webLink || '#'
      }));

      daysInMonth.push({
        date: dateStr,
        isCurrentMonth: true,
        isToday: new Date().toISOString().split('T')[0] === dateStr,
        events: dayEvents
      });

      date.setDate(date.getDate() + 1);
    }

    return daysInMonth;
  };

  
  return (
    <div className="bg-gray-100 lg:flex lg:h-full lg:flex-col">
      <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
        <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
          {['Man', 'Tirs', 'Ons', 'Tors', 'Fre', 'Lør', 'Søn'].map(day => (
            <div key={day} className="bg-white py-2 rounded-lg">
              {day.substring(0, 1)}<span className="sr-only sm:not-sr-only">{day.substring(1)}</span>
            </div>
          ))}
        </div>
        <div className="flex text-xs leading-6 text-gray-700 lg:flex-auto">
          <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-5 lg:gap-px">
            {days.map((day) => (
              <div key={day.date} className={`relative px-3 py-2 rounded-lg transition duration-300 ease-in-out ${day.isCurrentMonth ? 'bg-white hover:bg-gray-100' : 'bg-gray-50 text-gray-500'}`}>
                <time dateTime={day.date} className={`flex h-6 w-6 items-center justify-center rounded-full font-semibold ${day.isToday ? 'bg-[#217E7A] text-white' : 'text-gray-900'}`}>
                  {day.date.split('-').pop()?.replace(/^0/, '') ?? ''}
                </time>
                {day.events.length > 0 && (
                  <ol className="mt-2">
                    {day.events.slice(0, 2).map((event) => (
                      <li key={event.id}>
                        <a href={event.href} className="group flex">
                          <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-gray-600">
                            {event.name}
                          </p>
                          <time dateTime={event.datetime} className="ml-3 hidden flex-none text-gray-500 group-hover:text-gray-600 xl:block">
                            {event.time}
                          </time>
                        </a>
                      </li>
                    ))}
                    {day.events.length > 2 && <li className="text-gray-500">+ {day.events.length - 2} more</li>}
                  </ol>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {selectedDay && selectedDay.events.length > 0 && (
        <div className="px-4 py-10 sm:px-6 lg:hidden">
          <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
            {selectedDay.events.map((event) => (
              <li key={event.id} className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50">
                <div className="flex-auto">
                  <p className="font-semibold text-gray-900">{event.name}</p>
                  <time dateTime={event.datetime} className="mt-2 flex items-center text-gray-700">
                    <ClockIcon className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    {event.time}
                  </time>
                </div>
                <a href={event.href} className="ml-6 flex-none self-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 opacity-0 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:opacity-100 group-hover:opacity-100">
                  Rediger<span className="sr-only">, {event.name}</span>
                </a>
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );
}