import React, { Fragment, useState } from 'react';
import { Dialog, Transition,  Menu } from '@headlessui/react';
import { XMarkIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Supplier } from './SupplierCard';
import '../../../styles/designs.css'; 

interface SupplierDetailProps {
  supplier: Supplier;
  open: boolean;
  onClose: () => void;
  onSendMessage: (supplierId: string, userId: string) => void;
  userId: string;
}


function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function SupplierDetail({ supplier, open, onClose, onSendMessage, userId }: SupplierDetailProps) {
    const [showNumber, setShowNumber] = useState(false);

    const supplierInfo = [
        {
          title: 'Tjenester fra '+supplier.name,
          items: supplier.services, 
          color: 'green',
        },
        {
          title: 'Stil',
          items: supplier.style,
          color: 'blue',
        },
        {
          title: 'Produkt-typer',
          items: supplier.categories,
          color: 'yellow',
        },
        // Legg til flere kategorier her om nødvendig
      ];


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">Leverandørprofil - Ta kontakt direkte i Dity Events</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-gray-500"
                            onClick={() => onClose()}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    
{/* Main content */}
<div className="divide-y divide-gray-200">
          <div className="pb-6">
            {/* Top Image and Title Section */}
            <div className="h-24 bg-[#227E7B] sm:h-20 lg:h-28" />
            <div className="-mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6 lg:-mt-16">
              <div>
                <div className="-m-1 flex">
                  <div className="inline-flex overflow-hidden rounded-lg border-4 border-white">
                    <img
                      className="h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"
                      src={supplier.profileImage}
                      alt={supplier.name}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-6 sm:ml-6 sm:flex-1">
                <div>
                  <div className="flex items-center">
                    <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">{supplier.name}</h3>
                    {/* ... Øvrige deler av denne seksjonen ... */}
                  </div>
                  <p className="text-sm text-gray-500">{supplier.email}</p>
                </div>
                <div className="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
                              <button
                                type="button"
                                /* className="inline-flex w-full flex-shrink-0 items-center justify-center rounded-md bg-[#227E7B] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 sm:flex-1"
                                 */
                                className='buttonSecondaryGreen w-full justify-center sm:flex-1'
                                onClick={() => onSendMessage(supplier.id, userId)}
                              >
                                Send melding
                              </button>
                              <button
                                type="button"
                                onClick={() => setShowNumber(true)}
                                /* className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                               */
                                className='buttonTernaryWhiteBorder w-full justify-center sm:flex-1'
                              >
                                {showNumber ? (
                                  <>{"+47 "+supplier.phone}</>
                                ):(
                                  <>Ring</>
                                )}
                              </button>
                              <div className="ml-3 inline-flex sm:ml-0">
                                <Menu as="div" className="relative inline-block text-left">
                                  <Menu.Button 
                                    /* className="relative inline-flex items-center rounded-md bg-white p-2 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                   */
                                    className='buttonTernaryWhiteBorder'
                                  >
                                    <span className="absolute -inset-1" />
                                    <span className="sr-only">Open options menu</span>
                                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                  </Menu.Button>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <div className="py-1">
                                        <Menu.Item>
                                          {({ active }) => (
                                            <a
                                              href="#"
                                              className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                              )}
                                            >
                                                 Se anmeldelser
                                            </a>
                                          )}
                                        </Menu.Item>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <a
                                              href="#"
                                              className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                              )}
                                            >
                                                    Rapporter inaktivitet
                                            </a>
                                          )}
                                        </Menu.Item>
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            </div>
                </div>
            </div>
          </div>
          </div>

          <div className="px-4 py-5 sm:px-0 sm:py-0">
            <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
              <div className="sm:flex sm:px-6 sm:py-5">
                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">Adresse</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                  {supplier.address}, {supplier.postalCode} {supplier.city}
                </dd>
              </div>
              
            </dl>
          </div>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 p-4">
      {supplierInfo.map((info, index) => (
        <div
          key={index}
          className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-gray-500 focus-within:ring-offset-2 hover:border-gray-400"
        >
          <div className="flex-shrink-0">
            {/* Du kan legge til et ikon eller bilde her */}
          </div>
          <div className="min-w-0 flex-1">
              <span className="absolute inset-0" aria-hidden="true" />
              <p className="text-sm font-medium text-gray-900">{info.title}</p>
              <div className="flex flex-wrap gap-2 mt-2">
                {info.items.map((item, itemIndex) => (
                  <span key={itemIndex} className={`inline-flex items-center rounded-full bg-${info.color}-50 px-2.5 py-0.5 text-xs font-medium text-${info.color}-800 ring-1 ring-inset ring-${info.color}-500/10`}>
                    {item}
                  </span>
                ))}
              </div>
          </div>
        </div>
      ))}
    </div>


                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}