import { CheckCircleIcon } from '@heroicons/react/20/solid'
import person4 from '../../images/icons/Group 27 (1).png'


const benefits = [
    'Sanntids oversikt over utstyr i alle organisasjoner',
    'Enkel booking og automatiserte påminnelser for utstyrsretur',
    'Tilpassede brukerprofiler med rollebasert tilgangsstyring',
    'Integrerte vedlikeholdsplaner for optimal utstyrsstatus',
    'Mobiltilgang for booking og administrasjon på farten',
    'Avanserte rapporteringsverktøy for effektiv ressursforvaltning'
  ];

export default function NotConfigured() {
  return (
    <div className=" py-12 sm:py-20 rounded-3xl">
      <div className="relative isolate">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-white px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
          <img
              className="h-60 flex-none "
              src={person4}
            />
            <div className="w-full flex-auto">
              <h2 className="text-3xl font-bold tracking-tight text-[#217E7A] sm:text-4xl">Få oversikt og reserver felles utstyr</h2>
              <p className="mt-6 text-lg leading-8 text-gray-500">
                
Få oversikt og reserver felles utstyr enkelt og effektivt. Vår løsning gir deg muligheten til å håndtere organisasjonens utstyr på en sentralisert plattform.
              </p>
              <ul
                role="list"
                className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-[#217E7A] sm:grid-cols-2"
              >
                {benefits.map((benefit) => (
                  <li key={benefit} className="flex gap-x-3">
                    <CheckCircleIcon className="h-7 w-5 flex-none" aria-hidden="true" />
                    {benefit}
                  </li>
                ))}
              </ul>
              <div className="mt-10 flex">
                <a href="#" className="bg-[#217E7A] rounded-lg p-4 text-md font-bold leading-6 text-white">
                  Book demo og konfigurer <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  )
}
