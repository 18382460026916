import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import '../../../styles/designs.css';



interface AddTaskModalProps {
    newTask: { id: string; title: string; desc: string; done: boolean };
    setNewTask: React.Dispatch<React.SetStateAction<{ id: string; title: string; desc: string; done: boolean }>>;
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
    handleAddTask: () => void; // Antatt type, oppdater om nødvendig
  }
  


function AddTaskModal({ newTask, setNewTask, isOpen, setIsOpen, handleAddTask }: AddTaskModalProps) {
return (
    <Transition show={isOpen}>
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
            <div className="absolute inset-0 overflow-hidden">
                <Transition.Child
                    as="div"
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                />     
                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                        <Transition.Child
                            as="div"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                            className="w-screen max-w-md"
                        >
                            <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                                <div className="mt-5 px-4 py-6 sm:px-6">
                                    <Dialog.Title className="text-lg font-medium text-gray-900 pb-2">Legg til ny oppgave</Dialog.Title>
                                    {/* Tittel input */}
                                    <div className="isolate -space-y-px rounded-md shadow-sm">
                                        {/* Tittel input */}
                                        <div className="relative rounded-md rounded-b-none px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-[#257E7B]">
                                            <label htmlFor="title" className="block text-xs font-medium text-gray-900">
                                                Tittel
                                            </label>
                                            <input
                                                type="text"
                                                name="title"
                                                id="title"
                                                value={newTask.title}
                                                onChange={(e) => setNewTask({ ...newTask, title: e.target.value })}
                                                className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                placeholder="Oppgavetittel"
                                            />
                                        </div>
                                        {/* Beskrivelse input */}
                                        <div className="relative rounded-md rounded-t-none px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-[#257E7B]">
                                            <label htmlFor="desc" className="block text-xs font-medium text-gray-900">
                                                Beskrivelse
                                            </label>
                                            <input
                                                type="text"
                                                name="desc"
                                                id="desc"
                                                value={newTask.desc}
                                                onChange={(e) => setNewTask({ ...newTask, desc: e.target.value })}
                                                className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                placeholder="Oppgavebeskrivelse"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex mt-4 space-x-2">
                                        <button
                                            type="button"
                                            onClick={handleAddTask}
                                            /* className="inline-flex items-center gap-x-2 rounded-md bg-[#257E7B] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#257E7B]"
                                            */
                                           className='buttonSecondaryGreen'

                                        >
                                            <CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                            Legg til
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => setIsOpen(false)}
                                            /* className="inline-flex items-center gap-x-2 rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                            */
                                           className='buttonTernaryGray'
                                        
                                        >
                                            <XCircleIcon className="h-5 w-5" aria-hidden="true" />
                                            Lukk
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>    
                </div>
            </Dialog>
        </Transition>
    );
}

export default AddTaskModal;