/*eslint-disable*/
import { useState, useEffect } from 'react';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import {
  CakeIcon,
  WrenchIcon,
  BuildingOfficeIcon,
  TruckIcon,
  LightBulbIcon,
  PaperAirplaneIcon,
  HomeModernIcon,
  SwatchIcon,
  CameraIcon,
  MicrophoneIcon,
  AcademicCapIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/24/outline';
import { useData } from '../DataContext';
import { db, app } from "../firebase";
import { doc, addDoc, collection, updateDoc, arrayUnion } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { helix } from 'ldrs'
helix.register("event-animation")
import { lineWobble } from 'ldrs'
lineWobble.register("create-event-animation")
import '../styles/designs.css'





const colorClasses: any = {
  Catering: "bg-red-100 text-red-700 hover:bg-red-600/20",
  LydOgLys: "bg-blue-100 text-blue-700 hover:bg-blue-600/20",
  Lokaler: "bg-yellow-100 text-yellow-700 hover:bg-yellow-600/20", 
  Transport: "bg-green-100 text-green-700 hover:bg-green-600/20", 
  Aktiviteter: "bg-indigo-100 text-indigo-700 hover:bg-indigo-600/20",
  TakeAway: "bg-purple-100 text-purple-700 hover:bg-purple-600/20", 
  Restaurant: "bg-pink-100 text-pink-700 hover:bg-pink-600/20", 
  DekorOgDesign: "bg-orange-100 text-orange-700 hover:bg-orange-600/20", 
  FotoOgVideo: "bg-teal-100 text-teal-700 hover:bg-teal-600/20", 
  Foredragsholder: "bg-lime-100 text-lime-700 hover:bg-lime-600/20", 
  Underholdning: "bg-cyan-100 text-cyan-700 hover:bg-cyan-600/20", 
};

const categories = [
  { name: 'Catering', href: '#', icon: CakeIcon, current: false },
  { name: 'LydOgLys', href: '#', icon: WrenchIcon, current: false },
  { name: 'Lokaler', href: '#', icon: BuildingOfficeIcon, current: false },
  { name: 'Transport', href: '#', icon: TruckIcon, current: false },
  { name: 'Aktiviteter', href: '#', icon: LightBulbIcon, current: false },
  { name: 'TakeAway', href: '#', icon: PaperAirplaneIcon, current: false },
  { name: 'Restaurant', href: '#', icon: HomeModernIcon, current: false },
  { name: 'DekorOgDesign', href: '#', icon: SwatchIcon, current: false },
  { name: 'FotoOgVideo', href: '#', icon: CameraIcon, current: false },
  { name: 'Foredragsholder', href: '#', icon: AcademicCapIcon, current: false },
  { name: 'Underholdning', href: '#', icon: MicrophoneIcon, current: false },
];

type SearchResult = {
  display_name: string;
  // Legg til andre felt her basert på API-responsen
  // For eksempel:
  // lat: string;
  // lon: string;
};

function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);


  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default function CreateEventPage() {
  const [locationQuery, setLocationQuery] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(locationQuery, 300);
  const [selectedCategories, setSelectedCategories] = useState(categories);
  const [eventDescription, setEventDescription] = useState('');
  const { userData, setUserData, userId, setEventId, eventId  } = useData();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  


  const handleTriggerFunctionClick = async (newEventId: string) => {
    const functions = getFunctions(app, 'europe-west1');
    const triggerFunction = httpsCallable(functions, 'aiGenerateInspirationArticles');
    const triggerFunction2 = httpsCallable(functions, 'aiGenerateEventPlanningTips');
    
    try {
        // Endre nøkkelnavnet her til clientReferenceId
        const result = await triggerFunction({ clientReferenceId: newEventId });
        console.log(result.data);
        const result2 = await triggerFunction2({ clientReferenceId: newEventId });
        console.log(result2.data);
        // Håndter resultatet som du ønsker
    } catch (error) {
        console.error('Error calling function:', error);
    }
};

  function Alert({ message }: any) {
    return (
      <div className="rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">Attention needed</h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>{message}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }



  const toggleCategory = (name: any) => {
    setSelectedCategories(selectedCategories.map(category =>
      category.name === name ? { ...category, current: !category.current } : category
    ));
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setFormSubmitted(true);

    if (!event.currentTarget.checkValidity()) {
      setShowAlert(true);
      return;
    }

    setShowAlert(false);

  
    const selectedCategoryNames = selectedCategories
      .filter(category => category.current)
      .map(category => category.name);
  
    const newEvent = {
      title: event.target.eventName.value,
      eventDescription,
      creationDate: new Date().getTime(),
      budget: event.target.budget.value,
      numberGuests: event.target.numberGuests.value,
      location: locationQuery,
      supplierCategories: selectedCategoryNames,
      budgetItems: [],
      date: new Date(event.target.eventDate.value).getTime(),
      guestList: [],
      invitationIds: [],
      toDoList: [],
      users: [{ uid: userId }]
    };
  
    try {
      // Legge til det nye eventet i 'events'-samlingen
      const eventRef = await addDoc(collection(db, "events"), newEvent);
  
      // Oppdater brukerens dokument i 'users'-samlingen
      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, {
        'eventInfo.currentEvents': arrayUnion({ id: eventRef.id, title: newEvent.title })
      });

      await handleTriggerFunctionClick(eventRef.id)
  
      console.log("Event opprettet med ID:", eventRef.id);
      setEventId(eventRef.id)
      localStorage.setItem('currentEventId', eventRef.id);
      //window.location.href = "/event-configuration/"+eventRef.id;
    } catch (error) {
      console.error("Feil ved oppretting av event:", error);
    }
  };
  

  useEffect(() => {
    if (debouncedSearchTerm && !debouncedSearchTerm.includes(',') && debouncedSearchTerm.length > 4) {
      setIsSearching(true);
      fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(debouncedSearchTerm)}`, {
        headers: {
          'User-Agent': 'YourApp/1.0 (your@email.com)'
        }
      })
      .then(response => response.json())
      .then(data => {
        setSearchResults(data);
        setIsSearching(false);
      })
      .catch(error => {
        console.error('Error:', error);
        setIsSearching(false);
      });
    } else {
      setSearchResults([]);
    }
  }, [debouncedSearchTerm]);

  const handleSelectLocation = (location: string) => {
    setLocationQuery(location);
    setSearchResults([]);
  };

  return (
    <div className="space-y-10 divide-y divide-gray-900/10 m-12 pt-5">
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-lg font-semibold leading-7 text-gray-900">Opprett nytt event</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Fyll ut detaljene for å planlegge ditt event.
          </p>
          <div className="flex justify-center mt-40 min-h-screen bg-gray-100">
          <event-animation
          size="100"
          speed="7" 
          color="#ef9b84" 
        ></event-animation>
        </div>
        </div>

        {showAlert && <Alert message="Please fill in all required fields." />}
        <form onSubmit={handleSubmit} className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              
              {/* Navn på event */}
              <div className="sm:col-span-6">
                <label htmlFor="eventName" className="block text-sm font-medium leading-6 text-gray-900">
                  Navn på event
                </label>
                <input
                  type="text"
                  name="eventName"
                  id="eventName"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
                  placeholder="Mitt fantastiske event"
                  required
                />
              </div>

              <div className="sm:col-span-6">
        <label htmlFor="eventDescription" className="block text-sm font-medium leading-6 text-gray-900">
          Beskrivelse av arrangementet (Forklar konteksten)
        </label>
        <textarea
          id="eventDescription"
          name="eventDescription"
          rows={4}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
          placeholder="Beskriv arrangementet ditt her..."
          value={eventDescription}
          onChange={(e) => setEventDescription(e.target.value)}
          required
        />
      </div>

              {/* Dato for event */}
              <div className="sm:col-span-6">
                <label htmlFor="eventDate" className="block text-sm font-medium leading-6 text-gray-900">
                  Dato for event
                </label>
                <input
                  type="date"
                  name="eventDate"
                  id="eventDate"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
                  required
                />
              </div>

              {/* Budsjett */}
              <div className="sm:col-span-3">
                <label htmlFor="budget" className="block text-sm font-medium leading-6 text-gray-900">
                  Budsjett
                </label>
                <input
                  type="number"
                  name="budget"
                  id="budget"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
                  placeholder="20000"
                  required
                />
              </div>

              {/* Antall gjester */}
              <div className="sm:col-span-3">
                <label htmlFor="numberGuests" className="block text-sm font-medium leading-6 text-gray-900">
                  Estimert antall gjester
                </label>
                <input
                  type="number"
                  name="numberGuests"
                  id="numberGuests"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
                  placeholder="50"
                  required
                />
              </div>

              

             {/* Sted for event */}
             <div className="sm:col-span-6">
                <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                  Lokasjon for event (adresse eller sted i Norge)
                </label>
                <div className="relative sm:col-span-6">
        <input
          type="text"
          name="location"
          id="location"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
          placeholder="Skriv inn en adresse"
          value={locationQuery}
          onChange={(e) => setLocationQuery(e.target.value)}
          required
        />
        <ul className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm text-gray-900 shadow-lg">
          {isSearching && <li>Søker...</li>}
          {searchResults.map((result, index) => (
            <li
              key={index}
              className="cursor-pointer px-3 py-2 hover:bg-gray-100"
              onClick={() => handleSelectLocation(result.display_name)}
            >
              {result.display_name}
            </li>
          ))}
        </ul>
      </div>
      </div>

                  {/* Leverandørvalg */}
      <div className="sm:col-span-6">
        <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
          Relevante leverandører
        </label>
        <div className="flex flex-wrap gap-2 mt-2">
          {selectedCategories.map((category, index) => (
            <span
              key={index}
              className={`inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium cursor-pointer ${category.current ? colorClasses[category.name] : 'bg-gray-100 text-gray-400'} `}
              onClick={() => toggleCategory(category.name)}
            >
              <category.icon className="h-4 w-4" />
              {category.name}
            </span>
          ))}
        </div>
      </div>
      

              {/* Lagre-knapp */}
              <div className="sm:col-span-6">
              <button
                type="submit"
                disabled={formSubmitted}
/*                 className="mt-2 w-full rounded-md bg-[#257E7B] py-2 px-4 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-[#257E7B] focus:ring-offset-2"
 */                className='buttonSecondaryGreen w-full justify-center'
              >
              {formSubmitted ? (
                <div role="status" className="flex justify-center items-center">
                <span className="pr-8">Vennligst vent</span> <create-event-animation
                size="80"
                stroke="5"
                speed="1.75" 
                color="white" 
              ></create-event-animation>
              </div>
              ) : (
                "Opprett Event"
              )}
            </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}