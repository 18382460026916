import { collection, query, where, onSnapshot, orderBy, doc, updateDoc, addDoc, getDoc, arrayUnion } from 'firebase/firestore';
import { useData } from '../../DataContext';
import { db, app } from "../../firebase";
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface UpdateModalProps {
  userId: string;
  field: string; // Feltet som skal oppdateres, f.eks. 'personalInfo.name'
  isOpen: boolean;
  onClose: () => void; // Funksjon for å lukke modalen
}


export const UpdateModal: React.FC<UpdateModalProps> = ({ userId, field, isOpen, onClose }) => {
    const [newValue, setNewValue] = useState('');
    const cancelButtonRef = useRef(null);
  
    const updateData = async () => {
      const userDoc = doc(db, 'users', userId);
      const fieldToUpdate = { [field]: newValue };
      try {
        await updateDoc(userDoc, fieldToUpdate);
        console.log('Oppdatering vellykket');
        onClose(); // Lukk modalen etter vellykket oppdatering
      } catch (error) {
        console.error('Feil ved oppdatering:', error);
      }
    };
  
    return (
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
  
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Oppdater {field}
                    </Dialog.Title>
                    <div className="mt-2">
                      <input
                        type="text"
                        value={newValue}
                        onChange={(e) => setNewValue(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#217E7A] focus:ring-[#217E7A] sm:text-sm"
                        placeholder="Ny verdi"
                      />
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-[#217E7A] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-[#217E7A] focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={updateData}
                    >
                      Oppdater
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#217E7A] focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={onClose}
                      ref={cancelButtonRef}>
                        Avbryt
                        </button>
                        </div>
                        </Dialog.Panel>
                        </Transition.Child>
                        </div>
                        </div>
                        </Dialog>
                        </Transition.Root>
                        );
                        };