import { useState, useEffect } from 'react'
import { useData } from '../../DataContext';

import AboutEvent from '../../components/Events/AboutEvent';
import GuestList from './GuestListPage';
import BudgetPage from './BudgetPage';
import TasksPage from './TasksPage';
import InvitationsPage from './InvitationsPage';
import SupplierPage from './SupplierPage';
import EventDropDownMenu from '../../components/Events/EventDropDownMenu';
import CreateEventPage from '../CreateEventPage';
import EventsOverview from '../../components/Events/EventsOverview';

import '../../styles/designs.css';


const secondaryNavigation = [
    { name: 'Oversikt', current: true },
    { name: 'Gjesteliste', current: false },
    /* { name: 'Invitasjoner', current: false }, */
    { name: 'Budsjett', current: false },
    { name: 'Gjøremål', current: false },
    { name: 'Leverandører', current: false },
    /* { name: 'OpprettEvent', current: false},
    { name: 'AlleEventer', current: false}, */
]



export default function EventPage() {
    const { eventData, eventId, setEventData, setEventId, userData } = useData();
    const [currentPage, setCurrentPage] = useState("Oversikt");
   
    function renderInspirationCard(index: any, url:any, backgroundImage: any, defaultTitle: any) {
        return (
            <>
                <div>
                
                    <div
                        onClick={() => window.location.href = url}
                        style={{
                            backgroundImage: `url("${backgroundImage}")`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            borderRadius: '23px',
                            border: '4.32px solid #3B4058',
                            height: "200px",
                            width: "300px"
                        }}
                        className='m-4 cursor-pointer  flex flex-col items-center justify-center gap-y-2 border-t border-gray-900/5 py-2 sm:px-4 lg:border-t-0 xl:px-8'
                    >
                        <div className="mt-20 mb-2 justify-end text-white rounded-xl text-sm font-black shadow-md p-2 bg-[#257E7B] shadow-black hover:shadow-gray-500/40">
                            {eventData.inspirationArticles?.[index] ?? defaultTitle}
                        </div>
                        
                    </div>
                </div>
            </>
        );
    }

    const [allTemplates, setAllTemplates] = useState()

    useEffect(() => {
        if (!eventId) {

            const storedEventId = localStorage.getItem('currentEventId');
            setEventId(storedEventId);
            console.log(eventData)
        }
    }, [eventId]);


    const handleNavigation = (pageName: string) => setCurrentPage(pageName);

    const changePage = (newPage: string) => {
        setCurrentPage(newPage);
    };

    const onTriggerFetchDataChange = () => {
        setEventId(eventId);
    };

    const calculateDays = (eventDate: any) => {
        if (!eventDate) return 'Laster...';
        
        const days = Math.ceil((new Date(eventDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
      
        if (days < 0) return 'Fullført';
        if (days === 0) return 'I dag';
        return days;
      };

    return (
        <div className="bg-gray-100 pb-40">
            <main>
                <header className="mb-4 pt-6 sm:mb-6" style={{background: '#F1F1F1'}}>
                    <div className="mx-auto flex max-w-7xl flex-wrap items-center justify-between gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
                        <div className="flex">
                            <img
                                className="inline-block h-16 w-16 rounded-full mr-4"
                                src={userData.personalInfo.profilePicture}
                                alt=""
                            />
                            <h1 
                                style={{
                                    color: 'var(--Mrkebl, #3B4058)',
                                    fontFamily: 'Poppins',
                                    fontSize: '40px',
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    lineHeight: '0.8', // Justert for tettere linjehøyde
                                }}
                            > 
                                <span className="flex flex-col "> {/* Justert for å kontrollere vertikal avstand */}
                                    {eventData ? eventData.title : 'Laster...'}
                                        <EventDropDownMenu />
                                </span>
                            </h1>
                        </div>
                        <h1 
                            className="flex flex-col items-center font-poppins text-4xl font-black pt-4 pr-8 mr-28"
                            style={{
                                color: 'var(--Mrkebl, #3B4058)',
                            }}
                        >
                            {calculateDays(eventData.date)}
                            <span className="font-thin text-2xl">{calculateDays(eventData.date)=='Fullført'|| calculateDays(eventData.date) =='I dag'?'':'dager'}</span>
                        </h1>
                    </div>
                    <div className="order-last flex w-full gap-x-8 border-b-2 border-gray-200 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:pl-6 sm:leading-7">
                        {secondaryNavigation.map((item) => (
                            <a 
                                key={item.name} 
                                style={{ cursor: 'pointer' }} 
                                className={`whitespace-nowrap py-1 px-1 relative ${
                                    currentPage == item.name 
                                    ? 'text-[#257E7B] after:absolute after:inset-x-0 after:bottom-0 after:h-0.5 after:bg-[#257E7B]' 
                                    : 'text-gray-700 hover:text-gray-900'
                                }`}
                                onClick={() => handleNavigation(item.name)}
                            >
                                {item.name}
                            </a>
                        ))}
                    </div>
                </header>
                
                {
                    currentPage === "Oversikt" ? 
                    <AboutEvent 
                        changePage={changePage}
                        numberGuests={eventData.guestList?.length ?? 0} 
                        guests={eventData.guestList?.filter((guest: any) => guest.confirmed).length ?? 0} 
                        numberTasks={eventData.toDoList?.length} 
                        tasks={eventData.toDoList?.filter((task: any) => task.done).length ?? 0}
                        budget={eventData.budget} 
                        eventDate={eventData.eventDate}
                    ></AboutEvent>:
                    currentPage === "Gjesteliste" ? 
                        <GuestList></GuestList> :
                    currentPage === "Budsjett" ? 
                        <BudgetPage 
                            numberGuests={2} 
                            guests={eventData.guests} 
                            numberTasks={eventData.numberTasks} 
                            budget={eventData.budget} 
                        ></BudgetPage> :
                    currentPage === "Gjøremål" ? 
                        <TasksPage></TasksPage> :
                    currentPage === "Invitasjoner" ? 
                        <InvitationsPage 
                            /* eventId={eventId} 
                            allTemplates={eventData.allTemplates} 
                            onTriggerFetchDataChange={onTriggerFetchDataChange} */
                        ></InvitationsPage> :
                    currentPage === "Leverandører" ? 
                        <SupplierPage></SupplierPage> :
                    currentPage === "OpprettEvent" ?
                        <CreateEventPage></CreateEventPage>:
                    currentPage === "AlleEventer" ?
                        <EventsOverview></EventsOverview>:
                    null
                }                
            </main>
            <div className="order-last flex w-full gap-x-8 border-b-2 border-gray-200 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:pl-6 sm:leading-7">
                <div className="font-light p-8 mt-12 text-2xl">Trenger du inspirasjon?
                    <div className="flex justify-center mt-8">
                        {renderInspirationCard(0, "https://partylyd.no/aktuelt?p=slik-forvandler-du-et-vanlig-rom-til-et-festlokale", "https://dropinblog.net/34251141/files/featured/festlokale.jpg", "")}
                        {renderInspirationCard(1, "https://partylyd.no/aktuelt?p=kostnadsanalyse-av-lydutleie-vs-kj%C3%B8p", "https://dropinblog.net/34251141/files/featured/artikkelbilder__3_.png", "")}
                        {renderInspirationCard(2, "https://partylyd.no/aktuelt?p=introduksjon-til-bryllupsmiddag", "https://dropinblog.net/34251141/files/featured/bryllupsmiddag.jpg", "")}
                    </div>
                </div>
            </div>
            <div className="px-8 mt-16">
                <div className="flex flex-wrap justify-center gap-4">
                    {eventData.eventPlanningTips && eventData.eventPlanningTips.map((tip: any, index: any) => (
                        <div key={index} className="max-w-sm rounded-2xl overflow-hidden shadow-lg p-4">
                            <div className="font-semibold text-lg mb-2 text-[#257E7B] hover:text-gray-500">Tips {index + 1}</div>
                            <p className="text-gray-700 text-base">
                                {tip.slice(2)}
                            </p>
                        </div>
                    ))}
                </div>
            </div>


        </div>
     
    )
}
