import React, { useState, useEffect } from 'react';
import { db, app } from "../../firebase";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Dialog, Transition } from '@headlessui/react';
import { CheckCircleIcon, XCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { Switch } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import '../../styles/tasksPage.css';
import { motion } from 'framer-motion';
import EditTaskModal from '../../components/Events/tasks/EditTaskModal';
import AddTaskModal from '../../components/Events/tasks/AddTaskModal';
import TaskMenu from '../../components/Events/tasks/TaskMenu';
import { useData } from '../../DataContext';
import { hatch } from 'ldrs'
import '../../styles/designs.css'
hatch.register("generate-tasks-animation")



function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }

type Task = {
    id: string;
  title: string;
  desc: string;
  done: boolean;
};

export default function TasksPage() {
    const [tasks, setTasks] = useState<Task[]>([]);
    const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);
    const [isEditTaskModalOpen, setIsEditTaskModalOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState<Task | null>(null);
    const { eventData, eventId, setEventId, userData } = useData();
    const [awaitingGeneration, setAwaitingGeneration] = useState(false);
    


    const handleTriggerFunctionClick = async () => {
         setAwaitingGeneration(true)
        const functions = getFunctions(app, 'europe-west1');
        const triggerFunction = httpsCallable(functions, 'aiGenerateTasks');
        
        try {
            // Endre nøkkelnavnet her til clientReferenceId
            const result = await triggerFunction({ clientReferenceId: eventId });
            console.log(result.data);
            setAwaitingGeneration(false)
            // Håndter resultatet som du ønsker
        } catch (error) {
            console.error('Error calling function:', error);
        }
    };


    // Funksjon for å oppdatere en oppgave
    const updateTask = async () => {
        if (selectedTask) {
        const updatedTasks = tasks.map(t => t.id === selectedTask.id ? { ...selectedTask } : t);
        setTasks(updatedTasks);

        const docRef = doc(db, "events", eventId);
        await updateDoc(docRef, { toDoList: updatedTasks });

        setIsEditTaskModalOpen(false);
    };
}

    // Funksjon for å slette en oppgave
    const deleteTask = async () => {
        if (selectedTask) {
            const updatedTasks = tasks.filter(t => t.id !== selectedTask.id);
            setTasks(updatedTasks);
    
            const docRef = doc(db, "events", eventId);
            await updateDoc(docRef, { toDoList: updatedTasks });
    
            setIsEditTaskModalOpen(false);
        }
    }

    const openAddTaskModal = () => {
        setIsAddTaskModalOpen(true);
    };

    const openEditTaskModal = (task: Task) => {
        setSelectedTask(task);
        setIsEditTaskModalOpen(true);
    };

    useEffect(() => {
        setTasks(eventData.toDoList);
    }, [eventData]);

    const [newTask, setNewTask] = useState({ id: Date.now().toString(), title: '', desc: '', done: false });

    const handleAddTask = async () => {
        const updatedTask = { ...newTask, id: Date.now().toString() }; // Opprett en ny oppgave med en unik id
        const updatedTasks = [...tasks, updatedTask];
        setTasks(updatedTasks);
    
        const docRef = doc(db, "events", eventId);
        await updateDoc(docRef, { toDoList: updatedTasks });
    
        setIsAddTaskModalOpen(false);
        setNewTask({ id: Date.now().toString(), title: '', desc: '', done: false }); // Tilbakestill newTask med en ny unik id
    };


    const handleToggle = async (taskToToggle: Task) => {
        const updatedTasks = tasks.map(task => 
            task.id === taskToToggle.id ? { ...task, done: !task.done } : task
        );
    
        // Oppdater databasen først
        const docRef = doc(db, "events", eventId);
        await updateDoc(docRef, { toDoList: updatedTasks });
    
        // Etter at databasen er oppdatert, oppdater lokal state
        setTasks(updatedTasks);
    };

    // Sorter tasks slik at uferdige kommer først
    const sortedTasks = [...tasks].sort((a, b) => Number(a.done) - Number(b.done));

    const openTaskDetails = (task: Task) => {
        setSelectedTask(task);
        setIsEditTaskModalOpen(true);
    };

    return (
        <div className="bg-gray-100">
            <div className="m-4">
            
    {/* Eksisterende knapp for å legge til oppgave */}
    <button
        type="button"
        onClick={openAddTaskModal}
        /* className="inline-flex squish-button items-center gap-x-2 rounded-md bg-[#257E7B] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
         */
        className='buttonSecondaryGreen'
    
    >
        <CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
        Legg til Oppgave
    </button>

    {/* Ny knapp for å trigge funksjonen */}
    <button
        type="button"
        onClick={handleTriggerFunctionClick}
        /* className="squish-button ml-2 inline-flex items-center gap-x-2 rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
         */
        className='buttonTernaryGray ml-2'
    >
      {awaitingGeneration ? (
                <div role="status" className="flex justify-center items-center">
                 <generate-tasks-animation
                  size="20"
                  speed="3.5" 
                  color="white" 
                ></generate-tasks-animation>
                </div>
              ) : (
                <>
                <SparklesIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                 Generer 3 forslag
                 </>
              )}
    </button>
    <TaskMenu/>
</div>


      <AddTaskModal
          newTask={newTask}
          setNewTask={setNewTask}
          isOpen={isAddTaskModalOpen}
          setIsOpen={setIsAddTaskModalOpen}
          handleAddTask={handleAddTask} // antar at denne funksjonen er definert
      />

            {selectedTask && (
        <EditTaskModal
            selectedTask={selectedTask}
            setSelectedTask={setSelectedTask}
            updateTask={updateTask}
            deleteTask={deleteTask}
            isOpen={isEditTaskModalOpen}
            setIsOpen={setIsEditTaskModalOpen}
        />
    )}

<ul
  role="list"
  className="divide-y divide-gray-200 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl m-4"
>
<h3 className="text-base font-semibold leading-6 text-gray-900 p-4 text-xl">Gjøremål</h3>
  {sortedTasks.map((task, index) => (
    <motion.li
      key={task.id}
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={`relative cursor-pointer hover:bg-orange-50 flex justify-between items-center px-4 py-5 sm:px-6 ${task.done ? "bg-green-100" : "bg-white"}`}
      onClick={() => openTaskDetails(task)}
    >
      <div className="flex flex-1 min-w-0 gap-x-4">
        <p className="text-sm font-semibold leading-6 text-gray-900">
          {task.title}
        </p>
        <p className="text-xs text-gray-500 flex-1">
          {task.desc}
        </p>
      </div>
      <div className="flex items-center">
  <span className={`mr-2 text-sm ${task.done ? 'text-green-600' : 'text-orange-600'}`}>
    {task.done ? "Fullført" : "Ikke fullført"}
  </span>
  <div className="flex-none pr-8 pt-1" onClick={(e) => e.stopPropagation()}>
    <Switch
      checked={task.done}
      onChange={() => handleToggle(task)}
      className={classNames(
        task.done ? 'bg-[#257E7B]' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none'
      )}
    >
      <span className="sr-only">Endre status</span>
      <span
        className={classNames(
          task.done ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </Switch>
  </div>
</div>
                    
                    <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                </motion.li>
                
            ))}
        </ul>
        </div>
    );
}