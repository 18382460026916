import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from "../../../firebase";
import { useData } from '../../../DataContext';
import '../../../styles/designs.css';

interface AddBudgetItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  budgetItems: any[]; // Oppdater med din faktiske type for budsjettposter
  eventId: string;
}

const AddBudgetItemModal: React.FC<AddBudgetItemModalProps> = ({ isOpen, onClose, budgetItems, eventId }) => {
  const { setEventId  } = useData();


    const [newBudgetItem, setNewBudgetItem] = useState({
      id: Date.now().toString(), // Unik ID
      title: '', // Tittel
      description: '', // Beskrivelse
      amount: '', // Beløp
      status: 'Pending' // Standardstatus
    });

    const isItemValid = () => {
      return newBudgetItem.title && newBudgetItem.amount && newBudgetItem.status;
    };

    const handleAddBudgetItem = async () => {
      if (!isItemValid()) {
        alert('Vennligst fyll ut alle de nødvendige feltene.');
        return;
      }

      const updatedBudgetItems = [...budgetItems, newBudgetItem];
      const docRef = doc(db, "events", eventId);
      try {
        onClose();
        await updateDoc(docRef, { budgetItems: updatedBudgetItems });
        budgetItems = updatedBudgetItems

      } catch (error) {
        console.error("Error updating document: ", error);
      }
    };

  return (
    <Transition show={isOpen}>
      <Dialog open={isOpen} onClose={onClose}>
      <Transition.Child
                     
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <h3 className="text-lg font-medium leading-6 text-gray-900 pr-20 pl-20">Legg til ny budsjettpost</h3>

              {/* Input for tittel */}
          <div className="mt-4">
            <input
              type="text"
              placeholder="Tittel"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
              value={newBudgetItem.title}
              onChange={(e) => setNewBudgetItem({ ...newBudgetItem, title: e.target.value })}
            />
          </div>

          {/* Input for beskrivelse */}
          <div className="mt-4">
            <textarea
              placeholder="Beskrivelse"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
              value={newBudgetItem.description}
              onChange={(e) => setNewBudgetItem({ ...newBudgetItem, description: e.target.value })}
            />
          </div>

              <div className="mt-4">
                <input
                  type="text"
                  placeholder="Sum i kroner"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
                  value={newBudgetItem.amount}
                  onChange={(e) => setNewBudgetItem({ ...newBudgetItem, amount: e.target.value })}
                />
              </div>

              <div className="mt-4">
                <select
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
                  value={newBudgetItem.status}
                  onChange={(e) => setNewBudgetItem({ ...newBudgetItem, status: e.target.value })}
                >
                  <option value="Paid">Betalt</option>
                  <option value="Pending">Ikke betalt</option>
                  {/* Legg til flere alternativer etter behov */}
                </select>
              </div>

              <div className="mt-5 sm:mt-6 flex justify-end">
                <button
                  type="button"
                  /* className="inline-flex justify-center rounded-md bg-[#257E7B] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-[#257E7B] focus:ring-offset-2 focus:ring-offset-gray-100"
                   */
                  className='buttonSecondaryGreen '
                  onClick={handleAddBudgetItem}
                >
                  Legg til
                </button>
                <button
                  type="button"
                  /* className="ml-3 inline-flex justify-center rounded-md bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-[#257E7B] focus:ring-offset-2 focus:ring-offset-gray-100"
                   */
                  className='buttonTernaryGray ml-3'
                  onClick={onClose}
                >
                  Lukk
                </button>
              </div>
            </Dialog.Panel>
            </Transition.Child>
                        </div>
                    </div>
      </Dialog>
    </Transition>
  );
};

export default AddBudgetItemModal;