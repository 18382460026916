import groupIcon from '../../images/icons/group1.png'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import '../../styles/designs.css'

const benefits = [
  'Samhandle i skyen',
  'Tilordne team på oppgaver',
  'Chat internt med teamet',
  'Felleschat med leverandører',
  'Konfigurer din organisasjon',
  'Opprett flere team',
]

export default function NotConfigured() {
  return (
    <div className=" py-12 sm:py-20 rounded-3xl">
      <div className="relative isolate">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-white px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
            <img
              className="h-60 flex-none "
              src={groupIcon}
            />
            <div className="w-full flex-auto">
              <h2 className="text-3xl font-bold tracking-tight text-[#217E7A] sm:text-4xl">Samarbeid med ditt team</h2>
              <p className="mt-6 text-lg leading-8 text-gray-500">
                Med Dity Teams får hele arrangementsgruppen full oversikt over planleggingen.
              </p>
              <ul
                role="list"
                className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-[#217E7A] sm:grid-cols-2"
              >
                {benefits.map((benefit) => (
                  <li key={benefit} className="flex gap-x-3">
                    <CheckCircleIcon className="h-7 w-5 flex-none" aria-hidden="true" />
                    {benefit}
                  </li>
                ))}
              </ul>
              <div className="mt-10 flex">
                {/* <a href="#" className="bg-[#f09b84] rounded-lg p-4 text-md font-bold leading-6 text-white">
                  Book demo og konfigurer <span aria-hidden="true">&rarr;</span>
                </a> */}
                <button className='buttonSecondaryPink'>
                  Book demo og konfigurer <span aria-hidden="true">&rarr;</span>
                </button>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  )
}
