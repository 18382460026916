import { useState } from 'react'
import { Dialog, Disclosure, RadioGroup } from '@headlessui/react'
import { Bars3Icon, MinusSmallIcon, PlusSmallIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { CheckIcon } from '@heroicons/react/20/solid'
import personIcon2 from '../../images/icons/person2.png'
import aneo from '../../images/aneo.png'
import discovery from '../../images/discovery.png'
import { TbInnerShadowBottom } from 'react-icons/tb'


const pricing = {
  frequencies: [
    { value: 'monthly', label: 'Månedlig', priceSuffix: '/måned' },
    { value: 'annually', label: 'Årlig', priceSuffix: '/år' },
  ],
  tiers: [
    {
      name: 'Basic - "Startpakken"',
      id: 'tier-hobby',
      href: '#',
      price: { monthly: '1 000kr ', annually: '12 000 kr' },
      description: 'En god start på å skape glede og spenning i de ansattes hverdag.',
      features: ['Ubegrenset antall arrangmenter', 'Ferdige 2-klikks maler', 'Ett team på inntil 5 brukere', '2 timer onboarding', 'Full støtte via e-post og telefon'],
      mostPopular: false,
    },
    {
      name: 'Profesjonell - "Vekstpakken"',
      id: 'tier-freelancer',
      href: '#',
      price: { monthly: '2 000kr ', annually: '24 000kr ' },
      description: 'For deg med mange ansatte eller eksterne ambisjoner.',
      features: ['Alt fra basic', 'Full støtte inkludert videomøte', '5 team på inntil 5 brukere', 'Rapporter og oppfølging', 'White-label med egendefinert branding'],
      mostPopular: true,
    },
    {
      name: 'Eventbyrå - "Enterprise-løsningen"',
      id: 'tier-enterprise',
      href: '#',
      price: { monthly: '5 000kr ', annually: '60 000kr ' },
      description: 'En alt-i-ett løsning for deg med eventer som sentral virksomhet.',
      features: [
        'Alt fra profesjonell',
        'Ubegrenset antall abonnenter for å vokse uten begrensninger',
        'Avansert analyse for dypere innsikt og bedre beslutningsstøtte',
        '1-times dedikert støtte for lynrask respons på henvendelser',
        'Marketing automations for effektiv markedsføring av dine arrangementer',
        'Egendefinerte rapporteringsverktøy for å tilpasse innsikt etter dine behov',
      ],
      mostPopular: false,
    },
  ],
}
const faqs = [
  {
    question: "Hva er det beste med deres eventplanleggingstjeneste?",
    answer: "Det beste med vår tjeneste er fleksibiliteten og brukervennligheten den tilbyr. Med våre intuitive verktøy kan du enkelt planlegge, organisere og gjennomføre arrangementer av alle størrelser, samtidig som du har tilgang til omfattende støtte og avanserte analysefunksjoner."
  },
  {
    question: "Hvordan kan jeg registrere mitt team på plattformen?",
    answer: "Du kan registrere teamet ditt ved å gå til 'Teaminnstillinger' etter at du har logget inn på din konto. Her kan du legge til nye medlemmer med deres e-postadresser, og de vil motta en invitasjon til å bli med i teamet ditt."
  },
  {
    question: "Tilbyr dere støtte for integrasjon med andre tjenester?",
    answer: "Ja, vi tilbyr integrasjoner med en rekke populære tjenester som kalendere, e-postmarkedsføringsplattformer og CRM-systemer for å gjøre eventplanleggingen så sømløs som mulig."
  },
  {
    question: "Kan jeg tilpasse eventlandingssider med vår egen branding?",
    answer: "Absolutt. Vår plattform gir deg muligheten til å tilpasse landingssider og registreringsskjemaer med din egen branding, inkludert logoer, farger og mer, for å matche din bedriftsidentitet."
  },
  {
    question: "Hva slags støtte kan jeg forvente som profesjonell bruker?",
    answer: "Som profesjonell bruker får du tilgang til prioritert kundestøtte via e-post, telefon og videomøter. Vårt team er dedikert til å sikre at dine arrangementer blir en suksess, med rask respons og personlig assistanse når du trenger det."
  },
  {
    question: "Hvordan fungerer fakturering og abonnement?",
    answer: "Vår tjeneste tilbyr fleksible abonnementsplaner basert på dine behov. Fakturering skjer månedlig eller årlig, avhengig av din foretrukne betalingsplan. Du kan enkelt oppgradere, nedgradere eller kansellere abonnementet ditt direkte fra kontoen din."
  }
  // Flere spørsmål...
]


function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function PricingSection() {
  const [frequency, setFrequency] = useState(pricing.frequencies[0])

  return (
    <div className="bg-gray-100">

      <main>
        {/* Pricing section */}
        <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-32 lg:px-8">
        <div className='flex'>
        <img
            className=" mx-20 mt-10 h-40"
            src={personIcon2}
            alt="Your Company"
          />
          <div>
          <div className="mx-auto max-w-4xl text-center">
            <h1 className="text-base font-semibold leading-7 text-[#267E7A]">Priser</h1>
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Priser for organisasjoner i&nbsp;alle&nbsp;størrelser
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
            Velg et gunstig abonnement som passer best med dine behov til funksjoner for å planlegge, engasjere og skape eventer av høy kvalitet.
          </p>
          </div>
          </div>
          <div className="mt-16 flex justify-center">
            <RadioGroup
              value={frequency}
              onChange={setFrequency}
              className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
            >
              <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
              {pricing.frequencies.map((option) => (
                <RadioGroup.Option
                  key={option.value}
                  value={option}
                  className={({ checked }) =>
                    classNames(
                      checked ? 'bg-[#267E7A] text-white' : 'text-gray-500',
                      'cursor-pointer rounded-full px-2.5 py-1'
                    )
                  }
                >
                  <span>{option.label}</span>
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          </div>
          <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
            {pricing.tiers.map((tier) => (
              <div
                key={tier.id}
                className={classNames(
                  tier.mostPopular ? 'ring-2 ring-[#267E7A]' : 'ring-1 ring-gray-200',
                  'rounded-3xl p-8'
                )}
              >
                <h2
                  id={tier.id}
                  className={classNames(
                    tier.mostPopular ? 'text-[#267E7A]' : 'text-gray-900',
                    'text-lg font-semibold leading-8'
                  )}
                >
                  {tier.name}
                </h2>
                <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-4xl font-bold tracking-tight text-gray-900">{//tier.price[frequency.value]
                  }</span>
                  <span className="text-sm font-semibold leading-6 text-gray-600">{frequency.value=="monthly"? tier.price.monthly:tier.price.annually}{frequency.priceSuffix}</span>
                </p>
                <a
                  href={tier.href}
                  aria-describedby={tier.id}
                  className={classNames(
                    tier.mostPopular
                      ? 'bg-[#267E7A] text-white shadow-sm hover:bg-grayo-500'
                      : 'text-[#267E7A] ring-1 ring-inset ring-[#267E7A] hover:ring-gray-300',
                    'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  )}
                >
                  { 'Ta kontakt'}
                </a>
                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon className="h-6 w-5 flex-none text-[#267E7A]" aria-hidden="true" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Logo cloud */}
        <div className="mx-auto mt-24 max-w-7xl px-6 sm:mt-32 lg:px-8">
          <div className="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src={aneo}
              alt="Transistor"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src={discovery}
              alt="Reform"
              width={158}
              height={48}
            />
            
          </div>
          <div className="mt-16 flex justify-center">
            <p className="relative rounded-full bg-gray-50 px-4 py-1.5 text-sm leading-6 text-gray-600 ring-1 ring-inset ring-gray-900/5">
              <span className="hidden md:inline">
                Dity events reduserer tid brukt på eventplanlegging med over 50%.
              </span>
              <a href="#" className="font-semibold text-[#267E7A]">
                <span className="absolute inset-0" aria-hidden="true" /> Se hvordan her{' '}
                <span aria-hidden="true">&rarr;</span>
              </a>
            </p>
          </div>
        </div>


        {/* FAQ section */}
        <div className="mx-auto my-24 max-w-7xl px-6 sm:my-56 lg:px-8">
          <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Ofte stilte spørsmål</h2>
            <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt>
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                          <span className="text-base font-semibold leading-7">{faq.question}</span>
                          <span className="ml-6 flex h-7 items-center">
                            {open ? (
                              <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                            ) : (
                              <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                            )}
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </main>
    </div>
  )
}