import React from 'react';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid';


type Location = {
  area: string;
  address: string;
  zip: number;
  city: string;
  long: number;
  lat: number;
};

export interface Supplier {
    id: string;
    name: string;
    services: string[];
    style: string[];
    categories: string[];
    locations: Location[];
    area: string;
    address: string;
    postalCode: string;
    city: string;
    zip: string;
    long: string;
    lat: string;
    phone: string;
    email: string;
    link: string;
    profileImage: string;
    supplierType: string; // New field for category
  }

interface SupplierCardProps {
  supplier: Supplier;
  onSendMessage: (supplierId: string, userId: string) => void;
  userId: string;
}

const SupplierCard: React.FC<SupplierCardProps> = ({ supplier, onSendMessage, userId}) => {
  return (
    <li className="cursor-pointer col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow hover:shadow-none">
      <div className="flex flex-1 flex-col p-8">
        <img className="mx-auto h-32 w-32 flex-shrink-0 rounded-full" src={supplier.profileImage} alt="" />
        <h3 className="mt-6 text-sm font-medium text-gray-900">{supplier.name}</h3>
        <dl className="mt-1 flex flex-grow flex-col justify-between">
          <dt className="sr-only">Title</dt>
          <dd className="text-sm text-gray-500">{supplier.style[0]}</dd>
          <dt className="sr-only">City</dt>
          <dd className="mt-3">
            <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
              {supplier.locations[0].city}
            </span>
          </dd>
        </dl>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="flex w-0 flex-1">
            <a
              
              className="relative -mr-px inline-flex w-0 flex-1 pl-2 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
              onClick={() => onSendMessage(supplier.id, userId)}
            >
              <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              Send melding
            </a>
          </div>
        </div>
      </div>
    </li>
  );
}

export default SupplierCard;