import React, { useState, useEffect } from 'react';
import InboxCard from '../components/Inbox/InboxCard';
import MessageCorrespondence from '../components/Inbox/MessageCorrespondence';
import EventToggle from '../components/Inbox/EventToggle';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react';
import { useData } from '../DataContext';
import { collection, addDoc, doc, getDoc } from "firebase/firestore"; 
import { db } from "../firebase";

import '../styles/designs.css';


interface Person {
    id: string;
    name: string;
    event: string;
    role: string;
    imageUrl: string;
    conversationId: string;
    lastSeen: string | null;
    lastSeenDateTime: string | null;
  }

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }



export default function InboxPage() {
   const { eventData, eventId, setEventData, setEventId, conversations, userId } = useData();
    const [query, setQuery] = useState('');
    const [people, setPeople] = useState<Person[]>([]); // Definer state som en array av Person
    const [currentConversationId, setCurrentConversationId] = useState(localStorage.getItem("lastConversationId") || '');
    const [currentEventId, setCurrentEventId] = useState('');


    const handleEventSelect = (eventId: string) => {
      setCurrentEventId(eventId);
    };

  const handleInboxCardClick = (conversationId: string) => {
    setCurrentConversationId(conversationId);
    localStorage.setItem("lastConversationId", conversationId)
  };


    useEffect(() => {
      const updatePeople = async () => {
        const generatedPeople = await generatePeopleFromConversations(conversations, userId, currentEventId);
        setPeople(generatedPeople);
      };

      if (conversations.length > 0) {
          updatePeople();
      }
  }, [conversations, userId, currentEventId]);
    //createUser(newUserData);

    
    async function fetchUserData(userId: string) {
      const docRef = doc(db, "users", userId);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
          return docSnap.data();
      } else {
          console.log("No such document!");
          return null;
      }
  }
  
  function createPeopleObject(userDocument: any, conversationId: string) {
    // Sjekk om supplierInfo eksisterer og har nødvendige data
    if (userDocument.supplierInfo && userDocument.supplierInfo.name) {
        return {
            id: userDocument.supplierInfo.id,
            name: userDocument.supplierInfo.name,
            event: userDocument.supplierInfo.email,
            role: userDocument.supplierInfo.supplierType,
            imageUrl: userDocument.supplierInfo.profileImage,
            conversationId: conversationId,
            lastSeen: formatLastSeen(userDocument.technicalInfo.lastActive), // Eller en annen passende tekst
            lastSeenDateTime: userDocument.technicalInfo.lastActive // Siden dette feltet kanskje ikke finnes for leverandører
        };
    }

    // Hvis supplierInfo ikke finnes, bruk personalInfo og professionalInfo som før
    return {
        id: userDocument.userID,
        name: userDocument.personalInfo.name,
        event: userDocument.personalInfo.contact.secondaryEmail,
        role: userDocument.professionalInfo.organization,
        imageUrl: userDocument.personalInfo.profilePicture,
        conversationId: conversationId,
        lastSeen: formatLastSeen(userDocument.technicalInfo.lastActive),
        lastSeenDateTime: userDocument.technicalInfo.lastActive
    };
}
  
async function generatePeopleFromConversations(conversations: any, currentUserId: string, currentEventId: string) {
  let people = [];

  for (const conversation of conversations) {
      for (const participant of conversation.participants) {
        if (!currentEventId || (conversation.events && conversation.events.includes(currentEventId))) {
          if (participant !== currentUserId) {
              const userData = await fetchUserData(participant);
              if (userData) {
                  const person = createPeopleObject(userData, conversation.id);
                  people.push(person);
              }
          }
        }
      }
  }
  return people;
}
  
  function formatLastSeen(lastSeenDateTime: any) {
    if (!lastSeenDateTime) return "N/A";

    const lastSeenDate = new Date(lastSeenDateTime).getTime(); // Konverter til millisekunder
    const now = new Date().getTime(); // Konverter til millisekunder
    const timeDiff = now - lastSeenDate; // Differansen i millisekunder

    const minutes = Math.floor(timeDiff / 60000); // Konverter til minutter
    const hours = Math.floor(minutes / 60); // Konverter til timer
    const days = Math.floor(hours / 24); // Konverter til dager

    if (days > 0) {
        return `${days} dager siden`;
    } else if (hours > 0) {
        return `${hours} timer siden`;
    } else if (minutes > 0) {
        return `${minutes} minutter siden`;
    } else {
        return "Akkurat nå";
    }
}
  
    const filteredPeople = query === ''
      ? people
      : people.filter((person) =>
          person.name.toLowerCase().includes(query.toLowerCase())
        );
  
    return (
      <>
        <div> {/* Div with specifications for the "Main page" imported from designs.css */}
          <div className="flex h-screen bg-gray-100">
            <div className="flex-1 overflow-auto">

                {/* Header */}
            <h1 className="p-8" style={{
              color: '#3B4058',
              fontFamily: 'Poppins',
              fontSize: '60px',
              fontWeight: 700,
            }}>
              Innboks
            </h1>
              {/* Søkefunksjon */}
              <div className="p-8 pr-28 mr-28">
                <div className="pb-2">
                <EventToggle onEventSelect={handleEventSelect} />
              </div>
                <Combobox as="div" value={query} onChange={setQuery}>
                  <div className="relative mt-1 ">
                    <MagnifyingGlassIcon className="pointer-events-none absolute left-3 top-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <Combobox.Input
                      className="w-full rounded-md border border-gray-300 bg-[#D9D9D9] py-2 pl-10 pr-3 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
                      placeholder="Søk etter samtale"
                      onChange={(event) => setQuery(event.target.value)}
                    />
                  </div>
                </Combobox>
              </div>
      
              {/* Render filtrerte InboxCards */}
              {filteredPeople.map((person) => (
                <InboxCard
                key={person.id}
                name={person.name}
                email={person.event}
                role={person.role}
                imageUrl={person.imageUrl}
                conversationId={person.conversationId}
                lastSeen={person.lastSeen}
                lastSeenDateTime={person.lastSeenDateTime}
                onClick={() => handleInboxCardClick(person.conversationId)}
              />
              ))}
            </div>
      
            <div className="w-px bg-gray-300 h-full"></div>
      
            <div className="flex-1 overflow-auto p-4 pt-20">
              <MessageCorrespondence currentConversationId={currentConversationId} />
            </div>
          </div>
        </div>
      </>
    );
  }