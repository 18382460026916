
import {
  LightBulbIcon,
} from '@heroicons/react/24/outline'
import { CheckIcon, QuestionMarkCircleIcon, StarIcon } from '@heroicons/react/20/solid'
import React, { useState, useEffect } from 'react';
import { db } from '../firebase'; // Importer din Firebase konfigurasjon
import { doc, getDoc } from 'firebase/firestore';
import { bouncy } from 'ldrs'
import CreateTemplateEvent from '../components/templates/CreateTemplateEvent'

bouncy.register('dity-loader')


  interface Breadcrumb {
    id: number;
    name: string;
    href: string;
  }

  interface toDoList{
    id: string;
    desc: string;
    title: string;
  }

  interface Event {
    id: string;
    title: string;
    description: string;
    callToAction: string;
    eventType: string;
    perks: string[];
    imageUrls: string[];
    breadcrumbs: Breadcrumb[];
    onePageInfo: string;
    toDoList: toDoList[];
  }

export default function TemplatePage() {
  const [templateEvent, setTemplateEvent] = useState<Event | null>(null);
  const [loading, setLoading] = useState(true);
  const [isCreateTemplateEventOpen, setIsCreateTemplateEventOpen] = useState(false);

  useEffect(() => {
    const fetchTemplateEvent = async () => {
      const urlParts = window.location.href.split("/");
      let templateId = urlParts[urlParts.length - 1];
      if (templateId.endsWith('?')) {
        templateId = templateId.slice(0, -1);
      }
      const docRef = doc(db, "templateEvents", templateId);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        const breadcrumbs = [
          { id: 1, name: 'Oversikt', href: '/home' },
        ];
  
        const updatedTemplateEvent = {
          ...docSnap.data() as Omit<Event, 'breadcrumbs'>,
          breadcrumbs: [
            ...breadcrumbs,
            { id: 3, name: docSnap.data().title, href: '/template-event/'+docSnap.data().id },
          ],
        };
  
        setTemplateEvent(updatedTemplateEvent);
        setLoading(false);
      } else {
        console.log("No such document!");
        setLoading(false);
      }
    };
  
    fetchTemplateEvent();
  }, []);


  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <dity-loader size="60" color="#257e7b"></dity-loader>
      </div>
    );
  }

  return (
    <div className="bg-gray-100">
      <CreateTemplateEvent
                  isOpen={isCreateTemplateEventOpen}
                  onClose={() => setIsCreateTemplateEventOpen(false)}
                  templateEvent={templateEvent}
                />

      <main>
        {/* Product */}
        <div className="bg-gray-100">
          <div className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 sm:pb-32 sm:pt-24 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            {/* Product details */}
            <div className="lg:max-w-lg lg:self-end">
              <nav aria-label="Breadcrumb">
                <ol role="list" className="flex items-center space-x-2">
                  {templateEvent?.breadcrumbs.map((breadcrumb, breadcrumbIdx) => (
                    <li key={breadcrumb.id}>
                      <div className="flex items-center text-sm">
                        <a href={breadcrumb.href} className="font-medium text-gray-500 hover:text-gray-900">
                          {breadcrumb.name}
                        </a>
                        {breadcrumbIdx !== templateEvent?.breadcrumbs.length - 1 ? (
                          <svg
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                            className="ml-2 h-5 w-5 flex-shrink-0 text-gray-300"
                          >
                            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                          </svg>
                        ) : null}
                      </div>
                    </li>
                  ))}
                </ol>
              </nav>

              <div className="mt-4">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{templateEvent?.title}</h1>
              </div>

              <section className="mt-4">

            

                <div className="mt-4 space-y-6">
                  <p className="text-base text-gray-500">{templateEvent?.onePageInfo}</p>
                </div>
              </section>
            </div>

            {/* Product image */}
            <div className="mt-10 lg:col-start-2 lg:row-span-2 lg:mt-0 lg:self-center">
              <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg">
                <img
                  src={templateEvent?.imageUrls[0]}
                  className="h-full w-full object-cover object-center"
                />
              </div>
            </div>

            <div className="mt-10 lg:col-start-1 lg:row-start-2 lg:max-w-lg lg:self-start">
              <section>

                  <div className="sm:flex sm:justify-between">

                  </div>
                  <div className="mt-4">
                    <a href="#tasks" className="group inline-flex text-sm text-gray-500 hover:text-gray-700">
                      <span>Hvordan organiserer jeg opplegget?</span>
                      <QuestionMarkCircleIcon
                        className="ml-2 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </a>
                  </div>
                  <div className="mt-10">
                    <button
                      className="flex w-full items-center justify-center rounded-md border border-transparent bg-[#217E7A] px-8 py-3 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-[#217E7A] focus:ring-offset-2 focus:ring-offset-gray-50"
                      onClick={() => setIsCreateTemplateEventOpen(true)}
                   >
                      Opprett event
                    </button>
                  </div>
                  <div className="mt-6 text-center">
                    <a href="#" className="group inline-flex text-base font-medium">
                      <span className="text-gray-500 hover:text-gray-700">På under 30 sekunder</span>
                    </a>
                  </div>
              </section>
            </div>
          </div>
        </div>


        <section aria-labelledby="policy-heading" className="text-center px-16">
            <h2 id="policy-heading" className="sr-only">
              Fordeler med eventet
            </h2>
            <div className="grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3 lg:gap-x-8">
              {templateEvent?.perks.map((perk) => (
                <div key={perk}>
                  <LightBulbIcon
                        className="mx-auto h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                  <h3 className="mt-6 text-base font-medium text-gray-900">{perk}</h3>
                </div>
              ))}
            </div>
          </section>

        <div className="mx-auto max-w-2xl px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
          {/* Details section */}
          <section aria-labelledby="details-heading">
            <div className="flex flex-col items-center text-center">
              <h2 id="tasks" className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Gjøremål
              </h2>
            </div>
            {templateEvent?.toDoList.map((task) => (
                <div className='flex px-16'>
                  <CheckIcon
                        className="h-6 w-6 mt-auto mr-4 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                  <h3 className="mt-6 text-base font-medium text-gray-900">{task.title}</h3>
                </div>
              ))}
          </section>

        </div>

      </main>

    </div>
  )
}
