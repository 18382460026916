import { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { CiCircleCheck } from "react-icons/ci";
import { TbMoodSad } from "react-icons/tb";
import { MdOutlinePending } from "react-icons/md";
import { useData } from '../../DataContext';

//@ts-ignore
import { db, app } from "../../firebase.ts";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

interface AboutEventProps {
    changePage: (page: string) => void;
    numberGuests: number,
    guests: any,
    budget: number,
    tasks: number,
    numberTasks: number,
    eventDate: Date,
}

interface Style {
    color: string;
    textAlign: React.CSSProperties['textAlign'];
    fontSize: string;
    fontWeight?: string;
    WebkitTextStrokeWidth?: string;
    WebkitTextStrokeColor?: string;
    fontFamily?: string; // Legg til denne linjen
}


const AboutEvent: React.FC<AboutEventProps> = ({ changePage, numberGuests, guests, budget, tasks, numberTasks, eventDate }: AboutEventProps) => {
    const { eventData, eventId, setEventId } = useData();

    
const stats = [
    {
        name: 'Antall Gjester',
        value: guests,
        secondaryValue: numberGuests,
        description: "gjester kommer",
        style: {
            borderRadius: '23px',
            border: '4.32px solid #3B4058',
            boxShadow: '-4.32px 4.32px 0px 5.4px #3B4058'
        },
        page: "Gjesteliste"
    },
    {
        name: 'Budsjett',
        value: new Intl.NumberFormat('no-NB').format(Number(eventData.budgetItems?.reduce((sum: number, item: any) => Number(sum) + Number(item.amount), 0) || 0)),
        secondaryValue: new Intl.NumberFormat('no-NB').format(budget),
        description: "kroner brukt",
        style: {
            borderRadius: '23px',
            border: '4.32px solid #3B4058',
            boxShadow: '-4.32px 4.32px 0px 5.4px #3B4058'
        },
        page: "Budsjett"
    },
    {
        name: 'Gjenstående Gjøremål',
        value: tasks,
        secondaryValue: numberTasks,
        description: "gjøremål gjort",
        style: {
            borderRadius: '23px',
            border: '4.32px solid #3B4058',
            boxShadow: '-4.32px 4.32px 0px 5.4px #3B4058'
        },
        page: "Gjøremål"
    },
    {
        name: 'Dager Igjen',
        value: 0,
        secondaryValue: eventData?.supplierCategories?.length || 0,
        description: "leverandører",
        style: {
            borderRadius: '23px',
            border: '4.32px solid #3B4058',
            boxShadow: '-4.32px 4.32px 0px 5.4px #3B4058'
        },
        page: "Leverandører"
    }
];

const primaryValueStyle: Style = {
    color: '#3B4058',
    textAlign: 'center', // Endret til en gyldig TextAlign verdi
    fontSize: '52px',
    fontWeight: '700'
};

const secondaryValueStyle: Style = {
    color: '#3B4058',
    textAlign: 'center', // Endret til en gyldig TextAlign verdi
    fontSize: '34px',
    fontWeight: '300',
    WebkitTextStrokeWidth: '0.72px',
    WebkitTextStrokeColor: '#3B4058'
};

const descriptionStyle: Style = {
    color: '#3B4058',
    textAlign: 'center',
    fontSize: '19.8px',
    fontFamily: 'Forum' // Nå tillatt etter oppdatering av Style interface
};

    return (
       
        <>
        <div className="relative isolate overflow-hidden">
                    {/* Stats */}
                
                    <div className="px-16">
    <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 lg:px-2 xl:px-0 pr-8 pl-8">
        {stats.map((stat, statIdx) => (
            statIdx === 3 ? 
                // Betinget rendering for det fjerde elementet
                <div onClick={() => changePage(stat.page)} className="p-6 cursor-pointer">
                    <div
                        key={stat.name}
                        style={stat.style}
                        className="custom-class-for-fourth-item pb-12 pt-11" // Legg til en unik klasse eller stil her
                    >
                        <div style={primaryValueStyle}>{stat.value}</div>
                        <div style={descriptionStyle}>{stat.description}</div>
                        {/* Andre unike layout-elementer for det fjerde elementet */}
                    </div>
                </div>
                : 
                // Standard rendering for de første tre elementene
                <div onClick={() => changePage(stat.page)} className="p-6 cursor-pointer">
                    <div
                        key={stat.name}
                        style={stat.style}
                        className={classNames(
                            statIdx % 2 === 1 ? 'sm:border-l' : '',
                            'flex flex-col items-center justify-center gap-y-2 border-t border-gray-900/5 py-2 sm:px-4 lg:border-t-0 xl:px-8'
                        )}
                    >
                        <div style={primaryValueStyle}>{stat.value}</div>
                        <hr style={{ width: '100%', height: '2px', backgroundColor: 'gray' }} />
                        <div style={secondaryValueStyle}>{stat.secondaryValue}</div>
                        <div style={descriptionStyle}>{stat.description}</div>
                    </div>
                </div>
        ))}
    </dl>
</div>
                </div>


        </>
    )
}

export default AboutEvent