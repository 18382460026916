import React, { useRef } from 'react';
import { Dialog } from '@headlessui/react';

interface CopyInvitationLinkModalProps {
    invitationLink: string;
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
}

export default function CopyInvitationLinkModal({ invitationLink, isOpen, setIsOpen }: CopyInvitationLinkModalProps) {
    const linkRef = useRef<HTMLInputElement>(null);

    const copyToClipboard = () => {
        if (linkRef.current) {
            linkRef.current.select();
            document.execCommand('copy');
        }
    };

    return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                        <div>
                            <div className="mt-2">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    Kopier Invitasjonslenke
                                </h3>
                                <div className="mt-2">
                                    <input
                                        ref={linkRef}
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
                                        value={invitationLink}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6 flex justify-between">
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-[#257E7B] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                onClick={copyToClipboard}
                            >
                                Kopier
                            </button>
                            <button
                                type="button"
                                onClick={() => setIsOpen(false)} 
                                className="ml-3 inline-flex justify-center rounded-md bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                            >
                                Lukk
                            </button>
                        </div>
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    );
}
