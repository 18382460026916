import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { PhotoIcon } from '@heroicons/react/24/solid';

export default function AddTeam({ onClose }: { onClose: () => void }) {
  const [open, setOpen] = useState(true)

  const closeDialog = () => {
    setOpen(false);
    setTimeout(() => onClose(), 500);
  };


  

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-stone-50 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <form >
      <div className="space-y-8">
          <h2 className=" font-semibold leading-7 text-2xl text-gray-500">Opprett et nytt team</h2>

         

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div>
              <label htmlFor="headline" className="block text-sm font-medium leading-6 text-stone-900">
                Navn på team
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    name="headline"
                    id="headline"
                    autoComplete="headline"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-stone-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder=" ..."
                  />
                </div>
              </div>
            </div>

            <div>
            <label htmlFor="mainCategory" className="block text-sm font-medium leading-6 text-stone-900">
              Legg til ansatt
            </label>
            <div className="mt-2">
              <select
                id="mainCategory"
                name="mainCategory"
                autoComplete="mainCategory"
                className="block w-full p-2 rounded-md border-gray-300 shadow-sm  ring-1 ring-inset ring-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="">Velg en person</option>
                <option value="navn1">Navn 1</option>
                <option value="navn2">Navn 2</option>
              </select>
            </div>
          </div>
          </div>

            <div className="col-span-full">
              <label htmlFor="about" className="block text-sm font-medium leading-6 text-stone-900">
                Beskrivelse
              </label>
              <div className="mt-2">
                <textarea
                  id="about"
                  name="about"
                  rows={3}
                  className="block pl-2 w-full rounded-md border-0 py-1.5 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={''}
                />
              </div>
              <p className="mt-3 text-sm leading-6 text-gray-500">Skriv et par setninger om teamet.</p>
            </div>
          </div>


        <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={closeDialog}
            className="bg-stone-50 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#465745]"
          >
            Avbryt
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#217e7a] hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#465745]"
          >
            <CheckIcon className="mx-auto h-5 w-5 text-gray-300 mr-1" aria-hidden="true" /> Opprett team
          </button>
        </div>
      </div>
        </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}