import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  SparklesIcon,
  WrenchScrewdriverIcon,
  PlusIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/24/outline'
import companyLogo from '../images/dity.concept.png';

import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react'
import { useData } from '../DataContext';

const navigation =[
  { name: 'Hjem', href: 'home', icon: HomeIcon},
  { name: 'Team', href: 'team', icon: UsersIcon },
  { name: 'Utstyr', href: 'gear', icon: WrenchScrewdriverIcon, count: '12' },
  { name: 'Kalender', href: 'calendar', icon: CalendarIcon, count: '20+' },
  { name: 'DityGPT', href: 'GPT', icon: SparklesIcon },
  //{ name: 'Rapporter', href: '404', icon: ChartPieIcon },
]


function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type Event = {
  id: number;
  title: string;
  eventId: string;
  initial: string;
  href: string;
};

export default function SideBar() {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const [activeNavItem, setActiveNavItem] = useState('Hjem'); // Initialverdi basert på default current
  const [activeEventId, setActiveEventId] = useState(1); // Initialverdi basert på default current
  const { userData, setUserData, userId, setEventId  } = useData();



  const events = userData.eventInfo?.currentEvents?.map((event: Event) => ({
    id: event.id,
    title: event.title,
    eventId: event.id, // eller en annen unik identifikator hvis tilgjengelig
    initial: event.title.charAt(0), // For eksempel, første bokstav av tittelen
    href: `/event-configuration/${event.id}` // Endre etter behov
  })) || [];


  const handleNavClick = (name: string, href: string) => {
    setActiveNavItem(name);
    setActiveEventId(0);
    navigate(href);
  };

  const handleEventClick = (event: Event) => {
    localStorage.setItem('currentEventId', event.eventId);
    setActiveEventId(event.id);
    setEventId(event.id)
    setActiveNavItem("");
    navigate(event.href);
  };

  const handleCreateEventClick = () => {
    setEventId("");
    setActiveNavItem("");
    navigate("create-event")
  };

  const handleSeeAllEventsClick = () => {
    setEventId("");
    setActiveNavItem("");
    navigate("all-events");
  };

  
  // Max number of events displayed in sidebar is 4
  const limitedEvents = events.slice(0, 4); 


  function truncateName(name: string, maxLength: number): string {
    // Check if the name length exceeds the maxLength
    if (name.length > maxLength) {
      // Return the truncated name with "..." appended
      return `${name.substring(0, maxLength)}...`;
    } else {
      // Return the original name if it's short enough
      return name;
    }
  }

  


  return (
    
    <div className={`fixed top-0 h-screen overflow-hidden bg-[#227E7B] sidebar rounded-r-xl z-50 py-2 `} 
      style={{ width: isExpanded ? '288px' : '122px', padding: isExpanded ? 'px-4' : 'px-1'}}

      /* onMouseEnter = {() => setTimeout(() => {setIsExpanded(true)}, 5000)}
      onMouseLeave = {() => setTimeout(() => {setIsExpanded(false)}, 5000)} */
      onMouseEnter={() => setIsExpanded(true)} 
      onMouseLeave={() => setIsExpanded(false)} 
    >
    {/* <div className={`h-screen overflow-y-auto bg-[#227E7B] sidebar ${isExpanded ? 'w-80 px-4' : 'w-16 px-1'} rounded-r-xl z-10 py-2 sticky top-0`}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    > */}
      <div className="flex flex-col h-full items-center">

        {/* Logo */} 
        <div className="flex h-16 shrink-0">
          <img
            className={` w-auto pt-4 pb-4 pl-1 cursor-pointer h-24 `}
            src={companyLogo}
            alt="Dity"
            onClick={() => window.location.href = "/home"}
          />
        </div>

        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col gap-y-6 p-4">
            <li>
              <ul role="list" className="-mx-2 space-y-2">
                {navigation.map((item) => (
                  <li key={item.name}>
                  <div
                      onClick={() => handleNavClick(item.name, item.href)}
                      className={classNames(
                          isExpanded ? 'group cursor-pointeer cursor-pointer flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold' : 'justify-center group cursor-pointeer cursor-pointer flex rounded-md p-2 text-sm leading-6 font-semibold',
                          activeNavItem == item.name ? 'bg-[#EF9A84] text-white' : 'text-[#F1F1F1] hover:text-white hover:bg-[#EF9A84]' 
                      )}
                      
                    >
                      <item.icon className="items-center justify-center h-6 w-6 shrink-0" aria-hidden="true"  />
                      <span style={{ opacity: isExpanded ? 1 : 0, maxWidth: isExpanded ? '100%' : '0', transition: 'opacity 1s ease-in-out, max-width 1s ease-in-out' }}>{isExpanded ? item.name: ''}</span> 
                      {/* Foreløpig ikke der */}
                      {/* {isExpanded && item.count ? (
                        <span
                          className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-[#227E7B] ring-1 ring-inset ring-white"
                          aria-hidden="true"
                        >
                          {item.count}
                          
                        </span>
                      ) : null} */}
                    </div>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <div className=" justify-center text-xs font-semibold leading-6 text-white"> {isExpanded ? "Mine events": "events"}</div>
              <ul role="list" className="-mx-2 mt-2 space-y-1">
              <div
                    onClick={() => handleCreateEventClick()} 
                    className={classNames( isExpanded ?
                      'flex cursor-pointer group  rounded-md p-2 text-sm leading-6 font-semibold' : 'flex justify-center cursor-pointer group  rounded-md p-2 text-sm leading-6 font-semibold','text-white hover:text-white hover:bg-[#EF9A84]'
                    )}
                  >           
                    <span className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full border border-white bg-white text-[0.625rem] font-medium text-[#227E7B] group-hover:text-[#227E7B]">
                      <PlusIcon className='h-5 w-5'></PlusIcon>
                    </span>
                    {isExpanded ? 
                      <div className='ml-3 pt-1'>
                          {'Legg til nytt event'}
                      </div>
                    :
                      ''
                    }
              </div>
              {limitedEvents.map((event: Event) => (
                <li key={event.title}> 
                  <div
                    onClick={() => handleEventClick(event)}
                    className={classNames( isExpanded ?
                      'flex cursor-pointer group  rounded-md p-2 text-sm leading-6 font-semibold' : 'flex justify-center cursor-pointer group  rounded-md p-2 text-sm leading-6 font-semibold',
                      activeEventId === event.id ? 'bg-[#EF9A84] text-white' : 'text-white hover:text-white hover:bg-[#EF9A84]'
                    )}
                  >
                    <span className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full border border-white bg-white text-[0.625rem] font-medium text-[#227E7B] group-hover:text-[#227E7B]">
                      {event.initial}
                    </span>
                    {isExpanded ? 
                      
                        <span className="truncate ml-3 pt-1" 
                          style={{ opacity: isExpanded ? 1 : 0, maxWidth: isExpanded ? '100%' : '0', transition: 'opacity 1s ease-in-out, max-width 1s ease-in-out' }}
                        >
                          {truncateName(event.title, 16)}
                        </span>
                      
                    : 
                    ''
                    
                    }
                    {/* <span className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full border border-white bg-white text-[0.625rem] font-medium text-[#227E7B] group-hover:text-[#227E7B]">
                      {event.initial}
                    </span> */}
                      
                  </div>
                </li>
              ))}
                {events.length > 4 ? (
                  <div 
                    onClick={() => handleSeeAllEventsClick()}
                    className={classNames( isExpanded ?
                      'flex cursor-pointer group  rounded-md p-2 text-sm leading-6 font-semibold' : 'flex justify-center cursor-pointer group  rounded-md p-2 text-sm leading-6 font-semibold','text-white hover:text-white hover:bg-[#EF9A84]'
                    )}
                  >
                      <span className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full border border-white bg-white text-[0.625rem] font-medium text-[#227E7B] group-hover:text-[#227E7B]">
                        <EllipsisHorizontalIcon className='h-5 w-5'></EllipsisHorizontalIcon>
                      </span>
                      {/* Kommentert ut kode til ease-in-ease-out, bør endres til ease-in, men ikke helt sikker på hvordan */}
                      {/* <span className={classNames(isExpanded ? "truncate ml-3 pt-1":"")} 
                          style={{ opacity: isExpanded ? 1 : 0, maxWidth: isExpanded ? '100%' : '0', transition: 'opacity 0.5s ease-in-out, max-width 0.5s ease-in-out' }}
                        >
                          {"Se alle events"}
                        </span>  */}
                      {isExpanded ? 
                        
                        <span className="truncate ml-3 pt-1" 
                          style={{ opacity: isExpanded ? 1 : 0, maxWidth: isExpanded ? '100%' : '0', transition: 'opacity 1s ease-in-out, max-width 1s ease-in-out' }}
                        >
                          {"Se alle events"}
                        </span> 
                      : 
                      ''
                      } 

                  </div>


                )
                :
                ''
                }  
              </ul>
            </li>
          
            {/* Profil bilde  */}
            <li className="-mx-6 mt-auto">
              <div className='flex justify-center'>
                <Link
                  to={"profile"}

                  className= {isExpanded ? "flex items-center gap-x-3 px-4 py-4 text-sm font-semibold leading-6 text-white hover:bg-[#EF9A84]" : "flex items-center px-4 py-3 text-sm font-semibold leading-6 text-white hover:bg-[#EF9A84]"}               
                >
                  <img
                    className={`rounded-full bg-gray-800 h-7 v-7`}
                    src={userData.personalInfo.profilePicture}
                    alt=""
                  />
                  <span className="sr-only">Din profil</span>
                  <span aria-hidden="true">
                    {isExpanded ? userData.personalInfo.name: ""}
                  </span>
                </Link>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

/*  
<div 
                  onClick={() => handleSeeAllEventsClick()}
                  className={classNames( isExpanded ?
                    'flex cursor-pointer group  rounded-md p-2 text-sm leading-6 font-semibold' : 'flex justify-center cursor-pointer group  rounded-md p-2 text-sm leading-6 font-semibold','text-white hover:text-white hover:bg-[#EF9A84]'
                  )}
                >
                {events.length > 4 && (
                  <>
                    <span className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full border border-white bg-white text-[0.625rem] font-medium text-[#227E7B] group-hover:text-[#227E7B]">
                      <EllipsisHorizontalIcon className='h-5 w-5'></EllipsisHorizontalIcon>
                    </span>
                    {isExpanded ? 
                        
                      <span className="truncate ml-3 pt-1" 
                        style={{ opacity: isExpanded ? 1 : 0, maxWidth: isExpanded ? '100%' : '0', transition: 'opacity 1s ease-in-out, max-width 1s ease-in-out' }}
                      >
                        {"Se alle events"}
                      </span> 
                    : 
                    ''
                    } 
                  </>
                )}
                </div>
*/