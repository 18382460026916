import React from 'react';

// TypeScript interface for props
interface BudgetItemProps {
  id: number;
  title: string;
  description: string;
  amount: string;
  status: string;
}

const BudgetItem: React.FC<BudgetItemProps> = ({ id, title, description, amount, status }) => {

    return (
        <div className="flex cursor-pointer hover:bg-orange-50 flex-col border-b border-gray-200 py-2 px-4">
        <div className="text-lg font-semibold">{title}</div>
        {description && <div className="text-sm text-gray-600">{description}</div>}
        <div className="flex justify-between items-center mt-2">
            <div className="text-sm font-medium">{new Intl.NumberFormat('no-NB').format(Number(amount))} kr</div>
            <div className={`text-sm font-semibold px-2 py-1 rounded-full ${status === 'Paid' ? 'bg-green-100 text-green-800' : 'bg-orange-100 text-orange-800'}`}>
                {status === 'Paid' ? 'Betalt' : status === 'Pending' ? 'Ikke betalt' : status}
            </div>
        </div>
    </div>
    );
  };

export default BudgetItem;