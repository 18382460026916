import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, addDoc, updateDoc } from 'firebase/firestore';
//@ts-ignore
import { db, app } from "../../firebase.ts";
import '../../styles/designs.css';
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions(app, 'europe-west1');
const sendConfirmationEmail = httpsCallable(functions, 'sendEmailConfirmationInvitation');


const GuestInvitePage = () => {
  /* const { invitationId } = useParams(); */
  const urlParts = window.location.href.split("/");
  const invitationId = urlParts[urlParts.length - 1];
  const [eventId, setEventId] = useState('');


  type Allergy = {
    id: number;
    name: string;
    description: string;
  };

  interface EditInvitationModalProps {
      isOpen: boolean;
      setIsOpen: (open: boolean) => void;
      invitationId: string | null;
  }
  // Hooks og funksjoner som hører til denne komponenten
  const commonAllergies: Allergy[] = [
      { id: 1, name: 'Peanøtter', description: '' },
      { id: 2, name: 'Trenøtter', description: '' },
      { id: 3, name: 'Melk', description: '' },
      { id: 4, name: 'Egg', description: '' },
      { id: 5, name: 'Fisk', description: '' },
      { id: 6, name: 'Skalldyr', description: '' }
  ];

  const [selectedAllergies, setSelectedAllergies] = useState<Set<number>>(new Set());
  const [otherNotes, setOtherNotes] = useState<string>('');
  const [otherAllergy, setOtherAllergy] = useState<string>('');
  
  

  const toggleAllergy = (id: number) => {
      const newSelectedAllergies = new Set(selectedAllergies);
      if (newSelectedAllergies.has(id)) {
          newSelectedAllergies.delete(id);
      } else {
          newSelectedAllergies.add(id);
      }
      setSelectedAllergies(newSelectedAllergies);
  };

  type Invitation = {
    invitationName: string;
    eventId: string;
    description: string;
    /* Kan legge til flere ting */
  };
  const [invitationData, setInvitationData] = useState<Invitation>();

  const fetchInvitation = async () => {

    console.log("Henter invitasjons data:")
    if (!invitationId) {
      
    }// Sjekk at invitationId ikke er null eller undefined
    
  
    try {
      const docRef = doc(db, "invitations", invitationId); // Referanse til dokumentet i Firestore
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        // Dokumentet eksisterer, hent dataen
        const invitation = docSnap.data();
        setInvitationData({ invitationName: invitation.invitationName, eventId: invitation.eventId, description: invitation.description}); // Oppdater state med navnet på invitasjonen
        setEventId(invitation.eventId)
        console.log("INVITASJONEN BLEI HENTA")
      } else {
        // Dokumentet eksisterer ikke
        console.log("Ingen invitasjon funnet med dette ID!");
      }
    } catch (error) {
      // Håndter eventuelle feil
      console.error("Feil ved henting av invitasjon:", error);
    }
  };
  
  // Husk å kalle fetchInvitation et sted i komponenten, for eksempel i en useEffect
  useEffect(() => {
    fetchInvitation();
  }, [invitationId]); 

  // State variabler for skjema inndata
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [countryCode, setCountryCode] = useState('+47'); 
  const [phone, setPhone] = useState('');

  const handleSendEmail = async (guestData:any, invitationData:any) => {
    try {
      /* console.log("Prøver å sende epost")
      console.log("Guest data: ", guestData)
      console.log("Invitation data: ", invitationData) */


     const result = await sendConfirmationEmail({ guestData, invitationData });
    console.log(result);
  } catch (error) {
    console.error("Error sending email: ", error);
  }
  handleThankYou()
  };


  // Håndterer skjema innsending
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    console.log("EventId: ", eventId)

    const randomId = generateRandomString(13);
    const fullPhoneNumber = `${countryCode}${phone}`;

    const guestData = {
      firstName,
      lastName,
      name: firstName + ' ' +  lastName,
      email,
      phone: fullPhoneNumber,
      allergies: Array.from(selectedAllergies),
      specialNeeds: otherNotes,  
      confirmed: true,
      id: randomId
    };
    console.log("Guest data: ", guestData)

    
    const eventRef = doc(db, "events", eventId); // Referanse til dokumentet i Firestore
    try {
      const eventDoc = await getDoc(eventRef);
      console.log("Nå prøves det")

      if (eventDoc.exists()) {
        const existingGuestList = eventDoc.data().guestList || [];
        const updatedGuestList = [...existingGuestList, guestData];

        await updateDoc(eventRef, { guestList: updatedGuestList });

        

      } else {
        console.log("Event dokumentet finnes ikke!");
      }
    } catch (error) {
      console.error("Feil ved oppdatering av event:", error);
    }

    handleSendEmail(guestData, invitationData);
    /* console.log("Det funka!") */

  };


  const handleThankYou = () => {

    console.log("Denne kjøres?")
    window.location.href = '/guest-invitation/thank-you'; // Erstatt '/takk-side' med stien til din takkeside
  };

  

  const generateRandomString = (length: number) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  /* const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      // Samler dataene
      const formData = {
        firstName,
        lastName,
        email,
        phone,
        allergies: Array.from(selectedAllergies),
        otherAllergy
      };

      // Legg til dokument i en samling i Firestore
      await addDoc(collection(db, 'responses'), formData);

      // Vurder å nullstille skjemaet eller gi bruker tilbakemelding
    } catch (error) {
      console.error("Feil ved innsending av skjema:", error);
    }
  }; */

  // Du kan nå bruke invitationId for å hente relevant data eller utføre andre handlinger
  return (
    <>
      <div> {/* Div with specifications for the "Main page" imported from designs.css */}
        <div className="pt-10">
          <div className="fixed inset-0 bg-white bg-opacity-75 transition-opacity" />
            <div className="fixed inset-0 z-10 overflow-y-auto p-20">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <form onSubmit={handleSubmit}>
                            <div className="space-y-12">
                            <div className="border-b border-gray-900/10 pb-12">
                                {/* <h1 className="text-base font-semibold leading-7 text-gray-900 pb-5"> Hei! Du er blitt invitert til {invitationData ? invitationData.invitationName : 'laster...'}</h1>
                                <p className="mt-1 text-sm leading-6 text-gray-600 pt-5 pb-5"> Beskrivelse</p>
                                <p className="mt-1 text-sm leading-6 text-gray-600 pt-5 pb-5"> {invitationData ? invitationData.description : 'Beskrivelse laster...'} </p>
                                <p className="mt-1 text-sm leading-6 text-gray-600 pt-5 pb-5">Vennligst fyll inn feltene under.</p> */}

                                {/* <h1 className="text-base font-semibold leading-7 text-gray-900 pb-5">
                                  Hei! Du er blitt invitert til: {invitationData ? invitationData.invitationName : 'laster...'}
                                </h1> */}
                                <h1 className="text-base font-semibold leading-7 text-gray-900 pb-2">
                                  Hei! Du er invitert til:
                                </h1>

                                {/* Invitasjonens navn */}
                                <h2 
                                  style={{fontSize: "50px"}}
                                  className="text-xl font-semibold leading-7 text-gray-900 p-5 m-10"
                                >
                                  {invitationData ? invitationData.invitationName : 'laster...'}
                                </h2>

                                {/* Underoverskrift "Beskrivelse" */}
                                <p 
                                  style={{fontSize: "18px"}}
                                  className="text-sm font-medium leading-6 text-black-700 pt-5">
                                  Beskrivelse:
                                </p>

                                {/* Selv beskrivelsesteksten */}
                                <p 
                                  className="text-md leading-relaxed text-black mt-3 pb-20 max-w-xl" // 'max-w-xl' for maks bredde og 'leading-relaxed' for linjeavstand
                                  style={{ fontSize: "18px" }}>
                                  {invitationData ? invitationData.description : 'Beskrivelse laster...'}
                                </p>

                                <p className="mt-1 text-sm leading-6 text-gray-600 pt-5">
                                  Vennligst fyll inn feltene under.
                                </p>

                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                      Fornavn
                                    </label>
                                    <div className="mt-2">
                                    <input
                                        type="text"
                                        name="first-name"
                                        id="first-name"
                                        autoComplete="given-name"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#257E7B] sm:text-sm sm:leading-6"
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                      Etternavn
                                    </label>
                                    <div className="mt-2">
                                    <input
                                        type="text"
                                        name="last-name"
                                        id="last-name"
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#257E7B] sm:text-sm sm:leading-6"
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                    </div>
                                </div>

                                <div className="sm:col-start-2 sm:col-span-4">
                                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    E-post addresse
                                  </label>
                                  <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#257E7B] sm:text-sm sm:leading-6"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div className="sm:col-start-2 sm:col-span-4">
                                    <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                                    Telefon
                                    </label>
                                    <div className="mt-2 flex">
                                    <select
                                        id="country-code"
                                        name="country-code"
                                        autoComplete="country-code"
                                        className="rounded-l-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#257E7B] sm:text-sm sm:leading-6"
                                        onChange={(e) => setCountryCode(e.target.value)}
                                    >
                                        <option value="+47">+47</option>
                                        <option value="+1">+1</option>
                                        <option value="+44">+44</option>   
                                    </select>
                                    <input
                                        id="phone"
                                        name="phone"
                                        type="tel"
                                        autoComplete="tel"
                                        required
                                        pattern="[0-9]{8}"
                                        title="Telefonnummeret må bestå av 8 siffer"
                                        className="flex-1 rounded-r-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#257E7B] sm:text-sm sm:leading-6"
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                    </div>
                                </div>
                                </div>
                                <div className="border-b border-gray-900/10 pb-12"> </div>
                                <fieldset>
                                <legend className="text-base font-semibold leading-6 text-gray-900 pt-5">Allergier</legend>
                                    <div className="space-y-5 pt-5">
                                    {commonAllergies.map((allergy) => (
                                        <div key={allergy.id} className="relative flex items-start">
                                        <div className="flex h-6 items-center">
                                            <input
                                            id={`allergy-${allergy.id}`}
                                            aria-describedby={`allergy-${allergy.id}-description`}
                                            name={`allergy-${allergy.id}`}
                                            type="checkbox"
                                            checked={selectedAllergies.has(allergy.id)}
                                            onChange={() => toggleAllergy(allergy.id)}
                                            className="h-4 w-4 rounded border-gray-300 text-[#257E7B] focus:ring-[#257E7B]"
                                            />
                                        </div>
                                        <div className="ml-3 text-sm leading-6">
                                            <label htmlFor={`allergy-${allergy.id}`} className="font-medium text-gray-900">
                                            {allergy.name}
                                            </label>{' '}
                                            <span id={`allergy-${allergy.id}-description`} className="text-gray-500">
                                            <span className="sr-only">{allergy.name} </span>{allergy.description}
                                            </span>
                                        </div>
                                        </div>
                                    ))}
                                    </div>
                                </fieldset>
                                <div className="mt-4">
                                    <label htmlFor="other-allergy" className="block text-sm font-medium leading-6 text-gray-900">
                                    Andre Merknader
                                    </label>
                                    <div className="mt-2">
                                    <input
                                        type="text"
                                        name="other-allergy"
                                        id="other-allergy"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#257E7B] sm:text-sm sm:leading-6"
                                        placeholder="Skriv inn andre merknader"
                                        value={otherNotes}
                                        onChange={(e) => setOtherNotes(e.target.value)}
                                    />
                                    </div>
                                </div>
                                </div>
                            </div>         
                            <div className="mb-10 mt-6 flex items-center justify-end gap-x-6">
                            {/* <button  type="button" className="text-sm font-semibold leading-6 text-gray-900">
                                Cancel
                            </button> */}
                            <button
                                type="submit"
                                className="rounded-md bg-[#257E7B] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#257E7B] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#257E7B]"
                                /* onClick={()=>handleThankYou} */ 
                            >
                                Send inn svar
                            </button>
                            </div>
              </form> 
            </div>
          </div>
        </div>       
      </div>                     
    </>
  );
};

export default GuestInvitePage;
