/*eslint-disable*/
import { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  ExclamationTriangleIcon
} from '@heroicons/react/24/outline';
import { useData } from '../../DataContext';
import { db, app } from "../../firebase";
import { doc, addDoc, collection, updateDoc, arrayUnion } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { lineWobble } from 'ldrs'
lineWobble.register("create-event-animation")


type SearchResult = {
  display_name: string;
  // Legg til andre felt her basert på API-responsen
  // For eksempel:
  // lat: string;
  // lon: string;
};

function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);


  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default function CreateEventPage({templateEvent, isOpen, onClose}: any) {
  const [locationQuery, setLocationQuery] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(locationQuery, 300);
  const { userData, setUserData, userId, setEventId, eventId  } = useData();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  


  const handleTriggerFunctionClick = async (newEventId: string) => {
    const functions = getFunctions(app, 'europe-west1');
    const triggerFunction = httpsCallable(functions, 'aiGenerateInspirationArticles');
    const triggerFunction2 = httpsCallable(functions, 'aiGenerateEventPlanningTips');
    
    try {
        // Endre nøkkelnavnet her til clientReferenceId
        const result = await triggerFunction({ clientReferenceId: newEventId });
        console.log(result.data);
        const result2 = await triggerFunction2({ clientReferenceId: newEventId });
        console.log(result2.data);
        // Håndter resultatet som du ønsker
    } catch (error) {
        console.error('Error calling function:', error);
    }
};

  function Alert({ message }: any) {
    return (
      <div className="rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">Attention needed</h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>{message}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }


  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setFormSubmitted(true);

    if (!event.currentTarget.checkValidity()) {
      setShowAlert(true);
      return;
    }

    setShowAlert(false);
  
    const newEvent = {
      title: templateEvent.title,
      eventDescription: templateEvent.description,
      creationDate: new Date().getTime(),
      budget: event.target.budget.value,
      numberGuests: event.target.numberGuests.value,
      location: locationQuery,
      supplierCategories: ["Catering", "LydOgLys", "Lokaler", "Transport", "Aktiviteter", "TakeAway", "Restaurant", "DekorOgDesign", "FotoOgVideo", "Foredragsholder", "Underholdning"],
      budgetItems: [],
      date: new Date(event.target.eventDate.value).getTime(),
      guestList: templateEvent.toDoList,
      invitationIds: [],
      toDoList: templateEvent.toDoList,
      users: [{ uid: userId }]
    };
  
    try {
      // Legge til det nye eventet i 'events'-samlingen
      const eventRef = await addDoc(collection(db, "events"), newEvent);
  
      // Oppdater brukerens dokument i 'users'-samlingen
      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, {
        'eventInfo.currentEvents': arrayUnion({ id: eventRef.id, title: newEvent.title })
      });

      await handleTriggerFunctionClick(eventRef.id)
  
      console.log("Event opprettet med ID:", eventRef.id);
      setEventId(eventRef.id)
      localStorage.setItem('currentEventId', eventRef.id);
      window.location.href = "/event-configuration/"+eventRef.id;
    } catch (error) {
      console.error("Feil ved oppretting av event:", error);
    }
  };
  

  useEffect(() => {
    if (debouncedSearchTerm && !debouncedSearchTerm.includes(',') && debouncedSearchTerm.length > 4) {
      setIsSearching(true);
      fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(debouncedSearchTerm)}`, {
        headers: {
          'User-Agent': 'YourApp/1.0 (your@email.com)'
        }
      })
      .then(response => response.json())
      .then(data => {
        setSearchResults(data);
        setIsSearching(false);
      })
      .catch(error => {
        console.error('Error:', error);
        setIsSearching(false);
      });
    } else {
      setSearchResults([]);
    }
  }, [debouncedSearchTerm]);

  const handleSelectLocation = (location: string) => {
    setLocationQuery(location);
    setSearchResults([]);
  };

  return (
            <Transition show={isOpen}>
            <Dialog open={isOpen} onClose={onClose}>
            <div  className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Dialog.Panel
 className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    {showAlert && <Alert message="Please fill in all required fields." />}
        <form onSubmit={handleSubmit} className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

              {/* Dato for event */}
              <div className="sm:col-span-6">
                <label htmlFor="eventDate" className="block text-sm font-medium leading-6 text-gray-900">
                  Dato for {templateEvent.title}
                </label>
                <input
                  type="date"
                  name="eventDate"
                  id="eventDate"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
                  required
                />
              </div>

              {/* Budsjett */}
              <div className="sm:col-span-3">
                <label htmlFor="budget" className="block text-sm font-medium leading-6 text-gray-900">
                  Budsjett
                </label>
                <input
                  type="number"
                  name="budget"
                  id="budget"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
                  placeholder="20000"
                  required
                />
              </div>

              {/* Antall gjester */}
              <div className="sm:col-span-3">
                <label htmlFor="numberGuests" className="block text-sm font-medium leading-6 text-gray-900">
                  Estimert antall gjester
                </label>
                <input
                  type="number"
                  name="numberGuests"
                  id="numberGuests"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
                  placeholder="50"
                  required
                />
              </div>

              

             {/* Sted for event */}
             <div className="sm:col-span-6">
                <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                  Lokasjon for {templateEvent.title} (adresse eller sted i Norge)
                </label>
                <div className="relative sm:col-span-6">
        <input
          type="text"
          name="location"
          id="location"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
          placeholder="Skriv inn en adresse"
          value={locationQuery}
          onChange={(e) => setLocationQuery(e.target.value)}
          required
        />
        <ul className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm text-gray-900 shadow-lg">
          {isSearching && <li>Søker...</li>}
          {searchResults.map((result, index) => (
            <li
              key={index}
              className="cursor-pointer px-3 py-2 hover:bg-gray-100"
              onClick={() => handleSelectLocation(result.display_name)}
            >
              {result.display_name}
            </li>
          ))}
        </ul>
      </div>
      </div>
      

              {/* Lagre-knapp */}
              <div className="sm:col-span-6">
              <button
              type="submit"
              disabled={formSubmitted}
              className="mt-2 w-full rounded-md bg-[#257E7B] py-2 px-4 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-[#257E7B] focus:ring-offset-2"
            >
              {formSubmitted ? (
                <div role="status" className="flex justify-center items-center">
                <span className="pr-8">Vennligst vent</span> <create-event-animation
                size="80"
                stroke="5"
                speed="1.75" 
                color="white" 
              ></create-event-animation>
              </div>
              ) : (
                <>{"Opprett "+templateEvent.title}</>
              )}
            </button>
              </div>
            </div>
          </div>
        </form>
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    </Transition>            
        );
}