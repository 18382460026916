import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import {
  ArchiveBoxIcon,
  ArrowRightCircleIcon,
  ChevronDownIcon,
  DocumentDuplicateIcon,
  HeartIcon,
  PencilSquareIcon,
  TrashIcon,
  UserPlusIcon,
} from '@heroicons/react/20/solid'
import { useData } from '../../DataContext';
import { db } from "../../firebase"; // Erstatt med din faktiske import-sti
import { getDoc, doc, deleteDoc, updateDoc, arrayRemove, arrayUnion } from "firebase/firestore";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}



export default function EventDropDownMenu() {
  const { userData, setUserData, userId, eventId  } = useData();

  const handleDeleteEvent = async () => {
    try {
      // Slett eventet fra 'events'-samlingen
      await deleteDoc(doc(db, "events", eventId));

      // Hent brukerens dokument
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userEvents = userDocSnap.data().eventInfo.currentEvents || [];
        const eventToRemove = userEvents.find((e: any) => e.id === eventId);

        // Fjern referansen til eventet fra brukerens dokument
        if (eventToRemove) {
          await updateDoc(userDocRef, {
            'eventInfo.currentEvents': arrayRemove(eventToRemove)
          });
        }
      }

      window.location.href = "/home"
      console.log("Event slettet");
      // Oppdater UI eller naviger til en annen side etter behov
    } catch (error) {
      console.error("Feil ved sletting av event:", error);
    }
  };

  const handleArchiveEvent = async () => {
    try {
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userEvents = userDocSnap.data().eventInfo.currentEvents || [];
        const eventToArchive = userEvents.find((e: any) => e.id === eventId);

        if (eventToArchive) {
          // Fjern eventet fra 'currentEvents' og legg det til i 'pastEvents'
          await updateDoc(userDocRef, {
            'eventInfo.currentEvents': arrayRemove(eventToArchive),
            'eventInfo.pastEvents': arrayUnion(eventToArchive)
          });

          console.log("Event arkivert");
          window.location.href = "/home"
        }
      }
    } catch (error) {
      console.error("Feil ved arkivering av event:", error);
    }
};
  
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex italic w-full gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold hover:bg-gray-200">
          Om mitt event
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-700" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm'
                  )}
                >
                  <PencilSquareIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Rediger
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm'
                  )}
                >
                  <DocumentDuplicateIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Dupliser
                </a>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={handleArchiveEvent}
                  className={classNames(
                    active ? 'bg-gray-100 text-[#257E7B]' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm'
                  )}
                >
                  <ArchiveBoxIcon className="mr-3 h-5 w-5 text-[#257E7B] group-hover:text-gray-500" aria-hidden="true" />
                  Arkiver
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm'
                  )}
                >
                  <UserPlusIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  Legg til team
                </a>
              )}
            </Menu.Item>
          </div>
         
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                href="#"
                onClick={handleDeleteEvent} // Legg til onClick handler
                className={classNames(
                  active ? 'bg-gray-100 text-[#257E7B]' : 'text-gray-700',
                  'group flex items-center px-4 py-2 text-sm'
                )}
              >
                <TrashIcon className="mr-3 h-5 w-5 text-[#257E7B] group-hover:text-gray-500" aria-hidden="true" />
                Slett
              </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
