import person3 from '../images/icons/person3.png';
import { useEffect, useState } from "react";
import { useData } from '../DataContext';
import TemplateCard from '../components/templates/TemplateCard'
import { grid } from 'ldrs'
import '../styles/designs.css';
import { useNavigate } from 'react-router-dom';
grid.register("template-animation")

export default function HomePage() {
  const { userData, setUserData, userId  } = useData();
  const navigate = useNavigate();

  const handleCreateEvent = () => {
    navigate("../create-event")
  };

  return (
    <>
    <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
        <div
          className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
          aria-hidden="true"
        />
        <div className="mx-auto max-w-7xl px-6 py-8 sm:py-10 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8 ">
          <template-animation
            size="80"
            speed="4" 
            color="#ef9b84" 
          ></template-animation>
            <h1 className="max-w-2xl text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:col-span-2 xl:col-auto">
              Kom i gang med en template.
            </h1>
            
            <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
              <p className="text-lg leading-8 text-gray-600">
                Våre eventeksperter har satt sammen noen typiske eventer som dere kan bruke for å komme i gang. Klikk på forslaget for å se hva dere kan få ut av å arrangere et slikt event!
              </p>
              <div className="mt-10 flex items-center gap-x-6">
               {/*  <a
                  href="#"
                  className="rounded-md bg-[#257E7B] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Se alle forslag
                </a> 
                <a href="/create-event" className="text-sm font-semibold leading-6 text-gray-900">
                  Opprett egendefinert event  <span aria-hidden="true">→</span>
                </a>
                */}
                <button className='buttonSecondaryGreen'>
                    Se alle forslag
                </button>
                <button className='buttonTernaryWhite' onClick={handleCreateEvent}>
                  Opprett egendefinert event  <span aria-hidden="true">→</span>
                </button>
                
            
              </div>
            </div>
            <img
              src={person3}
              alt=""
              className="mt-10 mx-auto aspect-[6/5] w-60 max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
            />
          </div>
        </div>
       
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-gray-100 sm:h-32" />
      </div>
      <div className="mx-20">
       <TemplateCard></TemplateCard>
       </div>
      </>
  )
}
