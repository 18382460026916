import React, { useState } from 'react';
import { db } from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { Dialog, Transition } from '@headlessui/react';
import { useData } from '../../../DataContext';
import DeleteModal from '../DeleteModal';

type Guest = {
    id: string;
    name: string;
    phone: string;
    email: string;
    specialNeeds: string;
    confirmed: boolean;
};

type EditGuestModalProps = {
    selectedGuest: Guest;
    isOpen: boolean;
    onClose: () => void;
};

export default function EditGuestModal({ selectedGuest, isOpen, onClose }: EditGuestModalProps) {
    const { eventId, eventData } = useData();
    const [localSelectedGuest, setLocalSelectedGuest] = useState<Guest>(selectedGuest);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);


    const updateGuest = async () => {
        console.log(localSelectedGuest)
        const updatedGuests = eventData.guestList.map((g:any) => g.id === localSelectedGuest.id ? { ...localSelectedGuest } : g);
        console.log(localSelectedGuest)

        const docRef = doc(db, "events", eventId);
        await updateDoc(docRef, { guestList: updatedGuests });

        onClose();
    };

    const openDeleteModal = () => {
        setIsDeleteModalOpen(true);
    };


    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
    };

    const deleteGuest = async () => {
        const updatedGuests = eventData.guestList.filter((g:any) => g.id !== localSelectedGuest.id);

        const docRef = doc(db, "events", eventId);
        await updateDoc(docRef, { guestList: updatedGuests });

        onClose();
    };

    return (
        <Transition show={isOpen}>
        <Dialog open={isOpen} onClose={onClose}>
            <DeleteModal
                isOpen={isDeleteModalOpen}
                setIsOpen={setIsDeleteModalOpen}
                handleDeleteInvitation={deleteGuest}
                closeButton={closeDeleteModal}
                modalTitle='Slett gjest'
                modalText='Er du sikker på at du vil slette denne gjesten?'
            />
            <div className="absolute inset-0 overflow-hidden">
                <Transition.Child
                    as="div"
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                />

                <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                    <Transition.Child
                        as="div"
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                        className="w-screen max-w-md"
                    >
                        {/* Innholdet til modalen */}
                        <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                            <div className="mt-2 p-4">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    Rediger Gjest
                                </h3>

                                {/* Navn */}
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                                        placeholder="Navn på gjest"
                                        value={localSelectedGuest.name}
                                        onChange={(e) => setLocalSelectedGuest({ ...localSelectedGuest, name: e.target.value })}
                                    />
                                </div>

                                {/* Telefonnummer */}
                                <div className="mt-4">
                                    <input
                                        type="text"
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                                        placeholder="Telefonnummer"
                                        value={localSelectedGuest.phone}
                                        onChange={(e) => setLocalSelectedGuest({ ...localSelectedGuest, phone: e.target.value })}
                                    />
                                </div>

                                {/* E-postadresse */}
                                <div className="mt-4">
                                    <input
                                        type="email"
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                                        placeholder="E-postadresse"
                                        value={localSelectedGuest.email}
                                        onChange={(e) => setLocalSelectedGuest({ ...localSelectedGuest, email: e.target.value })}
                                    />
                                </div>


                                {/* Spesielle behov */}
                                <div className="mt-4">
                                    <textarea
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                                        placeholder="Spesielle behov (f.eks. diett, tilgang)"
                                        value={localSelectedGuest.specialNeeds}
                                        onChange={(e) => setLocalSelectedGuest({ ...localSelectedGuest, specialNeeds: e.target.value })}
                                    />
                                </div>

                                {/* Bekreftet deltakelse */}
                                <div className="mt-4">
                                    <label className="flex items-center">
                                        <input
                                            type="checkbox"
                                            className="form-checkbox h-5 w-5 text-[#257E7B]"
                                            checked={localSelectedGuest.confirmed}
                                            onChange={(e) => setLocalSelectedGuest({ ...localSelectedGuest, confirmed: e.target.checked })}
                                        />
                                        <span className="ml-2 text-sm text-gray-900">Bekreftet deltakelse</span>
                                    </label>
                                </div>

                                {/* Knapper for å oppdatere og avbryte */}
                                <div className="mt-5 sm:mt-6 flex justify-end">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md border border-transparent bg-[#257E7B] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                        onClick={updateGuest}
                                    >
                                        Oppdater
                                    </button>
                                    {/* Sletteknapp */}
                                <button
                                    type="button"
                                    className="ml-3 inline-flex justify-center rounded-md bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                    onClick={openDeleteModal}
                                >
                                    Slett
                                </button>
                                    <button
                                        type="button"
                                        className="ml-3 inline-flex justify-center rounded-md bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                        onClick={onClose}
                                    >
                                        Lukk
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>
    );
}