import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useData } from '../../DataContext';

export default function Employees() {
  const { selectedOrganization } = useData();
  const [employees, setEmployees] = useState<Array<any>>([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      const firestoreMembers = selectedOrganization?.members || [];
      const firestoreUserDocs = await Promise.all(
        firestoreMembers.map((userId: string) => getDoc(doc(db, "users", userId)))
      );
      const firestoreUsersData = firestoreUserDocs
        .filter((doc) => doc.exists())
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
          source: 'firestore' // Add a source attribute to distinguish between Firestore and Microsoft members
        }));

      // Combine Firestore users with Microsoft members
      const microsoftMembers = selectedOrganization?.membersMicrosoft || [];
      const combinedEmployees = [...firestoreUsersData, ...microsoftMembers.map((member: any) => ({
        ...member, // Assuming member objects have id, personalInfo, and professionalInfo structured as needed
        source: 'microsoft'
      }))];

      setEmployees(combinedEmployees);
    };

    if (selectedOrganization) {
      fetchEmployees();
    }
  }, [selectedOrganization]);

  return (
    <div className="py-6 sm:py-8 mb-10 rounded-xl">
      <div className="bg-white rounded-xl p-8 mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Ansatte i {selectedOrganization.name}</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Her ser du en oversikt over alle ansatte i din organisasjon.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-12 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
        >
          {employees.map((employee) => (
            <li key={employee.id}>
              <img className="mx-auto h-24 w-24 rounded-full" src={employee.source === 'firestore' ? employee.personalInfo.profilePicture: employee.imageUrl} alt="" />
              <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900">
                {employee.source === 'firestore' ? employee.personalInfo.name : employee.name}
              </h3>
              <p className="text-sm leading-6 text-gray-600">
                {employee.source === 'firestore' ? employee.professionalInfo.position : employee.role}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}