
import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import BudgetItem from '../../components/Events/budget/BudgetItem';
import AddBudgetItemModal from '../../components/Events/budget/AddBudgetItemModal';
import EditBudgetItemModal from '../../components/Events/budget/EditBudgetItemModal';
import BudgetMenu from '../../components/Events/budget/BudgetMenu';
import { CheckCircleIcon, XCircleIcon, QuestionMarkCircleIcon, PlusIcon } from '@heroicons/react/20/solid';
import { useData } from '../../DataContext';
import '../../styles/designs.css'


function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

type BudgetItem = {
  id: string;
  title: string;
  amount: number;
  status: string;
  description: string;
};

const BudgetPage = ({ budget}: any) => {
    const [localBudget, setLocalBudget] = useState(budget);
    const [showBudgetModal, setShowBudgetModal] = useState(false);
    const [modalBudget, setModalBudget] = useState<number>(0.0); 
      const [isAddBudgetItemModalOpen, setIsAddBudgetItemModalOpen] = useState(false);
      const [isEditBudgetItemModalOpen, setIsEditBudgetItemModalOpen] = useState(false);
      const [selectedBudgetItem, setSelectedBudgetItem] = useState<BudgetItem | null>(null);
      const { eventData, eventId, setEventId } = useData();

    const openModal = () => {
        setShowBudgetModal(true);
    }

    const closeModal = () => {
        setLocalBudget(modalBudget)

        setShowBudgetModal(false)
    }

    const closeModalNoChange = () => {

        setShowBudgetModal(false)
    }


    interface Stat {
        name: string;
        value: string;
        changeType: 'positive' | 'negative';
      }

      const [stats, setStats] = useState<Stat[]>([]); // Definerer en state med typen Stat[]

    useEffect(() => {
        let totalSpent = 0;
        let totalPending = 0;
        eventData.budgetItems.forEach((item: any) => {
          const amount = parseFloat(item.amount) || 0;
          if (item.status === 'Paid') {
            totalSpent += amount;
          } else if (item.status === 'Pending') {
            totalPending += amount;
          }
        });
      
        const unusedBudget = localBudget - totalSpent;
        const newStats: Stat[] = [
            {
              name: 'Totalt budsjett',
              value: `${localBudget}`,
              changeType: totalSpent+totalPending <= localBudget ? 'positive' : 'negative' // Positiv hvis totalt budsjett er lik eller over totalt regnskap
            },
            {
              name: 'Ubrukt budsjett',
              value: `${unusedBudget}`,
              changeType: unusedBudget > 0 ? 'positive' : 'negative' // Positiv hvis ubrukt budsjett er større enn 0
            },
            {
              name: 'Ubetalte fakturaer',
              value: `${totalPending}`,
              changeType: totalPending === 0 ? 'positive' : 'negative' // Positiv hvis ingen ubetalte fakturaer
            },
            {
              name: 'Regnskap',
              value: `${totalSpent}`,
              changeType: (totalPending === 0 && eventData.budgetItems.length > 0) ? 'positive' : 'negative' // Positiv hvis ingen ubetalte fakturaer og det eksisterer budsjettposter
            },
          ];
          setStats(newStats);
        }, [eventData.budgetItems, localBudget]);


        const openBudgetItemDetails = (budgetItem: BudgetItem) => {
          setSelectedBudgetItem(budgetItem);
          setIsEditBudgetItemModalOpen(true);
      };

    return (
        <div className="bg-gray-100 flex">
          {isEditBudgetItemModalOpen && selectedBudgetItem ? <EditBudgetItemModal selectedBudgetItem={selectedBudgetItem} isOpen={isEditBudgetItemModalOpen} onClose={() => setIsEditBudgetItemModalOpen(false)} /> : ""}
            <div className="flex-1">
                    <AddBudgetItemModal
              isOpen={isAddBudgetItemModalOpen}
              onClose={() => setIsAddBudgetItemModalOpen(false)}
              budgetItems={eventData.budgetItems}
              eventId={eventId}
            />
              <button
                  type="button"
                  /* className="ml-4 squish-button mr-2 font-extrabold inline-flex justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    */
                  className='buttonTernaryGray squish-button ml-4 mr-2'
                  onClick={() => openModal()}
              >
                    <CheckCircleIcon className="-ml-2 mr-1.5 h-5 w-5" aria-hidden="true" />
                  Sett budsjett
              </button>
              <button
                type="button"
               /*  className="inline-flex squish-button justify-center font-extrabold rounded-md border border-transparent bg-[#257E7B] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                 */
                className='buttonSecondaryGreen squish-button'
                onClick={() => setIsAddBudgetItemModalOpen(true)}
              >
                <PlusIcon className="-ml-2 mr-1 h-5 w-5" aria-hidden="true" />
                Ny budsjettpost
              </button>
              <BudgetMenu/>
                    <Transition.Root show={showBudgetModal} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={closeModal}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                            <div>
                                                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                                </div>
                                                <div className="mt-3 text-center sm:mt-5">
                                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                        Sett budsjett
                                                    </Dialog.Title>
                                                    <div className="mt-2">
                                                        <div>
                                                            <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                                                                Totalt for hele eventet
                                                            </label>
                                                            <div className="relative mt-2 rounded-md shadow-sm">
                                                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                                    <span className="text-gray-500 sm:text-sm">kr</span>
                                                                </div>
                                                                <input
                                                                    type="number"
                                                                    name="price"
                                                                    id="price"
                                                                    className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                                                                    placeholder="0.00"
                                                                    aria-describedby="price-currency"
                                                                    onChange={e => setModalBudget(parseFloat(e.target.value))}
                                                                    
                                                                />
                                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                                        NOK
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-5 sm:mt-6 flex justify-center space-x-3">
                                              <button
                                                type="button"
                                                /* className="inline-flex justify-center rounded-md bg-[#257E7B] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                                 */
                                                className='buttonSecondaryGreen'
                                                
                                                onClick={() => closeModal()}
                                              >
                                                Bekreft budsjett
                                              </button>
                                              <button
                                                type="button"
                                                /* className="inline-flex justify-center rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                                                 */
                                                className='buttonTernaryGray'
                                                onClick={() => closeModalNoChange()}
                                              >
                                                Lukk
                                              </button>
                                            </div>
                                          </Dialog.Panel>
                                      </Transition.Child>
                                  </div>
                              </div>
                          </Dialog>
                      </Transition.Root>
                      <ul className="divide-y divide-gray-200 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl m-4">
                      <h3 className="text-base font-semibold leading-6 text-gray-900 p-4 text-xl">Budsjettposter</h3>
                      {eventData.budgetItems.map((item: any) => (
                      <a href="#" onClick={() => openBudgetItemDetails(item)}>
                      <BudgetItem
                        id={item.id}
                        title={item.title}
                        description={item.description}
                        amount={item.amount}
                        status={item.status}
                      />
                    </a>
                ))}
                </ul>
                  </div>


                  <dl className="p-4 pr-8 pt-20">
                    {stats.map((stat) => (
                      <div
                        key={stat.name}
                        className="mb-4 rounded-xl items-baseline justify-between gap-y-2 bg-white px-2 py-6 sm:px-6 xl:px-8"
                      >
                        <dt className="text-sm font-medium leading-6 text-gray-500">{stat.name}</dt>
                        <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                          {new Intl.NumberFormat('no-NB').format(Number(stat.value)) +" kr"}
                        </dd>
                        <dd
                          className={classNames(
                            stat.changeType === 'negative' ? 'text-orange-600' : 'text-gray-700',
                            'text-xs font-medium'
                          )}
                        >
                          {/* Viser tilpasset tekst istedenfor bare 'changeType' */}
                          {stat.changeType === 'positive' ? 
                            (stat.name === 'Totalt budsjett' ? 'Du er innenfor budsjett' : 
                            stat.name === 'Ubrukt budsjett' ? `Du har mer å gå på` : 
                            'Alt er betalt') : 
                            (stat.name === 'Totalt budsjett' ? 'Du har overskredet budsjettet' : 
                            stat.name === 'Ubrukt budsjett' ? 'Du har brukt hele budsjettet' : 
                            'En eller flere utestående')}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
    )
}

export default BudgetPage; 