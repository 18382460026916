import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react'
import { useData } from '../../DataContext';
import '../../styles/designs.css'


type Event = {
  id: number;
  title: string;
  eventId: string;
  initial: string;
  href: string;
};

    
export default function EventsOverview() {
  const navigate = useNavigate();
  const [activeEventId, setActiveEventId] = useState(1); // Initialverdi basert på default current
  const { userData, setUserData, userId, setEventId  } = useData();

  const events = userData.eventInfo?.currentEvents?.map((event: Event) => ({
      id: event.id,
      title: event.title,
      eventId: event.id, // eller en annen unik identifikator hvis tilgjengelig
      initial: event.title.charAt(0), // For eksempel, første bokstav av tittelen
      href: `/event-configuration/${event.id}` // Endre etter behov
  })) || [];

  const handleEventClick = (event: Event) => {
      localStorage.setItem('currentEventId', event.eventId);
      setActiveEventId(event.id);
      setEventId(event.id);
      navigate(event.href);
  };



  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="w-full max-w-2xl mx-auto">
        <ul
          role="list"
          className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
        >
          {events.map((event: Event) => (
            <li key={event.id} className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
              <div className="flex min-w-0 gap-x-4">
                {/* <span className="h-12 w-12 flex-none rounded-full bg-gray-50 flex items-center justify-center font-bold">{event.initial}</span> */}
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    <a href={event.href}>
                      <span className="absolute inset-0 -top-px bottom-0" />
                      {event.title}
                    </a>
                  </p>
                  {/* <p className="mt-1 flex text-xs leading-5 text-gray-500">
                    Event ID: <span className="relative truncate hover:underline ml-1">{event.eventId}</span>
                  </p> */}
                </div>
              </div>
              <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
