import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useData } from '../../DataContext';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

interface Event {
    id: string;
    title: string;
  }

export default function AddEventToTeam({ onClose }: { onClose: () => void }) {
    const [open, setOpen] = useState(true);
    const { userData, selectedTeam } = useData();
    const [events, setEvents] = useState<Event[]>([]);
    const [selectedEventId, setSelectedEventId] = useState("");

    useEffect(() => {
        const fetchEvents = async () => {
            const fetchedEvents = await Promise.all(
                userData.eventInfo.currentEvents.map(async (event: any) => {
                    const eventRef = doc(db, "events", event.id);
                    const eventSnap = await getDoc(eventRef);
                    return eventSnap.exists() ? { id: eventSnap.id, title: eventSnap.data().title } : null;
                })
            );
            setEvents(fetchedEvents.filter((event): event is Event => event !== null));
        };

        fetchEvents();
    }, [userData.eventInfo.currentEvents]);

  const closeDialog = () => {
    setOpen(false);
    setTimeout(() => onClose(), 500);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!selectedTeam || !selectedEventId) return;

    const updatedEvents = [...selectedTeam.events, selectedEventId];
    const teamRef = doc(db, "organizations", selectedTeam.orgId, "teams", selectedTeam.id);

    await updateDoc(teamRef, { events: updatedEvents });

    closeDialog();
};


  

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-stone-50 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <form onSubmit={handleSubmit}>
      <div className="space-y-8">
          <h2 className=" font-semibold leading-7 text-2xl text-gray-500">Legg til event i {selectedTeam.name}</h2>

         

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div>
            <label htmlFor="mainCategory" className="block text-sm font-medium leading-6 text-stone-900">
              Legg til event
            </label>
            <div className="mt-2">
            <select
             id="event"
             name="event"
            onChange={(e) => setSelectedEventId(e.target.value)}
          className="mt-2 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    >
        <option value="">Velg event</option>
         {events.map((event) => (
         <option key={event.id} value={event.id}>{event.title}</option>
                  ))}
         </select>
            </div>
          </div>
          </div>

          </div>


        <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={closeDialog}
            className="bg-stone-50 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#465745]"
          >
            Avbryt
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#217e7a] hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#465745]"
          >
            <CheckIcon className="mx-auto h-5 w-5 text-gray-300 mr-1" aria-hidden="true" /> Legg til
          </button>
        </div>
      </div>
        </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}