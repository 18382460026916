import React, { useEffect } from 'react';
import { doc, setDoc, getFirestore } from "firebase/firestore";
import { getAuth, getRedirectResult, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
//@ts-ignore
import { db, app } from "../firebase.ts";
//@ts-ignore
import { useData } from '../DataContext';

const AuthRedirectionPage = () => {
    const firestore = getFirestore();
    const auth = getAuth();
    const { userData, setUserData } = useData();

    useEffect(() => {
        getRedirectResult(auth).then(async (result) => {
          if (result) {
  
            const user = result.user;
            console.log("SIGNED IN USER: ", user);
            
            const newUserData = {
              personalInfo: {
                name: user.displayName || '',
                email: user.email || '',
                contact: {
                  phone: user.phoneNumber || '',
                  secondaryEmail: user.email || ''
                },
                profilePicture: user.photoURL || '',
                bio: ''
              },
              professionalInfo: {
                experience: '', 
                organization: '', 
                position: '' 
              },
              settings: {
                language: 'Norsk', 
                notifications: {
                  email: true, 
                  mobile: false
                },
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Oslo'
              },
              socialMedia: {
                Facebook: '',
                LinkedIn: '',
                Twitter: ''
              },
              technicalInfo: {
                devices: [],
                lastActive: new Date().toISOString(),
                registrationDate: user.metadata.creationTime
              },
              interests: [],
              miscellaneous: {
                customFields: {
                  hobbies: []
                },
                notes: ''
              },
              accessControl: {
                permissions: [],
                roles: {}
              },
              eventInfo: {
                currentEvents: [],
                pastEvents: []
              },
              alerts: []
            };
      
            const providerId = result.providerId;
            let accessToken;
            if (providerId === 'google.com') {
              const credential = GoogleAuthProvider.credentialFromResult(result);
              accessToken = credential?.accessToken;
              console.log("Google")
              // Handle Google-specific logic, if any
            } else if (providerId === 'microsoft.com') {
              const credential = OAuthProvider.credentialFromResult(result);
              accessToken = credential?.accessToken;
              console.log("Microsoft")
              // Handle Microsoft-specific logic, if any
            }
      
            // Update user document in Firestore with the common userData
            const userDocRef = doc(firestore, "users", user.uid);
            await setDoc(userDocRef, newUserData, { merge: true });
            console.log('User document updated with provider data');
      
            if (accessToken) {
              const functions = getFunctions(app, 'europe-west1');
              const getUserInfo = httpsCallable(functions, 'getUsersAndEventsMs');
  
              getUserInfo({ accessToken }).then(async (functionResult) => {
                console.log(functionResult.data);
                
               /* const organizationId = userData.currentOrganization;
                
                if (organizationId && functionResult.data.users) {
                  const orgDocRef = doc(firestore, "organization", organizationId);
              
                  // Using a transaction to safely update the organization document
                  try {
                    await runTransaction(firestore, async (transaction: any) => {
                      const orgDoc = await transaction.get(orgDocRef);
                      if (!orgDoc.exists()) {
                        console.error("Document does not exist!");
                        return;
                      }
              
                      const currentMembers = orgDoc.data().membersMicrosoft || [];
              
                      const updatedMembers = [...currentMembers, ...functionResult.data.users.filter((user: any) => !currentMembers.includes(user))];
              
                      // Update the document
                      transaction.update(orgDocRef, { membersMicrosoft: updatedMembers });
                    });
                    console.log("Organization document updated successfully.");
                  } catch (e) {
                    console.error("Transaction failed: ", e);
                  }
                }
                */
              }).catch((error) => {
                console.error("Error fetching additional user info:", error);
              });
  
            }
          }
        }).catch((error) => {
          console.error("Error during sign-in:", error.message);
        });
      }, [auth, firestore]);


    return (
        <>
<div className="bg-gray-100 flex min-h-full flex-1 mt-10">
<div className="flex justify-center mx-auto pb-40 items-center min-h-screen bg-gray-100">
      <dity-loader size="120" color="#257e7b"></dity-loader>
    </div>
      </div>
        </>
    )

}

export default AuthRedirectionPage