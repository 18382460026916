import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { ShieldExclamationIcon, HeartIcon, CheckBadgeIcon, UserGroupIcon, LockOpenIcon } from '@heroicons/react/24/outline'
import andreas from '../images/team/andreas.jpg'
import haavard from '../images/team/haavard.jpg'
import nanna from '../images/team/nanna.jpg'
import martin from '../images/team/martin.jpg'
import emil from '../images/team/emil.jpg'
import thor from '../images/team/thor.jpg'
import steven from '../images/team/steven.jpg'
import groupIcon from '../images/icons/group1.png'
import FooterSection from '../components/home/FooterSection'



const stats = [
  { label: 'tekstiler kaster hver nordmann årlig.', value: '23,5 kg' },
  { label: 'av alle klesmerker bryter grunnleggende menneskerettigheter.', value: '93%' },
  { label: 'kreves i snitt til å lage et par med jeans – det tilsvarer mengden vann en gjennomsnittlig person drikker over en periode på syv år.', value: '7500 liter vann' },
]
const values = [
    {
      name: 'Engasjere, lære og tenke nytt.',
      description:
        'Vi ønsker å ta ansvar der det er mulig. I en moteindustri preget av overforbruk og raske trender, ønsker vi å bidra til forbedring der vi kan.',
      icon: <HeartIcon className="mx-auto h-10 w-10 text-gray-500" />
    },
    {
      name: 'Utgjøre en forskjell.',
      description:
        'Vi ønsker å ha en positiv innvirkning på miljøet, mennesker og holdninger gjennom alle våre handlinger.',
      icon: <ShieldExclamationIcon className="mx-auto h-10 w-10 text-gray-500" />
    },
    {
      name: 'Opptre med integritet.',
      description:
        'Vi vil fremme kreativitet, unik stil og mangfold som vi ser i samfunnet rundt oss. Vi ønsker å bryte ut av fastsatte normer og åpne opp for at kreativitet, endring og lønnsomhet kan gå hånd i hånd.',
      icon: <CheckBadgeIcon className="mx-auto h-10 w-10 text-gray-500" />
    },
    {
      name: 'Jobbe sammen.',
      description:
        'Vi vil fremme kreativitet, unik stil og mangfold som vi ser i samfunnet rundt oss. Vi ønsker å bryte ut av fastsatte normer og åpne opp for at kreativitet, endring og lønnsomhet kan gå hånd i hånd.',
      icon: <UserGroupIcon className="mx-auto h-10 w-10 text-gray-500" />
    },
    {
      name: 'Skape tillit.',
      description:
        'Vi vil fremme kreativitet, unik stil og mangfold som vi ser i samfunnet rundt oss. Vi ønsker å bryte ut av fastsatte normer og åpne opp for at kreativitet, endring og lønnsomhet kan gå hånd i hånd.',
      icon: <LockOpenIcon className="mx-auto h-10 w-10 text-gray-500" />
    }
  ]
const team = [
  {
    name: 'Håvard Hellang',
    role: 'Co-Founder / CEO',
    email: "haavard@dity.no",
    linkedIn: "https://www.linkedin.com/in/h%C3%A5vard-hellang/",
    imageUrl: haavard
      
  },  {
    name: 'Andreas Hjertaker',
    role: 'Co-Founder / CTO',
    email: "andreas@dity.no",
    linkedIn: "https://www.linkedin.com/in/andreashjertaker/",
    imageUrl: andreas
  },
  {
    name: 'Nanna Gleditsch',
    role: 'Design og UX',
    email: "nanna@dity.no",
    linkedIn: "https://linkedin.com/in/solveig-bergan-grimstad-9351331b8",
    imageUrl: nanna
  },
  {
    name: 'Steven Xu',
    role: 'Co-Founder / Key account management',
    email: "steven@dity.no",
    linkedIn: "https://www.linkedin.com/in/stevenxu00/",
    imageUrl: steven
  },
  {
    name: 'Martin Kammen',
    role: 'Co-Founder / Key account management',
    email: "martin@dity.no",
    linkedIn: "https://www.linkedin.com/in/martin-kammen-8145b7236/",
    imageUrl: martin
  },
  {
    name: 'Thor Holtung',
    role: 'Co-Founder / Strategy',
    email: "thor@dity.no",
    linkedIn: "https://www.linkedin.com/in/thorajholtung/",
    imageUrl: thor
  },
  {
    name: 'Emil Berglund',
    role: 'Co-Founder / Utvikling',
    email: "emil@dity.no",
    linkedIn: "https://www.linkedin.com/in/emil-bj%C3%B8rlykke-berglund-70294b253/",
    imageUrl: emil
  },
]

export default function AboutPage() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="bg-gray-100 mt-28">

      <main className="isolate mb-20">

        {/* Team section */}
        <div className="mx-auto  max-w-7xl px-6 lg:px-8 grid grid-cols-1 md:grid-cols-2">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Hvem er dity?</h2>
            <p className="mt-10 text-xl leading-8 text-gray-600">
               Vi bygger organisasjoner ved å samle de ansatte.
                </p>
            <p className="mt-6 mr-10 text-lg leading-8 text-gray-600">
            Ved å forsterke organisasjonens evne til å arrangere målrettede eventer bygger vi kultur, psykososial trygghet og faglige grupperinger.
            </p>
          </div>
          <img className="w-60 h-auto mx-auto mt-auto" src={groupIcon}></img>
        </div>
        <div>
          <ul
            role="list"
            className="mx-auto grid grid-cols-2 sm:grid-cols-4 mt-20 max-w-2xl gap-x-8 gap-y-16 text-center mx-2 lg:mx-0 lg:max-w-none"
          >
            {team.map((person) => (
              <li key={person.name}>
                <img className="mx-auto mt-1 h-60 w-60 object-cover rounded-xl" src={person.imageUrl} alt="" />
                <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-stone-900">{person.name}</h3>
                <p className="text-sm leading-6 text-gray-600">{person.role}</p>
                <p className="text-xs leading-6 text-gray-600">{person.email}</p>
                {person.linkedIn?<a href={person.linkedIn} className="text-gray-400 hover:text-[#465745]">
                <img src="https://cdn.icon-icons.com/icons2/2428/PNG/512/linkedin_black_logo_icon_147114.png" className="mx-auto mt-1 h-6 w-6" aria-hidden="true" />
              </a> :""}
                
              </li>
            ))}
          </ul>
          </div>


        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
          <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {values.map((value) => (
            <div key={value.name}>
                {value.icon}
                <dt className="font-semibold text-stone-900 mt-4">{value.name}</dt>
                <dd className="mt-1 text-gray-600">{value.description}</dd>
            </div>
            ))}

          </dl>
        </div>
      </main>
      <FooterSection></FooterSection>
    </div>
  )
}
