import React from 'react';
import { quantum } from 'ldrs'

quantum.register("supplier-chat")



const EmptyMessages = () => {
  return (
    <div className="px-6 py-24 sm:py-16 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <p className="text-base font-semibold leading-7 text-[#227E7B]">Send din første melding med Dity's</p>
        <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Leverandør-chat</h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          Den første meldingen sendes som en mail til din valgte leverandør. Forklar eventet så godt du kan, og få hjelp med Dity's AI-generering.
        </p>
        <div className="mt-20">
        <supplier-chat
          size="60"
          speed="5" 
          color="#257e7b" 
        ></supplier-chat>
        </div>
      </div>
    </div>
  );
};

export default EmptyMessages;