import React from 'react';
import { Dialog } from '@headlessui/react';
import '../../../styles/designs.css';

interface Task {
  id: string;
  title: string;
  desc: string;
  done: boolean;
}

interface EditTaskModalProps {
    selectedTask: Task; // Anta at du har en Task type definert et sted
    setSelectedTask: (task: Task | null) => void;
    updateTask: () => void;
    deleteTask: () => void;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
  }

  export default function EditTaskModal({
    selectedTask,
    setSelectedTask,
    updateTask,
    deleteTask,
    isOpen,
    setIsOpen
  }: EditTaskModalProps) {


    
    return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                        <div>
                            <div className="mt-2">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    Rediger Oppgave
                                </h3>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
                                        value={selectedTask.title}
                                        onChange={(e) => setSelectedTask({ ...selectedTask, title: e.target.value })}
                                    />
                                </div>
                                <div className="mt-4">
                                    <textarea
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#257E7B] focus:ring-[#257E7B] sm:text-sm"
                                        value={selectedTask.desc}
                                        onChange={(e) => setSelectedTask({ ...selectedTask, desc: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6 flex justify-between">
                            <button
                                type="button"
                                /* className="inline-flex justify-center rounded-md border border-transparent bg-[#257E7B] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                 */
                                className='buttonSecondaryGreen'
                                onClick={updateTask}
                            >
                                Oppdater
                            </button>
                            <div>
                                <button
                                    type="button"
                                    /* className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                     */
                                    className='buttonWarningRed'
                                    onClick={deleteTask}
                                >
                                    Slett
                                </button>
                            <button
                        type="button"
                        onClick={() => setIsOpen(false)} // Endret fra setIsEditTaskModalOpen til setIsOpen
                        /* className="ml-3 inline-flex justify-center rounded-md bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                         */
                        className='buttonTernaryGray ml-3'
                    >
                        Lukk
                    </button>
                        </div>
                    </div>
                </Dialog.Panel>
            </div>
            </div>
        </Dialog>
    );
}