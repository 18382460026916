import React from 'react';
import imageGroup1 from '../../images/icons/group1.png'

const ThankYouPage = () => {
  return (
    <>
        
        <div className="fixed inset-0 bg-white bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <main className="relative isolate min-h-screen">
                  <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-black sm:text-5xl">Takk for din innsending!</h1>
                    <p className="mt-4 text-base text-black/70 sm:mt-6">Vi har mottatt din informasjon og du vil få en bekreftelse på epost.</p>
                   
                  </div>   
                  {/* <img
                    /* src="https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80"
                     *
                    src={imageGroup1}
                    alt=""
                    className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
                    /> */}
                    <div className="flex justify-center items-center">
                      <img
                      src={imageGroup1}
                      alt=""

                      style={{
                        width: '50%',
                        height: 'auto',
                      }}
                      />
                    </div>

                </main>
            </div>
        </div>
        
    </>
  );
};

export default ThankYouPage;
