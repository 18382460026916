// I DityPointsContext.js
import React, { createContext, useContext, useState } from 'react';
import DityPointsFlight from './components/DityPointsFlight';

const DityPointsFlightContext = createContext();

export const useDityPointsFlight = () => useContext(DityPointsFlightContext);

export const DityPointsFlightProvider = ({ children }) => {
  const [isActive, setIsActive] = useState(false);
  const [startCoordinates, setStartCoordinates] = useState({ x: 0, y: 0 });

  const startFlight = (x, y) => {
    setStartCoordinates({ x, y });
    setIsActive(true);
  };

  const finishFlight = () => {
    setIsActive(false);
  };

  return (
    <DityPointsFlightContext.Provider value={{ startFlight, finishFlight }}>
      {children}
      <DityPointsFlight isActive={isActive} startCoordinates={startCoordinates} onFinish={finishFlight} />
    </DityPointsFlightContext.Provider>
  );
};