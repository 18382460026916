import { PhotoIcon, UserIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import { useData } from '../../DataContext';
import { db, storage } from "../../firebase";
import { useEffect, useState } from 'react';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";

export default function Personal({ markStepAsComplete }: any) {
    const { userData, setUserData, userId } = useData();
    const [localPersonalInfo, setLocalPersonalInfo] = useState({
        name: '',
        email: '',
        phone: '',
        profilePicture: '',
        experience: '',
    });

    useEffect(() => {
        setLocalPersonalInfo({
            name: userData.personalInfo.name || '',
            email: userData.personalInfo.email || '',
            phone: userData.personalInfo.phone || '',
            profilePicture: userData.personalInfo.profilePicture || '',
            experience: userData.personalInfo.experience || '',
        });
    }, [userData]);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setLocalPersonalInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleImageChange = async (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const imageRef = ref(storage, `profilePictures/${userId}/${file.name}`);
            const snapshot = await uploadBytes(imageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref);
            setLocalPersonalInfo(prev => ({
                ...prev,
                profilePicture: downloadURL,
            }));
        }
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            const userRef = doc(db, "users", userId);
            await updateDoc(userRef, {
                personalInfo: localPersonalInfo, // Oppdaterer med data fra localPersonalInfo
            });
            markStepAsComplete();
        } catch (error) {
            console.error("Det skjedde en feil ved oppdatering av brukerdata: ", error);
            alert("Kunne ikke oppdatere profilen din.");
        }
    };

  return (
    <div className="space-y-10 divide-y divide-gray-900/10">

      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
        <div className="px-4 sm:px-8">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Personlig informasjon</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">Sjekk at informasjonen stemmer, og fyll ut mangler.</p>

          <UserIcon className="h-40 mx-auto mt-20 text-[#257e7b]"/>
        </div>

        <form onSubmit={handleSubmit} className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                  Fullt navn
                </label>
                <div className="mt-2">
                  <input
                    value={localPersonalInfo.name}
                    onChange={handleChange}
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#257E7B] sm:text-sm sm:leading-6"
                    required
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  E-postadresse
                </label>
                <div className="mt-2">
                  <input
                    value={userData.personalInfo.email || ''}
                    readOnly
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 py-1.5 italic text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#257E7B] sm:text-sm sm:leading-6"
                    required
                 />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                  Telefonnummer
                </label>
                <div className="mt-2">
                <input
                    value={localPersonalInfo.phone}
                    onChange={handleChange}
                    id="phone"
                    name="phone"
                    type="phone"
                    autoComplete="phone"
                    className="pl-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#257E7B] sm:text-sm sm:leading-6"
                    required
                  />

                </div>
              </div>

              <div className="col-span-full">
                <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                  Profilbilde
                </label>
                <div className="mt-2 flex items-center gap-x-3">
                    {localPersonalInfo.profilePicture?
                    <img className="h-14 w-14 rounded-full" src={localPersonalInfo.profilePicture}/>
                  :<UserCircleIcon className="h-14 w-14 text-gray-300" aria-hidden="true" />}
                  <input
                        type="file"
                        id="fileInput"
                        name="profilePicture"
                        accept="image/*"
                        className="hidden"
                        onChange={handleImageChange}
                        />
                  <label htmlFor="fileInput" className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 cursor-pointer">
                        Bytt
                        </label>
                </div>
              </div>

              <div className="col-span-full">
                <label htmlFor="experience" className="block text-sm font-medium leading-6 text-gray-900">
                  Erfaring
                </label>
                <div className="mt-2">
                  <textarea
                    id="experience"
                    value={localPersonalInfo.experience}
                     onChange={handleChange}
                    name="experience"
                    rows={2}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#257E7B] sm:text-sm sm:leading-6"
                    required
                 />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-600">Skriv et par setninger om din erfaring med eventplanlegging.</p>
              </div>

            </div>
          </div>
          <div className="flex items-center justify-center gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <button
              type="submit"
              className="rounded-md bg-[#257E7B] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#257E7B]"
            >
              Lagre og fortsett
            </button>
          </div>
        </form>
      </div>

    
    </div>
  )
}
