import React, { useState } from 'react';
import { app } from "../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import person2 from '../images/icons/person2.png'

export default function BookDemoPage() {
  const [submissionSuccessful, setSubmissionSuccessful] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    role: '',
    phone: '',
    message: ''
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault(); 
    const functions = getFunctions(app, 'europe-west1');
    const sendFormData = httpsCallable(functions, 'sendFormData');
  
    console.log("Form Data:", formData);
  
    try {
      setSubmissionSuccessful(true);
      const result = await sendFormData({
        ...formData
      });

      console.log("Result from sendFormData:", result.data);
    } catch (error) {
      console.error("Error calling sendFormData function:", error);
    }
  };

    return (
      <>
        <div> {/* Div with specifications for the "Main page" imported from designs.css */}
          <div className="relative">
            <div className="lg:absolute lg:inset-0 lg:left-1/2">
              <img
                className="h-64 w-full bg-gray-50 object-cover sm:h-80 lg:absolute lg:h-full"
                src="https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=2560&h=3413&&q=80"
                alt=""
              />
            </div>
            <div className="pb-24 pt-16 sm:pb-32 sm:pt-24 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:pt-32">
              <div className="px-6 lg:px-8">
    
            {submissionSuccessful?
            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900">Takk!</h2>
              <p className="mt-2 text-lg leading-8 text-gray-600">
                Vi har mottatt din forespørsel og tar kontakt så snart som mulig.
              </p>
            <img src={person2} className="w-80 h-auto mt-8" alt="Success" />
          </div>
            :<div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900">Book en demo</h2>
                  <p className="mt-2 text-lg leading-8 text-gray-600">
                    Hyggelig at du er interessert i en demo, vi trenger litt informasjon fra deg.
                  </p>
                  <form className="mt-16">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                      <div>
                        <label htmlFor="firstName" className="block text-sm font-semibold leading-6 text-gray-900">
                          Fornavn
                        </label>
                        <div className="mt-2.5">
                          <input
                            type="text"
                            name="firstName"
                            id="firstName"
                            onChange={handleInputChange}
                        autoComplete="given-name"
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#267e7a] sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div>
                        <label htmlFor="lastName" className="block text-sm font-semibold leading-6 text-gray-900">
                          Etternavn
                        </label>
                        <div className="mt-2.5">
                          <input
                            type="text"
                            name="lastName"
                            id="lastName"
                            onChange={handleInputChange}
                        autoComplete="family-name"
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#267e7a] sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                          E-post
                        </label>
                        <div className="mt-2.5">
                          <input
                            id="email"
                            onChange={handleInputChange}
                        name="email"
                            type="email"
                            autoComplete="email"
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#267e7a] sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-900">
                          Selskap
                        </label>
                        <div className="mt-2.5">
                          <input
                            type="text"
                            name="company"
                            id="company"
                            onChange={handleInputChange}
                        autoComplete="organization"
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#267e7a] sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <div className="flex justify-between text-sm leading-6">
                          <label htmlFor="role" className="block font-semibold text-gray-900">
                            Stilling
                          </label>
                        </div>
                        <div className="mt-2.5">
                          <input
                            type="text"
                            name="role"
                            id="role"
                            onChange={handleInputChange}
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#267e7a] sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <div className="flex justify-between text-sm leading-6">
                          <label htmlFor="phone" className="block font-semibold text-gray-900">
                            Telefon
                          </label>
                        </div>
                        <div className="mt-2.5">
                          <input
                            type="tel"
                            name="phone"
                            id="phone"
                            onChange={handleInputChange}
                        autoComplete="tel"
                            aria-describedby="phone-description"
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#267e7a] sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <div className="flex justify-between text-sm leading-6">
                          <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                            Fortell litt om deres behov
                          </label>
                          <p id="message-description" className="text-gray-400">
                            Maks 500 tegn
                          </p>
                        </div>
                        <div className="mt-2.5">
                          <textarea
                            id="message"
                            name="message"
                            rows={4}
                            onChange={handleInputChange}
                        aria-describedby="message-description"
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#267e7a] sm:text-sm sm:leading-6"
                            defaultValue={''}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-10 flex justify-end border-t border-gray-900/10 pt-8">
                      <button
                        onClick={handleSubmit}
                        className="rounded-md bg-[#267e7a] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#267e7a]"
                      >
                        Send melding
                      </button>
                    </div>
                  </form>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  