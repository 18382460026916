import { PhotoIcon, UserCircleIcon, BuildingOffice2Icon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react';
import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useData } from '../../DataContext';
import { doc, updateDoc } from "firebase/firestore"; 


type Team = {
    id: string;
};

type Organization = {
    id: string;
    name: string;
    domain: string;
    licenses: string[];
    teams: Team[];
};


export default function Professional({ markStepAsComplete }: any) {
    const { userData, setUserData, userId } = useData();
    const [organization, setOrganization] = useState<Organization | null>(null);
    const [localProfessionalInfo, setLocalProfessionalInfo] = useState({
        position: '',
        eventsArranged: '',
        organizationName: '',
        organizationId: '', 
        license: '',
        defaultTeamId: '',
    });


    useEffect(() => {
        const findOrganizationByDomain = async () => {
            // Ekstraherer domenet fra brukerens e-postadresse
            const emailDomain = userData.personalInfo.email.split('@')[1];
            // Oppretter en spørring basert på domene-strengen
            //const q = query(collection(db, "organizations"), where("domain", "==", emailDomain));
            const q = query(collection(db, "organizations"), where("domains", "array-contains", emailDomain));
            const querySnapshot = await getDocs(q);

            // Går gjennom resultatene og setter den funnede organisasjonen
            querySnapshot.forEach((doc: any) => {
                // Forutsatt at det kun finnes én organisasjon per domene
                setOrganization({ id: doc.id, ...doc.data() });
            });
        };

        if (userData.personalInfo.email) {
            findOrganizationByDomain();
        }
    }, [userData.personalInfo.email]);


    useEffect(() => {
        // Når organization oppdateres, oppdater også den lokale tilstanden
        if (organization) {
            setLocalProfessionalInfo(prev => ({
                ...prev,
                position: userData?.professionalInfo?.position || '',
                eventsArranged: userData?.professionalInfo?.eventsArranged || '',
                organizationName: organization?.name,
                organizationId: organization?.id,
                license: organization?.licenses[0] || '',
                defaultTeamId: organization?.teams?.length > 0 ? organization?.teams[0]?.id : '',
            }));
        }
    }, [organization]);



    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setLocalProfessionalInfo(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            const userDocRef = doc(db, "users", userId);
            await updateDoc(userDocRef, {
                professionalInfo: localProfessionalInfo,

            });
            markStepAsComplete(); 
        } catch (error) {
            console.error("Det oppstod en feil ved oppdatering av dokumentet:", error);
            alert("Det oppstod en feil under lagring. Vennligst prøv igjen.");
        }
    };


  return (
    <div className="space-y-10 divide-y divide-gray-900/10">

      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
      <div className="px-4 sm:px-8">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Organisasjon</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Vi må koble deg opp mot rett organisasjon og lisens. Flere organisasjoner kan bli lagt til senere.
          </p>

          <BuildingOffice2Icon className="h-40 mx-auto mt-20 text-[#257e7b]"/>
        </div>

        <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label htmlFor="organization" className="block text-sm font-medium leading-6 text-gray-900">
                  Organisasjon
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-[#257E7B] sm:max-w-md">
                
                                <input
                type="text"
                name="organization"
                id="organization"
                className="pl-4 italic block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-500 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                value={organization?.name || ''}
                readOnly // Make this field read-only
                required
              />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                  Lisens
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-[#257E7B] sm:max-w-md">
                
                  <input
                    type="text"
                    name="website"
                    id="website"
                    className="pl-4 italic block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-500 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    value={organization?.licenses[0] || ''}
                    readOnly // Make this field read-only
                    required
                  />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                  Rolle
                </label>
                <div className="mt-2">
                <input
                    type="text"
                    name="position"
                    value={localProfessionalInfo.position}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#257E7B] sm:text-sm sm:leading-6"
                    required
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="eventsArranged" className="block text-sm font-medium leading-6 text-gray-900">
                    Hvor mange eventer har du arrangert?
                </label>
                <div className="mt-2">
                    <select
                    name="eventsArranged" // Dette må matche nøkkelen i tilstandsobjektet
                    value={localProfessionalInfo.eventsArranged}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#257E7B] sm:text-sm sm:leading-6"
                   >
                    <option value="">Velg antall</option>
                    <option value="0">0</option>
                    <option value="1-5">1-5</option>
                    <option value="6-10">6-10</option>
                    <option value="11-20">11-20</option>
                    <option value="20+">Mer enn 20</option>
                    </select>
                </div>
                </div>
                </div>
              
          </div>
          <div className="flex items-center justify-center gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            
            <button
              className="rounded-md bg-[#257E7B] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#257E7B]"
              onClick={handleSubmit}
            >
              Lagre og fortsett
            </button>
          </div>
        </form>
      </div>

    </div>
  )
}
