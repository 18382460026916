import { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import {
  CalendarDaysIcon,
  CreditCardIcon,
  EllipsisVerticalIcon,
  UserCircleIcon,
  XMarkIcon as XMarkIconMini,
  PencilIcon
} from '@heroicons/react/20/solid'
import Employees from './Employees'
import Team from './Team'
import AddTeam from './AddTeam'
import { Organization } from '../../pages/TeamPage';
import { useData } from '../../DataContext'
import '../../styles/designs.css'

interface OverviewProps {
    selectedOrganization: Organization | null;
  }

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const Overview: React.FC<OverviewProps> = ({ selectedOrganization }) => {
    const [isAddOpen, setIsAddOpen] = useState(false);
    const [isEmployeesVisible, setIsEmployeesVisible] = useState(false);
    const { userId } = useData();

    const isOrgAdmin = selectedOrganization?.admins.includes(userId)

    const handleAddClick = () => {
        setIsAddOpen(true);
      };
  
    const handleCloseAdd = () => {
      setIsAddOpen(false);
    };

    const toggleEmployeesVisibility = () => { // New function to toggle Employees visibility
      setIsEmployeesVisible(!isEmployeesVisible);
    };


  return (
    <>

      <main>
        <header className="relative isolate pt-16">

          <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8">
            <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
              <div className="flex items-center gap-x-6">
                <img
                  src={selectedOrganization?.logo}
                  alt=""
                  className="h-16 w-16 flex-none rounded-xl bg-white p-2 ring-1 ring-gray-900/10"
                />
                <h1>
                  <div className="text-sm leading-6 text-gray-500">
                    Organisasjon <span className="text-gray-700">{selectedOrganization?.orgNum}</span>
                  </div>
                  <div className="mt-1 text-base font-semibold leading-6 text-gray-900">{selectedOrganization?.name}</div>
                </h1>
              </div>
              {isOrgAdmin?
              <div className="flex items-center gap-x-4 sm:gap-x-6">
                {/* Gammelt design */}
                {/* <button type="button" className="hidden text-sm font-semibold leading-6 text-gray-900 sm:block" onClick={toggleEmployeesVisibility}>
                  Se alle ansatte
                </button> 
                                <a href="#" className="hidden text-sm font-semibold leading-6 text-gray-900 sm:block">
                  Rediger
                </a>*/}
                <button className='buttonTernaryWhite' onClick={toggleEmployeesVisibility}>
                  Se alle ansatte
                </button>
                <button className='buttonTernaryWhite'>
                  Rediger
                </button>

                {/* Gammelt design */}
                {/* <a
                  onClick={handleAddClick}
                  className="cursor-pointer squish-button rounded-md bg-[#217e7a] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Nytt team
                </a> */}
                <button 
                  className='buttonSecondaryGreen'
                  onClick={handleAddClick}  
                >
                  Nytt team
                </button>

                <Menu as="div" className="relative sm:hidden">
                  <Menu.Button className="-m-3 block p-3">
                    <span className="sr-only">More</span>
                    <EllipsisVerticalIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900'
                            )}
                          >
                            Copy URL
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900'
                            )}
                          >
                            Rediger
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              :""}
            </div>
          </div>
        </header>
      
        {isEmployeesVisible && <Employees />}
        <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8 grid grid-cols-2">
        
       <Team />
        
           {isOrgAdmin?
            <div className="ml-8">
              <h2 className="sr-only">Summary</h2>
              <div className="rounded-lg bg-gray-50 pb-4 shadow-sm ring-1 ring-gray-900/5">
                <dl className="flex flex-wrap">
                  <div className="flex-auto pl-6 pt-6">
                    <dt className="text-sm font-semibold leading-6 text-gray-900">Kortinformasjon</dt>
                  </div>
                  
                  <div className="flex-none self-end px-6 pt-4">
                    <dt className="sr-only">Status</dt>
                    <dd className="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20">
                      Konfigurert
                    </dd>
                  </div>
                  <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                    <dt className="flex-none">
                      <span className="sr-only">Client</span>
                      <UserCircleIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd className="text-sm font-medium leading-6 text-gray-900">Navn Navnesen</dd>
                  </div>
                  <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                    <dt className="flex-none">
                      <span className="sr-only">Due date</span>
                      <CalendarDaysIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd className="text-sm leading-6 text-gray-500">
                      <time dateTime="2023-01-31">04, 2023</time>
                    </dd>
                  </div>
                  <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                    <dt className="flex-none">
                      <span className="sr-only">Status</span>
                      <CreditCardIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd className="text-sm leading-6 text-gray-500">**** **** **** 5262</dd>
                    <div className="ml-auto">
                    <PencilIcon className="h-5 w-5 text-gray-600 hover:text-gray-800 cursor-pointer" />
                  </div>
                  </div>
                </dl>
              </div>

              <div className="rounded-lg bg-gray-50 pb-4 my-4 shadow-sm ring-1 ring-gray-900/5">
                <dl className="flex flex-wrap">
                  <div className="flex-auto pl-6 pt-6">
                    <dt className="text-sm font-semibold leading-6 text-gray-900">Fakturainformasjon</dt>
                  </div>
                  <div className="flex-none self-end px-6 pt-4">
                    <dt className="sr-only">Status</dt>
                    <dd className="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20">
                      Konfigurert
                    </dd>
                  </div>
                  <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                    <dt className="flex-none">
                      <span className="sr-only">Client</span>
                      <UserCircleIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd className="text-sm font-medium leading-6 text-gray-900">Kontaktperson Etternavn</dd>
                  </div>
                  <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                    <dt className="flex-none">
                      <span className="sr-only">Due date</span>
                      <CalendarDaysIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd className="text-sm leading-6 text-gray-500">
                      <time dateTime="2023-01-31">Adresse</time>
                    </dd>
                  </div>
                  <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                    <dt className="flex-none">
                      <span className="sr-only">Status</span>
                      <CreditCardIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd className="text-sm leading-6 text-gray-500">E-postmottak</dd>
                  </div>
                  <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                    <dt className="flex-none">
                      <span className="sr-only">Status</span>
                      <CreditCardIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd className="text-sm leading-6 text-gray-500">Andre bemerkninger</dd>
                    <div className="ml-auto">
                    <PencilIcon className="h-5 w-5 text-gray-600 hover:text-gray-800 cursor-pointer" />
                  </div>
                  </div>

                </dl>
              </div>
            </div>
            :""}




          </div>
  
      </main>
      {isAddOpen && <AddTeam onClose={()=>handleCloseAdd()} />}
    </>
  )
}

export default Overview;