import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useData } from '../../../DataContext';
import { db, app } from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions(app, 'europe-west1');
const sendEmailToGuestlist = httpsCallable(functions, 'sendEmailToGuestlist');


export default function SendEmailModal({ onClose }: any) {
  const [open, setOpen] = useState(true)
  const [subject, setSubject] = useState('')
  const [content, setContent] = useState('')
  const { eventId, eventData } = useData();

  const cancelButtonRef = useRef(null)

  const handleSendEmail = async () => {
    try {
      const result = await sendEmailToGuestlist({ subject, content, eventData });

      console.log(result);
      handleClose(); 
    } catch (error) {

      console.error("Error sending email: ", error);
    }
  };
  

  const handleClose = () => {
    setOpen(false)
    if (onClose) onClose() 
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Send E-post
                  </Dialog.Title>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="w-full mt-4 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#217E7A] focus:border-[#217E7A]"
                      placeholder="Emne"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                    <textarea
                      className="w-full mt-4 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#217E7A] focus:border-[#217E7A]"
                      rows={4}
                      placeholder="Innhold"
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 space-y-3 sm:space-y-0 sm:flex sm:flex-row-reverse gap-8">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent bg-[#217E7A] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none sm:col-start-2 sm:text-sm"
                    onClick={handleSendEmail} // Oppdatert til å kalle handleSendEmail
                    disabled={!subject || !content}
                    >
                    Send e-post til alle
                    </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:mt-0 sm:text-sm"
                    onClick={handleClose}
                    ref={cancelButtonRef}
                  >
                    Avbryt
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}