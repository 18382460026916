import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "./styles/fonts.css"
import { DataProvider } from './DataContext';
import { DityPointsFlightProvider } from './DityPointsContext';

import "./i18n"

/* import {I18nextProvider} from "react-i18next";
import i18next from "i18next"; */

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <div style={{fontFamily:"Poppins"}}>
  <React.StrictMode>
  <DataProvider>
  <DityPointsFlightProvider>
    <App />
  </DityPointsFlightProvider>
  </DataProvider>
  </React.StrictMode>
  </div>
);
