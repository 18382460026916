
// InvitationPage.tsx
import React from 'react';
import { useState, useEffect } from 'react'
import { addDoc, collection, updateDoc, doc, getDoc, query, where, getDocs, deleteDoc } from 'firebase/firestore';

import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'

//@ts-ignore
import { db, app } from "../../firebase.ts";


import { useData } from '../../DataContext';


// For å importere stiler, bruk denne!
import '../../styles/designs.css'


// ---------------------------------------------------------------------------------
// Trenger kanskje ikke dette under ------------------------------------------------
// ---------------------------------------------------------------------------------

import { AiOutlineCheckCircle } from 'react-icons/ai'; // Importerer ikonet
// ---------------------------------------------------------------------------------

import AddInvitationModal from '../../components/Events/invitations/AddInvitationModal';
import DeleteModal from '../../components/Events/DeleteModal';
import EditInvitationModal from '../../components/Events/invitations/EditInvitationModal';
import CopyInvitationLinkModal from '../../components/Events/invitations/CopyInvitationLinkModal';



interface Invitation {
    id: string;
    invitationName: string;
    eventId: string;
    description: string;
    // Du kan legge til flere felt her basert på din invitasjonsstruktur
}


export default function InvitationPage() {

    const { eventData, eventId, setEventId } = useData();

    // Add modal    
    const [invitationModalOpen, setInvitationModalOpen] = useState(false);
    const [newInvitation, setNewInvitation] = useState({ id: '', name: '', description: ''});
    const [editingInvitation, setEditingInvitation] = useState({ id: '', invitationName: '', description: ''});
    const [invitationCount, setInvitationCount] = useState(0);
    const [invitations, setInvitations] = useState<Invitation[]>([]);
    const [currentInvitationId, setCurrentInvitationId] = useState<string | null>(null);
    const [currentInvitation, setCurrentInvitation] = useState<Invitation | null>(null);
    // Delete Modal
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    /* const [invitationToDelete, setInvitationToDelete] = useState<Invitation>(); */

    // Edit modal
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);


    // Funksjon for å åpne new invitation modalen
    const openNewInvitation = () => {
        setInvitationModalOpen(true);
    };

    const fetchData = async (eventId: string): Promise<Invitation[]> => {
        try {
            // Oppretter en spørring for å filtrere invitasjoner basert på eventId
            const q = query(collection(db, "invitations"), where("eventId", "==", eventId));
    
            // Utfører spørringen og henter dokumentene
            const querySnapshot = await getDocs(q);
            const invitations: Invitation[] = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data() as Omit<Invitation, 'id'>;
                invitations.push({ id: doc.id, ...data });
            });
    
            console.log("Hentede invitasjoner:", invitations);
            return invitations;
        } catch (error) {
            console.error("Det oppstod en feil under henting av invitasjoner", error);
            // Håndter eventuelle feil her
            return [];
        }
    };

    useEffect(() => {
        const getInvitations = async () => {
            const fetchedInvitations = await fetchData(eventId);
            setInvitations(fetchedInvitations);
        };

        console.log("Eventid: ", eventId)
        
        getInvitations();
    }, [invitationCount, eventId]);

    useEffect(()=> {
        

    }, [eventId]    );

    // Funksjon for å oppdatere en invitasjon
    const handleAddInvitation = async () => {

        console.log("Lager invitasjon:", newInvitation);

        if (!newInvitation.name) {
            console.log("Invitasjonens navn kan ikke være tomt");
            return;
        }
    
        const invitationData = {
            invitationName: newInvitation.name,
            eventId: eventId,
            description: newInvitation.description,
        };
    
        try {
            // Legger til den nye invitasjonen i Firestore
            const docRef = await addDoc(collection(db, "invitations"), invitationData);
            console.log("Invitasjon lagret med ID:", docRef.id);
    
            // Lukker modalen og nullstiller newInvitation
            handleCloseAddInvitation();
    
        } catch (error) {
            console.error("Det oppstod en feil under lagring av invitasjonen", error);
            // Håndter eventuelle feil her
        }
        
        setInvitationCount(count => count + 1);
    };

    const handleUpdateInvitation = async (updatedInvitation: Invitation) => {
        console.log("Oppdaterer invitasjon:", updatedInvitation);
    
        if (!updatedInvitation.invitationName) {
            console.log("Invitasjonens navn kan ikke være tomt");
            return;
        }
    
        const invitationData = {
            invitationName: updatedInvitation.invitationName,
            description: updatedInvitation.description,
            // Inkluder andre felt som trenger å bli oppdatert
        };
    
        try {
            // Referanse til det spesifikke dokumentet i Firestore
            const docRef = doc(db, "invitations", updatedInvitation.id);
    
            // Oppdaterer dokumentet med de nye dataene
            await updateDoc(docRef, invitationData);
            console.log("Invitasjon oppdatert med ID:", updatedInvitation.id);
    
            // Lukker modalen eller utfører andre oppfølgingshandlinger
    
        } catch (error) {
            console.error("Det oppstod en feil under oppdatering av invitasjonen", error);
            // Håndter eventuelle feil her
        }
    
        setInvitationCount(count => count + 1);
    };
    

    // Close AddInvitiation modal
    const handleCloseAddInvitation = () => {
        setInvitationModalOpen(false); 
        setNewInvitation({ id: '', name: '', description: ''});
    };

    const openEditInvitation = (invitationId: string) => {
        const temp = invitations.find(invitation => invitation.id === invitationId);
        console.log("Temp: ", temp)
        
        if (temp) {
            setCurrentInvitation(temp); // Sett den aktuelle invitasjonen hvis den finnes
            
            setIsEditModalOpen(true);    // Åpne modalen
        } else {
            // Håndter tilfelle hvor ingen invitasjon med gitt id finnes
            console.error('Ingen invitasjon funnet med id:', invitationId);
            // Du kan også sette noen form for brukervennlig feilmelding her
        }
    };

    const closeEditInvitation = () => {
        setIsEditModalOpen(false);
        setEditingInvitation({...editingInvitation, invitationName: 'TULL', description: 'TULL'});
    };
    
    
    // Funksjon for å slette en invitasjon
    const handleDeleteInvitation = async () => {
        if (!currentInvitationId) return;

        try {
            await deleteDoc(doc(db, "invitations", currentInvitationId));
            console.log("Invitasjon slettet:", currentInvitationId);
            
            // Oppdaterer listen etter sletting
            setInvitationCount(count => count + 1);
        } catch (error) {
            console.error("Det oppstod en feil under sletting av invitasjonen", error);
        }
        setIsDeleteModalOpen(false);
    };

    const openDeleteModal = (invitationId: string) => {
        setCurrentInvitationId(invitationId);
        setIsDeleteModalOpen(true);
    };


    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
    };
    

    const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
    /* const baseInvitationLink = 'https://events.dity.no/invitation/'; */ 
    const baseInvitationLink = 'http://localhost:3000/guest-invitation'; 
    /*const [baseInvitationLink, setBaseInvitationLink] = useState('http://localhost:3000/guest-invitation');*/    
    const [invitationLink, setInvitationLink] = useState(baseInvitationLink + currentInvitationId);

    const handleLinkCreation = (invitationId: string) => {
        /*  https://events.dity.no/invitation/eventId*/
        setIsCopyModalOpen(true);
        setInvitationLink(baseInvitationLink + `/` + invitationId )   
    };

    const previewPage = (invitationId : string) => {

        window.open(baseInvitationLink + `/` + invitationId, '_blank');
    };


    const handleInviteGuests = () => {
        console.log("Logikk skal komme seinere")
    }; 
    




    // ---------------------------------------------------------------------------------
    return (
      <div className='m-12 h-screen'>
        <button
            type="button"
            onClick={openNewInvitation}         
            className='buttonSecondaryGreen squish-button'
 
        >
            {/* <CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" /> */}
            Ny invitasjon
        </button>
        {/* <button
            type="button"
            className='buttonSecondaryGreen'  
                     
        >
            
            Ny invitasjon
        </button> */}

        <div className='flex-1'>
            <AddInvitationModal
                newInvitation={newInvitation}
                setNewInvitation={setNewInvitation}
                isOpen={invitationModalOpen}
                setIsOpen={setInvitationModalOpen}
                handleAddInvitation={handleAddInvitation}
                closeButton={handleCloseAddInvitation}
            />
        

            <DeleteModal
                isOpen={isDeleteModalOpen}
                setIsOpen={setIsDeleteModalOpen}
                handleDeleteInvitation={handleDeleteInvitation}
                closeButton={closeDeleteModal}
                modalTitle='SLETTE DENNE INVITASJONEN'
                modalText='Er du sikker på at du vil slette invitasjonen?'
            />

            <EditInvitationModal
                isOpen={isEditModalOpen}
                setEditInvitation={setEditingInvitation}
                setIsOpen={setIsEditModalOpen}
                invitation={currentInvitation}
                closeButton={closeEditInvitation}
                handleUpdateInvitation={handleUpdateInvitation}
            />

            <CopyInvitationLinkModal
                invitationLink={invitationLink}
                isOpen={isCopyModalOpen}
                setIsOpen={setIsCopyModalOpen}
            />
        </div>


        {invitations.length > 0 ? (
            <ul role="list" className="divide-y divide-gray-100 bg-white p-4 shadow-sm rounded-md">
                {invitations.map((invitation) => (
                    <li key={invitation.id} className="flex items-center justify-between gap-x-6 py-5">
                        <div className="min-w-0">
                            <div className="flex items-start gap-x-3">
                                <p className="text-sm font-semibold leading-6 text-gray-900">{invitation.invitationName}</p>
                            </div>
                            {/* Du kan legge til flere detaljer om invitasjonen her */}
                        </div>
                        <div className="flex flex-none items-center gap-x-4">
                            {/* Her kan du legge til handlinger for hver invitasjon, som å vise, redigere, eller slette */}
                            {/* Eksempel: */}
                            <button 
                                className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                /* onClick={() => openEditModal(invitation.id)} */
                                onClick={() => previewPage(invitation.id)}

                            >
                                Forhåndsvis
                            </button>
                            {/*Fersken   bg-[#EF9A84] */}
                            {/*Grønn     bg-[#257E7B]  */}
                            {/*Grå       bg-[#3B4058] */}
                            <button 
                                className="inline-flex items-center gap-x-2 rounded-md bg-[#3B4058] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                onClick={() => handleInviteGuests()}
                            >
                                Send til gjester
                            </button>
                            
                            <button 
                                className="inline-flex items-center gap-x-2 rounded-md bg-[#257E7B] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                onClick={() => handleLinkCreation(invitation.id)}
                                /* onClick={() => openInvitationLink(invitation.id)} */
                            >
                                Se Invitasjonslenke
                            </button>
                            <Menu as="div" className="relative flex-none">
                                <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                    <span className="sr-only">Åpne alternativer</span>
                                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                </Menu.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                    {/* Redigeringsknapp */}
                                    <Menu.Item>
                                        {({ active }) => (
                                        <button
                                            onClick={() => openEditInvitation(invitation.id)}
                                            className={`${
                                            active ? 'bg-gray-50' : ''
                                            } block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900`}
                                        >
                                            Rediger
                                        </button>
                                        )}
                                    </Menu.Item>
                                    {/* Dupliseringsknapp */}
                                    <Menu.Item>
                                        {({ active }) => (
                                        <button
                                            /* onClick={() => duplicateInvitation(invitation.id)} */
                                            className={`${
                                            active ? 'bg-gray-50' : ''
                                            } block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900`}
                                        >
                                            Dupliser
                                        </button>
                                        )}
                                    </Menu.Item>
                                    {/* Sletteknapp */}
                                    <Menu.Item>
                                        {({ active }) => (
                                        <button
                                            onClick={() => openDeleteModal(invitation.id)}
                                            className={`${
                                            active ? 'bg-red-400' : ''
                                            } block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900`}
                                        >
                                            Slett
                                        </button>
                                    
                                        )}{/* <button
                                        onClick={() => openDeleteModal(invitation.id)}
                                        className={`${
                                            active ? 'bg-red-600' : 'bg-red-500'
                                        } inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400`}
                                    >
                                        Slett
                                    </button> */}
                                        {/* <button
                                            onClick={() => openDeleteModal(invitation.id)}
                                            className={`${
                                            active ? 'bg-gray-50' : ''
                                            } block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900`}
                                        >
                                            Slett
                                        </button> */}
                                    </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                                                            

                            {/* <button 
                                type="button"
                                className="inline-flex w-full justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400 sm:ml-3 sm:w-auto"
                                onClick={() => openDeleteModal(invitation.id)}
                            >
                                Slett
                            </button> */}
                            {/* Flere knapper eller menyelementer kan legges til her */}
                        </div>
                    </li>
                ))}
            </ul>
        ) : (
            <div className="text-center p-4">
                <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                        d="M3 7v10c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                        d="M3 7l9 6 9-6" />
                </svg>
                <h3 className="mt-2 text-sm font-semibold text-gray-900">Ingen invitasjoner</h3>
                <p className="mt-1 text-sm text-gray-500">
                    Her kan du opprette invitasjoner til ditt arrangement.
                    <br />
                    Begynn ved å lage en ny invitasjon
                </p>
                <div className="mt-6">
                    {/* Eventuelle handlinger eller knapper */}
                </div>
            </div>
        )}
    </div>
    );
}




  
