import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/20/solid'

interface AlertCardProps {
    alert: {
        title: string;
        description: string;
        status: string;
        date: string;
        timestamp: string;
        eventId: string;
    };
}

const AlertCard: React.FC<AlertCardProps> = ({ alert }) => {
    return (
        <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow mb-4 cursor-pointer hover:shadow-xl">
            <div className="flex w-full items-center justify-between space-x-6 p-6">
                <div className="flex-1">
                    <div className="flex items-center space-x-3">
                        <h3 className="text-sm font-medium text-gray-900">{alert.title}</h3>
                        <span className={`inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ${alert.status === 'Venter' ? 'bg-yellow-100 text-yellow-800' : 'bg-green-100 text-green-800'}`}>
                            {alert.status}
                        </span>
                    </div>
                    <p className="mt-1 text-sm text-gray-500">{alert.description}</p>
                    <p className="mt-1 text-sm text-gray-500">{alert.date}</p>
                </div>
                <div className="flex-shrink-0">
                    {alert.status === 'Venter' ? <ExclamationCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    : <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />}
                </div>
            </div>
        </li>
    )
}

export default AlertCard;