import React, { useState, useEffect } from 'react';
import { chaoticOrbit } from 'ldrs'
chaoticOrbit.register('fly-dp')

const DityPointsFlight = ({ isActive, startCoordinates, onFinish }: any) => {
  const [style, setStyle] = useState({});

  useEffect(() => {
    if (isActive) {
      setStyle({
        position: 'absolute',
        left: startCoordinates.x + 'px',
        top: startCoordinates.y + 'px',
        transition: 'all 3s ease'
      });

      setTimeout(() => {
        setStyle((currentStyle) => ({
          ...currentStyle,
          left: window.innerWidth - 250 + 'px', // Juster etter behov
          top: '20px'
        }));
      }, 10);

      setTimeout(() => {
        onFinish();
      }, 3000);
    }
  }, [isActive, startCoordinates, onFinish]);

  if (!isActive) return null;

  return (
    <div style={style}>
      <fly-dp size="40" speed="1.4" color="#EF9B84"></fly-dp>
    </div>
  );
};

export default DityPointsFlight;