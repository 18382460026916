import { PhotoIcon, UserCircleIcon, BellIcon, HeartIcon } from '@heroicons/react/24/solid'
import { useData } from '../../DataContext';
import { db, app } from "../../firebase";
import { useEffect, useState } from 'react';
import { doc, updateDoc } from "firebase/firestore"; 

export default function Preferences({ markStepAsComplete }: any) {
  const { userData, setUserData, userId } = useData();
  const [preferences, setPreferences] = useState({
    emailActions: false,
    emailCommunication: false,
    emailUpdates: false,
    pushNotifications: '', // 'everything', 'email', or 'nothing'
  });

  useEffect(() => {
    if (userData.settings && userData.settings.notifications) {
      setPreferences({
        emailActions: userData.settings.notifications.emailActions || false,
        emailCommunication: userData.settings.notifications.emailCommunication || false,
        emailUpdates: userData.settings.notifications.emailUpdates || false,
        pushNotifications: userData.settings.notifications.pushNotifications || 'everything',
      });
    }
  }, [userData]);

  const handleChange = (e: any) => {
    const { name, type, checked, value } = e.target;
  
    if (type === "radio") {
      setPreferences((prev) => ({
        ...prev,
        pushNotifications: value,
      }));
    } else {
      setPreferences((prev) => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, {
        "settings.notifications": preferences,
      });
      markStepAsComplete();
    } catch (error) {
      console.error("Det skjedde en feil ved lagring av preferanser: ", error);
      alert("Kunne ikke lagre dine preferanser.");
    }
  };

  return (
    <div className="space-y-10 divide-y divide-gray-900/10">

      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
      <div className="px-4 sm:px-8">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Preferanser</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            For å gjøre tjenesten så brukervennlig som mulig må du ta noen siste valg her.
          </p>

          <BellIcon className="h-40 mx-auto mt-20 text-[#257e7b]"/>
        </div>

        <form onSubmit={handleSubmit} className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="max-w-2xl space-y-10">
              <fieldset>
                <legend className="text-sm font-semibold leading-6 text-gray-900">På mail</legend>
                <div className="mt-6 space-y-6">
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="emailActions"
                        name="emailActions"
                        type="checkbox"
                        checked={preferences.emailActions}
                        onChange={handleChange}
                        className="h-4 w-4 rounded border-gray-300 text-[#257E7B] focus:ring-[#257E7B]"
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label htmlFor="emailActions" className="font-medium text-gray-900">
                        Hendelser
                      </label>
                      <p className="text-gray-500">Få varsler når det skjer viktige ting i Dity Events</p>
                    </div>
                  </div>
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="emailCommunication"
                        name="emailCommunication"
                        checked={preferences.emailCommunication}
                        onChange={handleChange}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-[#257E7B] focus:ring-[#257E7B]"
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label htmlFor="emailCommunication" className="font-medium text-gray-900">
                       Kommunikasjon
                      </label>
                      <p className="text-gray-500">Få varsel når en leverandør sender melding.</p>
                    </div>
                  </div>
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="emailUpdates"
                        name="emailUpdates"
                        checked={preferences.emailUpdates}
                        onChange={handleChange}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-[#257E7B] focus:ring-[#257E7B]"
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label htmlFor="emailUpdates" className="font-medium text-gray-900">
                        Oppdateringer
                      </label>
                      <p className="text-gray-500">Få informasjon om funksjonalitet og kommende versjoner.</p>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend className="text-sm font-semibold leading-6 text-gray-900">Push-notifikasjoner</legend>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Disse leveres via SMS til din mobiltelefon.
                </p>
                <div className="mt-6 space-y-6">
                  <div className="flex items-center gap-x-3">
                  <input
                    id="push-everything"
                    name="push-notifications"
                    type="radio"
                    value="everything"
                    checked={preferences.pushNotifications === "everything"}
                    onChange={handleChange}
                    className="h-4 w-4 border-gray-300 text-[#257E7B] focus:ring-[#257E7B]"
                  />
                    <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                      Alt
                    </label>
                  </div>
                  <div className="flex items-center gap-x-3">
                  <input
                        id="push-email"
                        name="push-notifications"
                        type="radio"
                        value="email"
                        checked={preferences.pushNotifications === "email"}
                        onChange={handleChange}
                        className="h-4 w-4 border-gray-300 text-[#257E7B] focus:ring-[#257E7B]"
                    />
                    <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">
                      Samme som e-post
                    </label>
                  </div>
                  <div className="flex items-center gap-x-3">
                  <input
                        id="push-nothing"
                        name="push-notifications"
                        type="radio"
                        value="nothing"
                        checked={preferences.pushNotifications === "nothing"}
                        onChange={handleChange}
                        className="h-4 w-4 border-gray-300 text-[#257E7B] focus:ring-[#257E7B]"
                      />
                    <label htmlFor="push-nothing" className="block text-sm font-medium leading-6 text-gray-900">
                      Ingen push-notifikasjoner
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div className="flex items-center justify-center gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">

            <button
              type="submit"
              className="rounded-md bg-[#257E7B] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#257E7B]"
            >
              Lagre og fortsett
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
