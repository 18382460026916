import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import transEng from "./translation/english/translation.json"
import transNor from "./translation/norsk/translation.json"

const resources = {
    en: {
            translation: transEng,
    },
    no: {
        translation: transNor,
    },

}


i18next
.use(initReactI18next)
.init({
    resources,
    lng:"no", 
});

export default i18next;