// DataContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { onSnapshot, doc, collection, query, where, getDoc } from "firebase/firestore";
import { db } from "./firebase";


const initialEventData = {
    title: "",
    guests: [],
    budget: 0,
    numberTasks: 0,
    eventDate: new Date(),
    allTemplates: null,
    // Inkluder andre felt du trenger
};

const initialUserData = {
    
        userID: "uniqueUserID",
        personalInfo: {
          name: "Ola Nordmann",
          profilePicture: "urlToProfilePicture.jpg",
          bio: "Eventplanlegger og musikkentusiast.",
          contact: {
            phone: "+4798765432",
            secondaryEmail: "ola.nordmann@example.com"
          }
        },
        settings: {
          notifications: {
            email: true,
            mobile: false
          },
          language: "Norsk",
        timeZone: "Europe/Oslo"
        },
        socialMedia: {
        LinkedIn: "linkedin.com/in/olanordmann",
        Facebook: "facebook.com/ola.nordmann",
        Twitter: "@olanordmann"
        },
        eventInfo: {
        interests: ["Musikkfestivaler", "Forretningskonferanser"],
        pastEvents: ["eventID1", "eventID2"],
        favorites: ["eventID3"]
        },
        professionalInfo: {
        organization: "Norsk Event AS",
        position: "Event Koordinator",
        experience: "5 år i eventplanlegging"
        },
        accessControl: {
        roles: {
            team1: "Organisator",
            team2: "Deltaker"
        },
        permissions: ["createEvent", "editEvent"]
        },
        technicalInfo: {
        registrationDate: "2023-01-15",
        lastActive: "2024-01-24",
        devices: ["iPhone 12", "MacBook Pro"]
        },
        miscellaneous: {
        customFields: {
            hobbies: ["Fotografering", "Gitar"]
        },
        notes: "Husk å oppdatere eventkalenderen for neste måned."
        },
        securityAndPrivacy: {
        privacySettings: {
            profileVisible: true,
            activityLog: false
        },
        securityLog: ["Logged in from new device on 2024-01-20"]
        }
}

export const DataContext = createContext();

export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
    const [eventData, setEventData] = useState(initialEventData);
    const [userData, setUserData] = useState(initialUserData);
    const [conversations, setConversations] = useState([]);
    const [eventId, setEventId] = useState("");
    const [userId, setUserId] = useState("");
    const [organizations, setOrganizations] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);

    useEffect(() => {
        if (eventId) {
            const unsubscribeEvent = fetchEventData(eventId);
            return () => unsubscribeEvent();
        }
    }, [eventId]);

    useEffect(() => {
        if (userId) {
            console.log(userId)
            const unsubscribeUser = fetchUserData(userId);
            const unsubscribeConversations = fetchConversations(userId);
            return () => {
                unsubscribeUser();
                unsubscribeConversations();
            };
        }
    }, [userId]);

    const fetchEventData = (id) => {
        const docRef = doc(db, "events", id);

        const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                setEventData(docSnapshot.data());
               // console.log("Eventdata:", docSnapshot.data());
            } else {
                console.log("Eventdokumentet finnes ikke");
                setEventData(initialEventData);
            }
        });

        return unsubscribe; 
    };

    const fetchUserData = (id) => {
        const docRef = doc(db, "users", id);
    
        const unsubscribe = onSnapshot(docRef, async (docSnapshot) => {
            if (docSnapshot.exists()) {
                const userData = docSnapshot.data();
                setUserData(userData);
                // Sjekk om det finnes organizations og hent disse
                if (userData.organizations && userData.organizations.length > 0) {
                    const orgsData = await Promise.all(userData.organizations.map(async (orgId) => {
                        const orgRef = doc(db, "organizations", orgId);
                        const orgSnap = await getDoc(orgRef);
                        return orgSnap.exists() ? { id: orgSnap.id, ...orgSnap.data() } : null;
                    }));
                    // Fjern eventuelle null-verdier og oppdater tilstanden
                    setOrganizations(orgsData.filter(org => org !== null));
                } else {
                    // Nullstill organizations hvis brukeren ikke har noen
                    setOrganizations([]);
                }
            } else {
                console.log("Brukerdokumentet finnes ikke");
                setUserData(initialUserData);
                setOrganizations([]);
            }
        });
    
        return unsubscribe; 
    };

    const fetchConversations = (userId) => {
        const q = query(
            collection(db, "conversations"),
            where("participants", "array-contains", userId)
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const convs = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setConversations(convs);
        });

        return unsubscribe;
    };

    useEffect(() => {
        if (userData.selectedOrganization) {
            const orgRef = doc(db, "organizations", userData.selectedOrganization);
            
            const unsubscribe = onSnapshot(orgRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    setSelectedOrganization({ id: docSnapshot.id, ...docSnapshot.data() });
                } else {
                    console.log("Den valgte organisasjonen finnes ikke");
                    setSelectedOrganization(null);
                }
            });

            return () => unsubscribe();
        } else {
            setSelectedOrganization(null);
        
        }
    }, [userData.selectedOrganization]);


    useEffect(() => {
        if (selectedOrganization && userData.selectedTeam) {
            const team = selectedOrganization.teams.find(team => team.id === userData.selectedTeam);
    
            if (team) {
                setSelectedTeam(team);
                console.log(team);
            } else {
                console.log("Det valgte teamet finnes ikke");
                setSelectedTeam(null);
            }
        } else {
            setSelectedTeam(null);
        }
    }, [selectedOrganization, userData.selectedTeam]);

    return (
        <DataContext.Provider value={{
            eventData, setEventData, eventId, setEventId,
            userData, setUserData, userId, setUserId,
            conversations, setConversations,
            organizations, setOrganizations,
            selectedOrganization, setSelectedOrganization,
            selectedTeam, setSelectedTeam
        }}>
            {children}
        </DataContext.Provider>
    );
};