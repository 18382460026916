import React, { useState, useEffect } from 'react'; 
import {
    CakeIcon,
    WrenchIcon,
    BuildingOfficeIcon,
    TruckIcon,
    LightBulbIcon,
    PaperAirplaneIcon,
    HomeModernIcon,
    SwatchIcon,
    CameraIcon,
    MicrophoneIcon,
    AcademicCapIcon
  } from '@heroicons/react/24/outline';
  import SupplierCard, { Supplier } from '../../components/Events/supplier/SupplierCard';
  import SupplierDetail from '../../components/Events/supplier/SupplierDetail';
  import { useData } from '../../DataContext';
  import { addDoc, collection, serverTimestamp, getDocs, query, where } from "firebase/firestore";
  import { db } from "../../firebase";


  const categories = [
    { name: 'Catering', href: '#', icon: CakeIcon, current: true },
    { name: 'LydOgLys', href: '#', icon: WrenchIcon, current: false },
    { name: 'Lokaler', href: '#', icon: BuildingOfficeIcon, current: false },
    { name: 'Transport', href: '#', icon: TruckIcon, current: false },
    { name: 'Aktiviteter', href: '#', icon: LightBulbIcon, current: false },
    { name: 'TakeAway', href: '#', icon: PaperAirplaneIcon, current: false },
    { name: 'Restaurant', href: '#', icon: HomeModernIcon, current: false },
    { name: 'DekorOgDesign', href: '#', icon: SwatchIcon, current: false },
    { name: 'FotoOgVideo', href: '#', icon: CameraIcon, current: false },
    { name: 'Foredragsholder', href: '#', icon: AcademicCapIcon, current: false },
    { name: 'Underholdning', href: '#', icon: MicrophoneIcon, current: false },
  ];
  
  
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  
  export default function CategoryTabs() {
    const defaultCategory = categories.find((category) => category.current) || categories[0];
    const [currentCategory, setCurrentCategory] = useState(categories[0].name);
    const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(null);
    const [detailOpen, setDetailOpen] = useState(false);
    const { userData, setUserData, userId, setEventId, eventData, eventId  } = useData();
    const [suppliers, setSuppliers] = useState<Supplier[]>([]);

    useEffect(() => {
      const fetchSuppliers = async () => {
        // Opprett en spørring for å hente brukere som har supplierInfo
        const q = query(collection(db, "users"), where("supplierInfo", "!=", null));
  
        const querySnapshot = await getDocs(q);
        const suppliersData = querySnapshot.docs.map(doc => ({
          ...doc.data().supplierInfo,
          id: doc.id // Legg til dokumentets id
        }));
        setSuppliers(suppliersData);
        console.log("Supplier data", suppliersData)
      };
  
      fetchSuppliers();
    }, []);


  const handleSendMessage = async (supplierId: string, userId: string) => {
    // Opprett et nytt samtale-dokument
    const conversationDoc = {
        lastMessage: {
            senderId: userId,
            text: "Hei, jeg er interessert i tjenestene dine.",
            timestamp: serverTimestamp(),
        },
        lastUpdated: serverTimestamp(),
        messages: [], // Start med en tom meldingsliste
        metadata: {
            // Legg til nødvendig metadata her
        },
        participants: [supplierId, userId], // Anta at dette er ID-ene for leverandøren og brukeren.
        events: [eventId],
        subject: currentCategory+" til "+eventData.title,
    };

    try {
      const docRef = await addDoc(collection(db, "conversations"), conversationDoc);
      localStorage.setItem("lastConversationId", docRef.id);
      // Naviger brukeren til innboksen
      window.location.href = '/inbox'; // Eller bruk din routing-løsning for navigasjon
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };
    

  const activeCategories = categories.filter(category => 
    eventData.supplierCategories.includes(category.name)
  );
    

    const handleCategoryChange = (newCategoryName: string) => {
        setCurrentCategory(newCategoryName);
      }

      const handleCardClick = (supplier: Supplier) => {
        setSelectedSupplier(supplier);
        console.log(selectedSupplier)
        setDetailOpen(true);
      };


  return (
    <div className="bg-gray-100">
      <div className="lg:hidden p-4">
        <label htmlFor="categories" className="sr-only">Velg en kategori</label>
        <select
          id="categories"
          name="categories"
          className="block w-full rounded-md border-gray-300 focus:border-[#227E7B] focus:ring-[#227E7B]"
          value={currentCategory}
          onChange={(e) => handleCategoryChange(e.target.value)}
        >
          {categories.map((category) => (
            <option key={category.name}>{category.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden lg:block">
        <nav className="isolate flex flex-wrap divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
          {activeCategories.map((category, categoryIdx) => (
            <button
              key={category.name}
              onClick={() => handleCategoryChange(category.name)}
              className={classNames(
                currentCategory === category.name ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                categoryIdx === 0 ? 'rounded-l-lg' : '',
                categoryIdx === categories.length - 1 ? 'rounded-r-lg' : '',
                'group relative overflow-hidden bg-white py-2 px-2 flex items-center justify-center text-sm font-medium hover:bg-gray-50 focus:z-10'
              )}
              aria-current={currentCategory === category.name ? 'page' : undefined}
            >
              <category.icon className="h-5 w-5 mr-2" aria-hidden="true" />
              <span className="align-middle">{category.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  currentCategory === category.name ? 'bg-[#257E7B]' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5'
                )}
              />
            </button>
          ))}
        </nav>
      </div>
      <div className="p-4">
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {suppliers.filter(supplier => supplier.supplierType === currentCategory).map((supplier) => (
            <div key={supplier.email} onClick={() => handleCardClick(supplier)}>
              <SupplierCard 
                supplier={supplier} 
                onSendMessage={handleSendMessage}
                userId={userId}
              />
            </div>
          ))}
        </ul>
      </div>
      {selectedSupplier && (
        <SupplierDetail
        supplier={selectedSupplier}
        open={detailOpen}
        onClose={() => setDetailOpen(false)}
        onSendMessage={handleSendMessage} // Passing handleSendMessage as a prop
        userId={userId}
      />
      )}
    </div>
  );
}