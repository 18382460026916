import React, { useState } from 'react';
import { db } from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { Dialog, Transition } from '@headlessui/react';
import { useData } from '../../../DataContext';
import '../../../styles/designs.css';


type BudgetItem = {
    id: string;
    title: string;
    amount: number;
    status: string;
    description: string;
};

type EditBudgetItemModalProps = {
    selectedBudgetItem: BudgetItem;
    isOpen: boolean;
    onClose: () => void;
};

export default function EditBudgetItemModal({ selectedBudgetItem, isOpen, onClose }: EditBudgetItemModalProps) {
    const { eventId, eventData } = useData();
    const [localSelectedBudgetItem, setLocalSelectedBudgetItem] = useState<BudgetItem>(selectedBudgetItem);


    const updateBudgetItem = async () => {
        console.log(localSelectedBudgetItem)
        const updatedBudgetItems = eventData.budgetItems.map((g:any) => g.id === localSelectedBudgetItem.id ? { ...localSelectedBudgetItem } : g);
        console.log(localSelectedBudgetItem)

        const docRef = doc(db, "events", eventId);
        await updateDoc(docRef, { budgetItems: updatedBudgetItems });

        onClose();
    };

    const deleteBudgetItem = async () => {
        const updatedBudgetItems = eventData.budgetItems.filter((g:any) => g.id !== localSelectedBudgetItem.id);

        const docRef = doc(db, "events", eventId);
        await updateDoc(docRef, { budgetItems: updatedBudgetItems });

        onClose();
    };

    return (
        <Transition show={isOpen}>
        <Dialog open={isOpen} onClose={onClose}>
            <div className="absolute inset-0 overflow-hidden">
                <Transition.Child
                    as="div"
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                />

                <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                    <Transition.Child
                        as="div"
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                        className="w-screen max-w-md"
                    >
                        {/* Innholdet til modalen */}
                        <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                            <div className="mt-10 p-4">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    Rediger budsjettpost
                                </h3>

                                {/* Navn */}
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                                        placeholder="Navn på post"
                                        value={localSelectedBudgetItem.title}
                                        onChange={(e) => setLocalSelectedBudgetItem({ ...localSelectedBudgetItem, title: e.target.value })}
                                    />
                                </div>

                                {/* Beskrivelse */}
                                <div className="mt-4">
                                    <input
                                        type="text"
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                                        placeholder="Beskrivelse"
                                        value={localSelectedBudgetItem.description}
                                        onChange={(e) => setLocalSelectedBudgetItem({ ...localSelectedBudgetItem, description: e.target.value })}
                                    />
                                </div>

                                {/* Sum */}
                                        <div className="mt-4">
                                    <input
                                        type="number"
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                                        placeholder="Sum"
                                        value={localSelectedBudgetItem.amount.toString()}
                                        onChange={(e) => setLocalSelectedBudgetItem({ 
                                            ...localSelectedBudgetItem, 
                                            amount: parseFloat(e.target.value) || 0 
                                        })}
                                    />
                                </div>
                                {/* Status */}
                                <div className="mt-4">
                                    <label htmlFor="status-select" className="block text-sm font-medium text-gray-700">
                                        Status på betaling
                                    </label>
                                    <select
                                        id="status-select"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        value={localSelectedBudgetItem.status}
                                        onChange={(e) => setLocalSelectedBudgetItem({ ...localSelectedBudgetItem, status: e.target.value })}
                                    >
                                        <option value="">Velg status</option>
                                        <option value="Paid">Betalt</option>
                                        <option value="Pending">Ikke betalt</option>
                                    </select>
                                </div>
                                {/* Knapper for å oppdatere og avbryte */}
                                <div className="mt-5 sm:mt-6 flex justify-end">
                                    <button
                                        type="button"
                                        /* className="inline-flex justify-center rounded-md border border-transparent bg-[#257E7B] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                         */
                                        className='buttonSecondaryGreen'
                                        onClick={updateBudgetItem}
                                    >
                                        Oppdater
                                    </button>
                                    {/* Sletteknapp */}
                                    <button
                                        type="button"
                                        /* className="ml-3 inline-flex justify-center rounded-md bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                         */
                                        className='buttonWarningRed ml-3'
                                        onClick={deleteBudgetItem}
                                    >
                                        Slett
                                    </button>
                                    <button
                                        type="button"
                                        /* className="ml-3 inline-flex justify-center rounded-md bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                         */
                                        className='buttonTernaryGray ml-3'
                                        onClick={onClose}
                                    >
                                        Lukk
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>
    );
}