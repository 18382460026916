import { ChevronRightIcon } from '@heroicons/react/20/solid'
import placeholderLogo from '../images/placeholder.png';
import conceptLogo from '../images/dity.concept.black.png';
import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import HeadSection from '../components/home/HeadSection'
import FeatureSection from '../components/home/FeatureSection'
import PricingSection from '../components/home/PricingSection'
import FooterSection from '../components/home/FooterSection'


import '../styles/designs.css'

export default function Home() {


  return (

    <div>
      <div className="bg-gray-100">

      <HeadSection></HeadSection>

      <div id="features">
      <FeatureSection></FeatureSection>
      </div>

      <div id="pricing">
      <PricingSection></PricingSection>
      </div>
      <FooterSection></FooterSection>
      </div>
    </div>

  )
}
