import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { PhotoIcon } from '@heroicons/react/24/solid';
import { useData } from '../../DataContext'
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase'

export default function AddTeammate({onClose}: { onClose: () => void }) {
  const [open, setOpen] = useState(true)
  const [selectedPeople, setSelectedPeople] = useState<Array<{ id: string; name: string }>>([]);
  const [memberOptions, setMemberOptions] = useState<Array<{ id: string; name: string; }>>([]);
  const { selectedOrganization, selectedTeam } = useData();

  useEffect(() => {
    const fetchMembers = async () => {
      const members = await Promise.all(
        selectedOrganization.members.filter((memberId: string) => !selectedTeam.users.includes(memberId))
        .map(async (memberId: string) => {
          const memberRef = doc(db, "users", memberId);
          const memberSnap = await getDoc(memberRef);
          return memberSnap.exists() ? { id: memberId, name: memberSnap.data().personalInfo.name } : null;
        })
      );
      setMemberOptions(members.filter(member => member !== null) as Array<{ id: string; name: string; }>);
    };

    fetchMembers();
  }, [selectedOrganization, selectedTeam]);

  const closeDialog = () => {
    setSelectedPeople([])
    setOpen(false);
    setTimeout(() => onClose(), 500);
  };

  const handleAddPerson = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const personId = event.target.value;
    const person = memberOptions.find((member: any) => member.id === personId);
    if (person && !selectedPeople.find(p => p.id === person.id)) {
      setSelectedPeople(prev => [...prev, person]);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!selectedOrganization || !selectedTeam) return;

    const teamIndex = selectedOrganization.teams.findIndex((team: any) => team.id === selectedTeam.id);

    if (teamIndex !== -1) {
      const updatedTeams = [...selectedOrganization.teams];
      const updatedTeamUsers = updatedTeams[teamIndex].users ? [...updatedTeams[teamIndex].users, ...selectedPeople.map(p => p.id)] : [...selectedPeople.map(p => p.id)];
      updatedTeams[teamIndex] = { ...updatedTeams[teamIndex], users: updatedTeamUsers };

      await updateDoc(doc(db, "organizations", selectedOrganization.id), { ...selectedOrganization, teams: updatedTeams });

      setSelectedPeople([]);
      closeDialog();
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-stone-50 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <form onSubmit={handleSubmit}> 
      <div className="space-y-8">
          <h2 className=" font-semibold leading-7 text-2xl text-gray-500">Legg til et teammedlem
          <p className="text-sm font-light leading-6 text-gray-500">Personen må være medlem av organisasjonen</p>
          </h2>
          

         

          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
            <label htmlFor="mainCategory" className="block text-sm font-medium leading-6 text-stone-900">
              Legg til medlem
            </label>
            <div className="mt-2">
            <select
                    id="mainCategory"
                    name="mainCategory"
                    autoComplete="mainCategory"
                    onChange={handleAddPerson}
                    className="block w-full p-2 rounded-md border-gray-300 shadow-sm ring-1 ring-inset ring-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option value="">Velg en person</option>
                    {memberOptions.map((member) => (
                      <option key={member.id} value={member.id}>{member.name}</option>
                    ))}
                  </select>
            </div>
          </div>
           
            <div className="mt-2">
            <label htmlFor="mainCategory" className="block text-sm font-medium leading-6 text-stone-900">Valgte medlemmer</label>
            <ul>
              {selectedPeople.map((person, index) => (
                <li key={index} className="text-sm text-gray-500">{person.name}</li>
              ))}
            </ul>
          </div>
          </div>
          </div>


        <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={closeDialog}
            className="bg-stone-50 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#465745]"
          >
            Avbryt
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#217e7a] hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#465745]"
          >
            <CheckIcon className="mx-auto h-5 w-5 text-gray-300 mr-1" aria-hidden="true" /> Legg til
          </button>
        </div>
      </div>
     
        </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}