import { useState, useEffect } from "react"
//@ts-ignore
import { db, app } from "../firebase.ts";
//@ts-ignore
import {
    query,
    collection,
    getDocs,
    where,
    DocumentData,
} from "firebase/firestore";


import '../styles/designs.css'

import { getAuth, signOut, createUserWithEmailAndPassword, getRedirectResult, signInWithRedirect, GoogleAuthProvider } from "firebase/auth";

/*
const createUser = async (userData: any) => {
    try {
        const docRef = await addDoc(collection(db, "users"), userData);
        console.log("Dokument skrevet med ID: ", docRef.id);
        return docRef.id; // Returnerer ID til det opprettede dokumentet
    } catch (e) {
        console.error("Feil ved opprettelse av dokument: ", e);
        return null;
    }
};

const newUserData = {
  userID: "uniqueUserID",
  personalInfo: {
      name: "Ny Bruker",
      profilePicture: "https://example.com/urlToProfilePicture.jpg",
      bio: "Bio for ny bruker",
      contact: {
          phone: "+4712345678",
          secondaryEmail: "ny.bruker@example.com"
      }
  },
  settings: {
      notifications: {
          email: true,
          mobile: false
      },
      language: "Norsk",
      timeZone: "Europe/Oslo"
  },
  socialMedia: {
      LinkedIn: "linkedin.com/in/nybruker",
      Facebook: "facebook.com/ny.bruker",
      Twitter: "@nybruker"
  },
  eventInfo: {
      interests: ["Musikkfestivaler", "Forretningskonferanser"],
      pastEvents: [],
      favorites: []
  },
  professionalInfo: {
      organization: "Ny Organisasjon AS",
      position: "Ny Stilling",
      experience: "1 år i eventplanlegging"
  },
  accessControl: {
      roles: {
          team1: "Deltaker",
          team2: "Gjest"
      },
      permissions: ["viewEvent"]
  },
  technicalInfo: {
      registrationDate: "2024-01-01",
      lastActive: "2024-01-01T12:00:00Z",
      devices: ["iPhone 13", "MacBook Air"]
  },
  miscellaneous: {
      customFields: {
          hobbies: ["Maling", "Fotografering"]
      },
      notes: "Dette er notater for ny bruker."
  },
  securityAndPrivacy: {
      privacySettings: {
          profileVisible: true,
          activityLog: true
      },
      securityLog: ["Logged in from new device on 2024-01-01"]
  }
};
*/

const NewUserPage = () => {

    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [verifyPassword, setVerifyPassowrd] = useState<string>("")


    const auth = getAuth(app)
    const provider = new GoogleAuthProvider()
    
    const createNewUser = () => {

        if ( verifyEqualPasswords() ) { 


            createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // signed up 
                const user = userCredential.user; 
                console.log("BRUKERINFO: ", user)
                console.log("USER CREATED")

                signOut(auth).then(() => {
                    window.location.href = "/authentication"
                }).catch((error) => {console.error("error during signout part")})
            })
            .catch((error) => {
                const errorCode = error.code
                const errorMessage = error.message
                console.error(`Error creating user, code: ${errorCode}, message: ${errorMessage}`)
            })
        }
    }

    const newUserWithGoogle = () => { 
        signInWithRedirect(auth, provider)
        .then((result) => {
            window.location.href = "/authentication"
        })
    }

    getRedirectResult(auth).then((status) => {
        console.log(status)
        if (status) { 
            window.location.href = "/authentication"
        }
    })
    .catch((error) => {
        console.error("error logging in with google: ", error.message)
    })

    const verifyEqualPasswords = () =>  {

        console.log(password === verifyPassword)

        return password === verifyPassword



    }

    const checkInputFields = () => {

        if (email === "" || password === "" || verifyPassword === "") { 
            return false
        }

        return true

    }



    return (

        <>
            <div> {/* Div with specifications for the "Main page" imported from designs.css */}
                <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8" style={{ background: "linear-gradient(to right, rgb(58, 28, 113), rgb(215, 109, 119), rgb(255, 175, 123))", minHeight: "100vh", width: "100vw" }}>
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <img className="mx-auto h-10 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Your Company" />
                        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Login på DITY Events</h2>
                    </div>

                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                        <form className="space-y-6">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email addresse</label>
                                <div className="mt-2">
                                    <input id="email" name="email" type="email" autoComplete="email" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setEmail(e.target.value)} />
                                </div>
                            </div>

                            <div>
                                <div className="flex items-center justify-between">
                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Passord</label>
                                </div>
                                <div className="mt-2">
                                    <input id="password" name="password" type="password" autoComplete="current-password" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setPassword(e.target.value)} />
                                </div>
                            </div>

                            <div>
                                <div className="flex items-center justify-between">
                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Gjennta Passord</label>
                                </div>
                                <div className="mt-2">
                                    <input id="verifyPassword" name="verifyPassword" type="password" autoComplete="current-password" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setVerifyPassowrd(e.target.value)} />
                                </div>
                            </div>

                            
                        </form>

                        <div>
                                <button className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={() => createNewUser()}
                                >Log inn!</button>
                                <button className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={() => newUserWithGoogle()}
                                >Lag ny bruker med Google!</button>
                            </div>


                    </div>
                </div>
            </div>
        </>
    )

}

export default NewUserPage