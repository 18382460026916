import '../styles/designs.css'


export default function BouncePage() {
    return (
      <>
        {/*
          This example requires updating your template:
  
          ```
          <html class="h-full">
          <body class="h-full">
          ```
        */}
        <div> {/* Div with specifications for the "Main page" imported from designs.css */}
          <main className="grid min-h-full place-items-center bg-gray-100 px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
              <p className="text-base font-semibold text-[#257E7B]">404</p>
              <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Siden finnes ikke</h1>
              <p className="mt-6 text-base leading-7 text-gray-600">Beklager, denne siden er ikke laget enda.</p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="#"
                  className="rounded-md bg-[#257E7B] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#257E7B]"
                >
                  Gå hjem
                </a>
                <a href="home" className="text-sm font-semibold text-gray-900">
                  Kontakt support <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
          </main>
        </div>
      </>
    )
  }
  