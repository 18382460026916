import React, { useState, useEffect, Fragment} from 'react';
import { db, app } from "../../firebase";
import { addDoc, onSnapshot, collection, updateDoc, doc, getDoc, query, where, getDocs, deleteDoc } from 'firebase/firestore';
import { CheckCircleIcon, XCircleIcon, QuestionMarkCircleIcon, PlusIcon, DocumentDuplicateIcon, EnvelopeIcon, AtSymbolIcon, ChatBubbleOvalLeftEllipsisIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Menu, Transition, Switch } from '@headlessui/react';
import { ChevronRightIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { motion } from 'framer-motion';
import { useData } from '../../DataContext';
import * as XLSX from 'xlsx';
import GuestMenu from '../../components/Events/guests/GuestMenu';
import AddGuestModal from '../../components/Events/guests/AddGuestModal';
import EditGuestModal from '../../components/Events/guests/EditGuestModal';
import AddGuestSearch from '../../components/Events/guests/AddGuestSearch';

//Invitation imports
import AddInvitationModal from '../../components/Events/invitations/AddInvitationModal';
import DeleteModal from '../../components/Events/DeleteModal';
import EditInvitationModal from '../../components/Events/invitations/EditInvitationModal';
import CopyInvitationLinkModal from '../../components/Events/invitations/CopyInvitationLinkModal';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

type Guest = {
    id: string;
    name: string;
    phone: string;
    email: string;
    specialNeeds: string;
    confirmed: boolean;
};

export default function GuestListPage() {
    const [guests, setGuests] = useState<Guest[]>([]);
    const [isAddGuestModalOpen, setIsAddGuestModalOpen] = useState(false);
    const [isAddGuestSearchOpen, setIsAddGuestSearchOpen] = useState(false);
    const [isEditGuestModalOpen, setIsEditGuestModalOpen] = useState(false);
    const [selectedGuest, setSelectedGuest] = useState<Guest | null>(null);
    const { eventData, eventId, setEventId, organizations } = useData();

const confirmedCount = Array.isArray(guests) ? guests.filter(guest => guest.confirmed).length : 0;
const unconfirmedCount = Array.isArray(guests) ? guests.filter(guest => !guest.confirmed).length : 0;
const specialNeedsCount = Array.isArray(guests) ? guests.filter(guest => guest.specialNeeds && guest.specialNeeds.trim() !== '').length : 0;

    const stats = [
        { name: 'Kommer', stat: confirmedCount.toString(), borderColor: '#219653', shadowColor: '#219653' },
        { name: 'Kommer ikke', stat: unconfirmedCount.toString(), borderColor: '#F35252', shadowColor: '#F35252' },
        { name: 'Allergier', stat: specialNeedsCount.toString(), borderColor: '#3B4058', shadowColor: '#3B4058' },
    ];

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(guests);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Gjesteliste");
    
        // Skap en midlertidig URL og last ned filen
        XLSX.writeFile(wb, "gjesteliste_"+eventData.title+".xlsx");
    };

    const openAddGuestModal = () => {
        setIsAddGuestModalOpen(true);
    };

    const openGuestDetails = (guest: Guest) => {
        setSelectedGuest(guest);
        setIsEditGuestModalOpen(true);
    };

    const openAddGuestSearch = () => {
        setIsAddGuestSearchOpen(true);
    };

    useEffect(() => {
        setGuests(eventData.guestList);
    }, [eventData]);


    const handleToggleConfirmation = async (guestToToggle: Guest) => {
        const updatedGuests = guests.map(guest => 
            guest.id === guestToToggle.id ? { ...guest, confirmed: !guest.confirmed } : guest
        );
    
        const docRef = doc(db, "events", eventId);
        await updateDoc(docRef, { guestList: updatedGuests });
    
        setGuests(updatedGuests);
    };

    const sortedGuests = [...guests].sort((a, b) => Number(a.confirmed) - Number(b.confirmed));

    // -----------------------------------------------------------------------------------------------------------------------------
    // Invitasjoner
    // -----------------------------------------------------------------------------------------------------------------------------
    interface Invitation {
        id: string;
        invitationName: string;
        eventId: string;
        description: string;
        // Du kan legge til flere felt her basert på din invitasjonsstruktur
    }


    // Add modal    
    const [invitationModalOpen, setInvitationModalOpen] = useState(false);
    const [newInvitation, setNewInvitation] = useState({ id: '', name: '', description: ''});
    const [editingInvitation, setEditingInvitation] = useState({ id: '', invitationName: '', description: ''});
    const [invitationCount, setInvitationCount] = useState(0);
    const [invitations, setInvitations] = useState<Invitation[]>([]);
    const [currentInvitationId, setCurrentInvitationId] = useState<string | null>(null);
    const [currentInvitation, setCurrentInvitation] = useState<Invitation | null>(null);
    // Delete Modal
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    /* const [invitationToDelete, setInvitationToDelete] = useState<Invitation>(); */

    // Edit modal
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
    const baseInvitationLink = 'https://events.dity.no/guest-invitation/';
    /* const baseInvitationLink = 'http://localhost:3000/guest-invitation'; */ 
    /*const [baseInvitationLink, setBaseInvitationLink] = useState('http://localhost:3000/guest-invitation');*/    
    const [invitationLink, setInvitationLink] = useState(baseInvitationLink + currentInvitationId);

    useEffect(() => {
        const getInvitations = async () => {
            const fetchedInvitations = await fetchData(eventId);
            setInvitations(fetchedInvitations);
        };

        console.log("Eventid: ", eventId)
        
        getInvitations();
    }, [invitationCount, eventId]);


    const fetchData = async (eventId: string): Promise<Invitation[]> => {
        try {
            // Oppretter en spørring for å filtrere invitasjoner basert på eventId
            const q = query(collection(db, "invitations"), where("eventId", "==", eventId));
    
            // Utfører spørringen og henter dokumentene
            const querySnapshot = await getDocs(q);
            const invitations: Invitation[] = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data() as Omit<Invitation, 'id'>;
                invitations.push({ id: doc.id, ...data });
            });
    
            console.log("Hentede invitasjoner:", invitations);
            return invitations;
        } catch (error) {
            console.error("Det oppstod en feil under henting av invitasjoner", error);
            // Håndter eventuelle feil her
            return [];
        }
    };

    const openNewInvitation = () => {
        setInvitationModalOpen(true);
    };

    const handleAddInvitation = async () => {

        console.log("Lager invitasjon:", newInvitation);

        if (!newInvitation.name) {
            console.log("Invitasjonens navn kan ikke være tomt");
            return;
        }
    
        const invitationData = {
            invitationName: newInvitation.name,
            eventId: eventId,
            description: newInvitation.description,
        };
    
        try {
            // Legger til den nye invitasjonen i Firestore
            const docRef = await addDoc(collection(db, "invitations"), invitationData);
            console.log("Invitasjon lagret med ID:", docRef.id);
    
            // Lukker modalen og nullstiller newInvitation
            handleCloseAddInvitation();
    
        } catch (error) {
            console.error("Det oppstod en feil under lagring av invitasjonen", error);
            // Håndter eventuelle feil her
        }
        
        setInvitationCount(count => count + 1);
    };

    // Close AddInvitiation modal
    const handleCloseAddInvitation = () => {
        setInvitationModalOpen(false); 
        setNewInvitation({ id: '', name: '', description: ''});
    };


    const handleUpdateInvitation = async (updatedInvitation: Invitation) => {
        console.log("Oppdaterer invitasjon:", updatedInvitation);
    
        if (!updatedInvitation.invitationName) {
            console.log("Invitasjonens navn kan ikke være tomt");
            return;
        }
    
        const invitationData = {
            invitationName: updatedInvitation.invitationName,
            description: updatedInvitation.description,
            // Inkluder andre felt som trenger å bli oppdatert
        };
    
        try {
            // Referanse til det spesifikke dokumentet i Firestore
            const docRef = doc(db, "invitations", updatedInvitation.id);
    
            // Oppdaterer dokumentet med de nye dataene
            await updateDoc(docRef, invitationData);
            console.log("Invitasjon oppdatert med ID:", updatedInvitation.id);
    
            // Lukker modalen eller utfører andre oppfølgingshandlinger
    
        } catch (error) {
            console.error("Det oppstod en feil under oppdatering av invitasjonen", error);
            // Håndter eventuelle feil her
        }
    
        setInvitationCount(count => count + 1);
    };

    const openEditInvitation = (invitationId: string) => {
        const temp = invitations.find(invitation => invitation.id === invitationId);
        console.log("Temp: ", temp)
        
        if (temp) {
            setCurrentInvitation(temp); // Sett den aktuelle invitasjonen hvis den finnes
            
            setIsEditModalOpen(true);    // Åpne modalen
        } else {
            // Håndter tilfelle hvor ingen invitasjon med gitt id finnes
            console.error('Ingen invitasjon funnet med id:', invitationId);
            // Du kan også sette noen form for brukervennlig feilmelding her
        }
    };

    const closeEditInvitation = () => {
        setIsEditModalOpen(false);
        setEditingInvitation({...editingInvitation, invitationName: 'TULL', description: 'TULL'});
    };


    // Funksjon for å slette en invitasjon
    const handleDeleteInvitation = async () => {
        if (!currentInvitationId) return;

        try {
            await deleteDoc(doc(db, "invitations", currentInvitationId));
            console.log("Invitasjon slettet:", currentInvitationId);
            
            // Oppdaterer listen etter sletting
            setInvitationCount(count => count + 1);
        } catch (error) {
            console.error("Det oppstod en feil under sletting av invitasjonen", error);
        }
        setIsDeleteModalOpen(false);
    };

    const openDeleteModal = (invitationId: string) => {
        setCurrentInvitationId(invitationId);
        setIsDeleteModalOpen(true);
    };


    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
    };


    const handleLinkCreation = (invitationId: string) => {
        /*  https://events.dity.no/invitation/eventId*/
        setIsCopyModalOpen(true);
        setInvitationLink(baseInvitationLink + `/` + invitationId )   
    };

    const previewPage = (invitationId : string) => {
        window.open(baseInvitationLink + `/` + invitationId, '_blank');
    };


    const handleInviteGuests = () => {
        console.log("Logikk skal komme seinere")
    }; 
    





    // -----------------------------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------------------
    

    return (
        <div className="flex bg-gray-100">
            {isAddGuestModalOpen?<AddGuestModal isOpen={isAddGuestModalOpen} onClose={() => setIsAddGuestModalOpen(false)}></AddGuestModal>:""}
            {isAddGuestSearchOpen?<AddGuestSearch isOpen={isAddGuestSearchOpen} onClose={() => setIsAddGuestSearchOpen(false)}></AddGuestSearch>:""}
            {isEditGuestModalOpen && selectedGuest ? <EditGuestModal selectedGuest={selectedGuest} isOpen={isEditGuestModalOpen} onClose={() => setIsEditGuestModalOpen(false)} /> : ""}
            <div className="flex-1">  
                <div className='m-4'>        
                    <button
                        type="button"
                        /* className="inline-flex squish-button ml-4 items-center rounded-md bg-[#257E7B] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                            */
                        className='buttonSecondaryGreen ml-2 squish-button'
                        onClick={openAddGuestModal}
                    >
                        <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Legg til gjest
                    </button>
                    <button
                        type="button"
                        /* className="inline-flex squish-button ml-2 items-center rounded-md bg-[#257E7B] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                            */
                        className='buttonSecondaryGreen ml-2 squish-button'
                        onClick={openAddGuestSearch}
                    >
                        <MagnifyingGlassIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                        {organizations[0].name}
                    </button>
                    <button
                        type="button"
                        /* className="inline-flex squish-button ml-2 items-center rounded-md bg-gray-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                            */
                        className='buttonTernaryGray ml-2 squish-button'
                    >
                        <DocumentDuplicateIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                        Kopier lenke
                    </button>
                    <button
                        type="button"
                        onClick={openNewInvitation}         
                        className='buttonSecondaryGreen ml-2 squish-button'
            
                    >
                        <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Ny invitasjon
                    </button>
                    <GuestMenu/>  
                </div>
                <AddInvitationModal
                    newInvitation={newInvitation}
                    setNewInvitation={setNewInvitation}
                    isOpen={invitationModalOpen}
                    setIsOpen={setInvitationModalOpen}
                    handleAddInvitation={handleAddInvitation}
                    closeButton={handleCloseAddInvitation}
                />            
                <DeleteModal
                    isOpen={isDeleteModalOpen}
                    setIsOpen={setIsDeleteModalOpen}
                    handleDeleteInvitation={handleDeleteInvitation}
                    closeButton={closeDeleteModal}
                    modalTitle='Slett Invitasjon'
                    modalText='Er du sikker på at du vil slette denne invitasjonen?'
                />

                <EditInvitationModal
                    isOpen={isEditModalOpen}
                    setEditInvitation={setEditingInvitation}
                    setIsOpen={setIsEditModalOpen}
                    invitation={currentInvitation}
                    closeButton={closeEditInvitation}
                    handleUpdateInvitation={handleUpdateInvitation}
                />

                <CopyInvitationLinkModal
                    invitationLink={invitationLink}
                    isOpen={isCopyModalOpen}
                    setIsOpen={setIsCopyModalOpen}
                />
                <ul role="list" className="m-4 divide-y divide-gray-200  bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                    <h3 className="text-base font-semibold leading-6 text-gray-900 p-4 text-xl">Invitasjoner</h3>
                    {invitations.length > 0 ? (
                        <ul role="list" className="divide-y divide-gray-100 bg-white p-4 shadow-sm rounded-md">
                            {invitations.map((invitation) => (
                                <li key={invitation.id} className="flex items-center justify-between gap-x-6 py-5">
                                    <div className="min-w-0">
                                        <div className="flex items-start gap-x-3">
                                            <p className="text-sm font-semibold leading-6 text-gray-900">{invitation.invitationName}</p>
                                        </div>
                                        {/* Du kan legge til flere detaljer om invitasjonen her */}
                                    </div>
                                    <div className="flex flex-none items-center gap-x-4">
                                        {/* Her kan du legge til handlinger for hver invitasjon, som å vise, redigere, eller slette */}
                                        {/* Eksempel: */}
                                        <button 
                                            /* className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                              */
                                             className='buttonTernaryWhiteBorder' 
                                            /* onClick={() => openEditModal(invitation.id)} */
                                            onClick={() => previewPage(invitation.id)}

                                        >
                                            Forhåndsvis
                                        </button>
                                        {/*Fersken   bg-[#EF9A84] */}
                                        {/*Grønn     bg-[#257E7B]  */}
                                        {/*Grå       bg-[#3B4058] */}
                                        <button 
                                            /*className="inline-flex items-center gap-x-2 rounded-md bg-[#3B4058] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"*/                                            onClick={() => handleInviteGuests()}
                                            className='buttonTernaryGray'
                                        >
                                            Send til gjester
                                        </button>
                                        
                                        <button 
                                            /* className="inline-flex items-center gap-x-2 rounded-md bg-[#257E7B] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                            */
                                            className='buttonSecondaryGreen'
                                            onClick={() => handleLinkCreation(invitation.id)}
                                            /* onClick={() => openInvitationLink(invitation.id)} */
                                        >
                                            Se Invitasjonslenke
                                        </button>
                                        <Menu as="div" className="relative flex-none">
                                            <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                                <span className="sr-only">Åpne alternativer</span>
                                                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                            </Menu.Button>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                                {/* Redigeringsknapp */}
                                                <Menu.Item>
                                                    {({ active }) => (
                                                    <button
                                                        onClick={() => openEditInvitation(invitation.id)}
                                                        className={`${
                                                        active ? 'bg-gray-50' : ''
                                                        } block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900`}
                                                    >
                                                        Rediger
                                                    </button>
                                                    )}
                                                </Menu.Item>
                                                {/* Dupliseringsknapp */}
                                                <Menu.Item>
                                                    {({ active }) => (
                                                    <button
                                                        /* onClick={() => duplicateInvitation(invitation.id)} */
                                                        className={`${
                                                        active ? 'bg-gray-50' : ''
                                                        } block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900`}
                                                    >
                                                        Dupliser
                                                    </button>
                                                    )}
                                                </Menu.Item>
                                                {/* Sletteknapp */}
                                                <Menu.Item>
                                                    {({ active }) => (
                                                    <button
                                                        onClick={() => openDeleteModal(invitation.id)}
                                                        className={`${
                                                        active ? 'bg-red-400' : ''
                                                        } block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900`}
                                                    >
                                                        Slett
                                                    </button>
                                                    )}
                                                </Menu.Item>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                                                        

                                        {/* <button 
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400 sm:ml-3 sm:w-auto"
                                            onClick={() => openDeleteModal(invitation.id)}
                                        >
                                            Slett
                                        </button> */}
                                        {/* Flere knapper eller menyelementer kan legges til her */}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="text-center p-4">
                            <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                    d="M3 7v10c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2z" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                    d="M3 7l9 6 9-6" />
                            </svg>
                            <h3 className="mt-2 text-sm font-semibold text-gray-900">Ingen invitasjoner</h3>
                            <p className="mt-1 text-sm text-gray-500">
                                Her kan du opprette invitasjoner til ditt arrangement.
                                <br />
                                Begynn ved å lage en ny invitasjon
                            </p>
                            <div className="mt-6">
                                {/* Eventuelle handlinger eller knapper */}
                            </div>
                        </div>
                    )}
                </ul>     
                <ul role="list" className="m-4 divide-y divide-gray-200 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                    <h3 className="text-base font-semibold leading-6 text-gray-900 p-4 text-xl">Gjesteliste</h3>
                    {/* Eksisterende SVG og tekst her */}
            
                    {sortedGuests.map((guest, index) => (
                        <motion.li
                            key={guest.id}
                            layout
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            className={`relative cursor-pointer hover:bg-orange-50 flex justify-between items-center px-2 py-3 sm:px-6 ${guest.confirmed ? "bg-green-100" : "bg-white"}`}
                            onClick={() => openGuestDetails(guest)}
                        >
                            {/* Visning av gjestens navn og detaljer */}
                            <div className="flex flex-1 min-w-0 gap-x-4">
                                <div className="flex flex-col">
                                    <p className="text-sm font-semibold leading-6 text-gray-900">
                                        {guest.name}
                                    </p>
                                    <p className="text-xs text-gray-500">
                                        {guest.phone}
                                    </p>
                                    <p className="text-xs text-gray-500">
                                        {guest.email}
                                    </p>

                                    <p className="text-xs text-gray-500">
                                        Spesielle behov: {guest.specialNeeds || 'Ingen'}
                                    </p>
                                </div>

                            </div>
                            <div className="flex items-center">
                                <span className={`mr-2 text-sm ${guest.confirmed ? 'text-green-600' : 'text-orange-600'}`}>
                                    {guest.confirmed ? "Bekreftet" : "Ikke bekreftet"}
                                </span>
                                <div className="flex-none pr-8 pt-1" onClick={(e) => e.stopPropagation()}>
                                    <Switch
                                        checked={guest.confirmed}
                                        onChange={() => handleToggleConfirmation(guest)}
                                        className={classNames(
                                        guest.confirmed ? 'bg-[#257E7B]' : 'bg-gray-200',
                                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none')}
                                    >
                                        <span className="sr-only">Endre status</span>
                                        <span
                                            className={classNames(
                                                guest.confirmed ? 'translate-x-5' : 'translate-x-0',
                                                'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                            )}
                                        />
                                    </Switch>
                                </div>
                            </div>
                            <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                        </motion.li>
                    ))}
                </ul>                        
            </div>
            <div className="flex-2 text-center px-8">
                {guests.length === 0 ? (
                // Vis denne delen hvis det ikke er noen gjester
                <div className="text-center">
                    {/* SVG og tekster for "Ingen gjester" */}
                    <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        {/* SVG path her */}
                    </svg>
                    <h3 className="mt-2 text-sm font-semibold text-gray-900">Ingen gjester</h3>
                    <p className="mt-1 text-sm text-gray-500">Begynn med å opprette en ny invitasjon, eller legg til gjest manuelt</p>
                </div>
                ) : (
                // Vis denne delen hvis det er gjester
                <div>
                    <dl className="mt-5 gap-5 pt-8">
                        {stats.map((item) => (
                            <div 
                                key={item.name} 
                                className="mb-8 overflow-hidden rounded-lg bg-white py-5 shadow sm:p-6"
                                style={{
                                    borderRadius: '8.64px',
                                    border: '4.32px solid ' + item.borderColor,
                                    boxShadow: `-4.32px 4.32px 0px 5.4px ${item.shadowColor}`,
                                }}
                            >
                                <div className="flex">
                                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                                <dt className="truncate ml-2 text-sm font-medium text-gray-500 pt-2 pl-4">{item.name}</dt>
                                </div>
                            </div>
                        ))}
                    </dl>
                </div>
                )}      
            </div>
        </div>
    );
}