import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import AlertCard from '../components/Alert/AlertCard'; // Oppdatert sti
import { useData } from '../DataContext';
import { helix } from 'ldrs'
helix.register("event-animation")

interface AlertPageProps {
    open: boolean;
    onClose: () => void;
}

  export default function AlertPage({ open, onClose }: AlertPageProps) {
    const { userData } = useData();
    const alerts = userData.alerts
    
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <div className="fixed inset-0" />
  
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll py-6 shadow-xl bg-[#F09C84]">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                        <event-animation
                        size="40"
                        speed="3" 
                        color="white" 
                        ></event-animation>
                          <Dialog.Title className="text-base font-semibold leading-6 text-white">
                          
                            <h1 style={{
                            fontFamily: 'Poppins',
                            fontSize: '24px',
                            fontWeight: 500,
                            }}>
                           Du har {userData.dityPoints} DityPoints
                            </h1>
                          </Dialog.Title>
                          
                          <div className="ml-3 flex h-7 items-center">
                          <button
  type="button"
  className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
  onClick={() => onClose()} // Endret her
>
  <span className="absolute -inset-2.5" />
  <span className="sr-only">Close panel</span>
  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
</button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
            
                            

        </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }