import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Dialog } from '@headlessui/react'
import { useState, useEffect } from 'react'
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Authentication from "./pages/AuthenticationPage";  
import NewUserPage from "./pages/NewUserPage";
import LandingPage from "./pages/LandingPage";
import SideBar from "./components/SideBar"
import NavBar from "./components/NavBar"
import GuestInvitePage from "./pages/Guest/GuestInvitePage";
//@ts-ignore
import EventPage from "./pages/Events/EventPage.tsx";
import { EnvelopeIcon, BellIcon } from '@heroicons/react/24/solid'
import InboxPage from "./pages/InboxPage";
import AlertPage from "./pages/AlertPage";
import TeamPage from "./pages/TeamPage";
import HomePage from "./pages/HomePage";
import BouncePage from "./pages/BouncePage";
import CreateEventPage from "./pages/CreateEventPage";
import ThankYouPage from "./pages/Guest/ThankYouPage";
import BookDemoPage from "./pages/BookDemoPage";
import GearPage from "./pages/GearPage";
import CalendarPage from "./pages/CalendarPage";
import GPTPage from "./pages/GPTPage";
import { useData } from './DataContext';
import EventsOverview from "./components/Events/EventsOverview";
import DityPointsPage from "./pages/DityPointsPage";
import AboutPage from "./pages/AboutPage";
import AuthRedirectionPage from "./pages/AuthRedirectionPage";
import TemplatePage from "./pages/TemplatePage";
import ConfigPage from "./pages/ConfigPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsOfServicePage from "./pages/TermsOfServicePage";
import { jelly } from 'ldrs'
import { useDityPointsFlight } from './DityPointsContext';
import DityPointsBar from "./components/DityPointsBar";
import '../src/styles/designs.css'
jelly.register('generate-dp')



function App() {

  

  // Eventuelle funksjoner for håndtering av klikk på ikonene
  const { userData, setUserId } = useData();
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showDityPointsModal, setShowDityPointsModal] = useState(false);
  const [showNavBar, setShowNavBar] = useState(true);
  const [showSideBar, setShowSideBar] = useState(false);

  const { startFlight } = useDityPointsFlight();

  const handleAppClick = (event: any) => {
    const { clientX, clientY } = event;
    startFlight(clientX, clientY);
    userData.dityPoints += 10;
  };
  
  const uid = localStorage.getItem("uid");
  useEffect(() => {
     setUserId(uid);
  }, []);

  const handleOpenModal = () => {
    setShowDityPointsModal(true);
  };

    const protectedRoutes = [
      '/profile',
      '/auth-redirection',
      '/new-user',
      '/landingpage',
      '/event-configuration',
      '/inbox',
      '/team',
      '/home',
      '/create-event',
      '/all-events',
      '/gear',
      '/calendar',
      '/GPT',
      '/template-event',
    ];

    // Define paths where you don't want the sidebar or navbar to show
    const hideNavForPaths = [
      '/guest-invitation/:eventId', // Use a more generic matching if needed
      '/guest-invitation/thank-you'
    ];

    const currentHref = window.location.href;
    const relativeUrl = new URL(currentHref).pathname;
    const isProtectedRoute = protectedRoutes.some(route => relativeUrl.startsWith(route));

    // Function to determine if the nav should be hidden
    const shouldHideNav = hideNavForPaths.some((p) => relativeUrl.startsWith(p.replace(/:\w+/, '')));
    
  useEffect(() => {

    if (isProtectedRoute && !uid) {
      window.location.href = '/authentication';
    }

  if (userData.personalInfo.name === "Ola Nordmann" && isProtectedRoute) {
    setShowNavBar(false);
  }
  if (userData.configured && isProtectedRoute) {
   setShowSideBar(true);
   setShowNavBar(false);
  }
  }, [userData]);


    if (userData.personalInfo.name === "Ola Nordmann" && uid) {
      return (
        <div className="bg-gray-100 flex w-full h-screen z-60">
            <div className="flex justify-center items-center w-full">
                <dity-loader size="120" color="#257e7b"></dity-loader>
            </div>
        </div>
    );
    }
  
   if (!userData.configured && uid && isProtectedRoute){
    return<>
    <NavBar />
    <ConfigPage />;
    </> 
   }
  const handleAlertClick = () => setShowAlertModal(true);

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  

  return (
    <>
     <Dialog open={showAlertModal} onClose={() => setShowAlertModal(false)}>
                    <AlertPage open={showAlertModal} onClose={() => setShowAlertModal(false)} />
                </Dialog>

                <Dialog open={showDityPointsModal} onClose={() => setShowDityPointsModal(false)}>
                    <DityPointsPage open={showDityPointsModal} onClose={() => setShowDityPointsModal(false)} />
                </Dialog>

          {false?
                <div className="fixed bottom-4 right-4">
        <button
          className="p-2 rounded-full bg-blue-500 text-white shadow-lg hover:bg-blue-700 transition-colors duration-300"
          onClick={handleAppClick} 
        >
          Få 10 DityPoints
        </button>
      </div>:""
          }

                
      <BrowserRouter>
      {/* {showSideBar ? <SideBar /> :""} 
      {showNavBar ? <NavBar />:""} */}
      {!shouldHideNav && (showSideBar ? <SideBar /> : "")}
      {!shouldHideNav && (showNavBar ? <NavBar /> : "")}

      {false?
      <DityPointsBar points={userData.dityPoints} onOpenModal={handleOpenModal} />
      :""}
      

      {!shouldHideNav && localStorage.getItem("uid") && userData?.eventInfo?.currentEvents?.length > 0 ? <div className="fixed top-0 right-0 p-4 flex justify-end z-10 bg-transparent">
          <Link to="/inbox">
            <div className="rounded-[10px] p-2 cursor-pointer bg-[#3B4058] hover:bg-[#2f3346] mr-2">
              <EnvelopeIcon className="h-6 w-6 text-[#F1F1F1] " />
            </div>
          </Link>
          <div 
            className="rounded-[10px] p-2 cursor-pointer bg-[#227E7B] hover:bg-[#277274] mr-12"
            onClick={handleAlertClick}
          >
            <BellIcon className="h-6 w-6 text-[#F1F1F1]" />
          </div>
        </div> : ""}
          {/*  
          hover:text-[#e0e0e0]
           hover:text-[#e0e0e0]
          */}
    
        <div className="flex bg-gray-100">
          <div className={classNames(localStorage.getItem("uid")?"mainPage":"", "flex-grow")}>
            <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutPage />}/>
            <Route path="/authentication" element={<Authentication />} />
            <Route path="/404" element={<BouncePage />} />        
            {/* <Route path="/guest-invitation/:eventId" element={<GuestInvitePage />} /> */}
            <Route path="/guest-invitation/:invitationId" element={<GuestInvitePage />}/>
            <Route path="/guest-invitation/thank-you" element={<ThankYouPage />}/>
            <Route path="/demo" element={<BookDemoPage />}/>
            <Route path="/terms-of-service" element={<TermsOfServicePage />}/>
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />}/>

            {/* Sider nedenfor krever innlogging */}
            <Route path="/profile" element={<Profile />} />
            <Route path="/auth-redirection" element={<AuthRedirectionPage />} />
            <Route path="/new-user" element={<NewUserPage />} />
            <Route path="/landingpage" element={<LandingPage />} />
            <Route path="/event-configuration/:eventId" element={<EventPage />} />
            <Route path="/inbox" element={<InboxPage />} />
            <Route path="/team" element={<TeamPage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/create-event" element={<CreateEventPage />} />
            <Route path="/all-events" element={<EventsOverview />} />
            <Route path="/gear" element={<GearPage />} />
            <Route path="/calendar" element={<CalendarPage />} />
            <Route path="/GPT" element={<GPTPage />} />
            <Route path="/template-event/:templateId" element={<TemplatePage />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;