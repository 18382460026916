import { useState, useEffect } from 'react'
import { useData } from '../DataContext';
import NotConfigured from '../components/team/NotConfigured';
import Overview from '../components/team/Overview';

import '../styles/designs.css';

interface GearItem {
  id: string;
  name: string;
}

interface InvoiceInfo {
  address: string;
}

interface Location {
  address: string;
  city: string;
  lat: string; // Vurder å endre type til number hvis det lagrer koordinater
  long: string; // Vurder å endre type til number hvis det lagrer koordinater
  zip: string;
}

interface Team {
  id: string;
  name: string;
  users?: string[]; // Antatt at dette er en array av bruker-IDs
}

interface Subscription {
  active: boolean;
  type: string;
}

export interface Organization {
  id: string;
  admins: string[]; // Array av bruker-IDs for admins
  gear: GearItem[];
  invoiceInfo: InvoiceInfo;
  locations: Location[];
  logo: string;
  name: string;
  orgNum: string; // Organisasjonsnummer
  subscription: Subscription;
  teams: Team[];
}
  
  export default function TeamPage() {
    const { organizations } = useData();
    const configured = organizations?true:false;
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);

    console.log(organizations)

    useEffect(() => {
      // Setter den første organisasjonen som standardvalg
      if (organizations.length > 0) {
          setSelectedOrganization(organizations[0]);
      }
  }, [organizations]);

    const handleOrganizationChange = (event: any) => {
      const orgId = event.target.value;
      const selectedOrg = organizations.find((org: any) => org.id === orgId);
      setSelectedOrganization(selectedOrg);
    };

    return (
      <>
        <div> {/* Div with specifications for the "Main page" imported from designs.css */}
          <div>
            {organizations?.length>1?
            <div>
              <label htmlFor="organization-select">Velg Organisasjon:</label>
              <select name="organizations" id="organization-select" onChange={handleOrganizationChange} value={selectedOrganization?.id || ""}>
                <option value="">Velg en organisasjon</option>
                {organizations?.map((org: any) => (
                  <option key={org.id} value={org.id}>{org.name}</option>
                ))}
              </select>
            </div>:""}
            {configured? 
            <Overview selectedOrganization={selectedOrganization} />
            : <NotConfigured />
            }
          </div>
        </div>
      </>
    )
  }
  