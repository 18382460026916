import React, {  useState, useEffect } from 'react';
import { SVGProps, ForwardRefExoticComponent, RefAttributes } from 'react';
import { PlusIcon, InboxStackIcon, UserGroupIcon, PuzzlePieceIcon, HeartIcon, ChatBubbleLeftIcon, AcademicCapIcon, PresentationChartLineIcon, SpeakerWaveIcon } from '@heroicons/react/20/solid';
import { db } from '../../firebase'; // Importer din Firebase konfigurasjon
import { collection, getDocs } from 'firebase/firestore';
import CreateTemplateEvent from './CreateTemplateEvent'

type EventTypeIcons = {
    [key: string]: ForwardRefExoticComponent<SVGProps<SVGSVGElement> & RefAttributes<SVGSVGElement>>;
  };

// Anta at disse ikonene representerer forskjellige event typer
const eventTypeIcons: EventTypeIcons = {
    Sosialt: HeartIcon,
    Teambuilding: UserGroupIcon,
    Workshop: PuzzlePieceIcon,
    Nettverking: ChatBubbleLeftIcon,
    Faglig: AcademicCapIcon,
    Kompetansebygging: PresentationChartLineIcon,
    Markedsføring: SpeakerWaveIcon
  };

  interface Event {
    id: string;
    title: string;
    description: string;
    callToAction: string;
    eventType: string[];
    perks: string[];
    imageUrls: string[];
  }

export default function TemplateCard() {
    const [templateEvents, setTemplateEvents] = useState<Event[]>([]);
    const [selectedId, setSelectedId] = useState(null);
    const [isCreateTemplateEventOpen, setIsCreateTemplateEventOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

    const openModalWithEvent = (event: Event) => {
      setSelectedEvent(event);
      setIsCreateTemplateEventOpen(true);
    };

    useEffect(() => {
        const fetchEvents = async () => {
          const eventCollectionRef = collection(db, 'templateEvents');
          const eventData = await getDocs(eventCollectionRef);
          setTemplateEvents(eventData.docs.map(doc => ({ id: doc.id, ...doc.data() }) as Event));
        };
    
        fetchEvents();
      }, []);

  const handleCardClick = (id: any) => {
    setSelectedId(selectedId === id ? null : id);
  };

  return (
    <>
    
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {templateEvents.map((event) => {
        const EventIcon = eventTypeIcons[event.eventType[0]] || PlusIcon;
        const isSelected = event.id === selectedId;
        
        return (
            <li key={event.id} className={`col-span-1 divide-y divide-gray-200 rounded-lg mb-auto bg-white shadow cursor-pointer hover:shadow-md group ${isSelected ? 'scale-105' : ''}`} onClick={() => handleCardClick(event.id)}>
            <div className={`flex w-full items-center justify-between space-x-6 p-6  `}>
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="truncate text-md font-bold text-[#EF9B84]">{event.title}</h3>
                {event.eventType.map((type: any, index: any) => (
              <span key={index} className="inline-flex flex-shrink-0 items-center rounded-full bg-salmon-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                {type}
              </span>
            ))}
              </div>
              <p className="mt-1 truncate text-sm text-gray-500">{event.callToAction}</p>
            </div>
            <EventIcon className="h-10 w-10 flex-shrink-0 text-[#EF9B84] group-hover:animate-pulse" aria-hidden="true" />
          </div>
          <div>
          {isSelected && (
            <>
                
              <div className="p-4 grid grid-cols-2 gap-8">
              <img src={event.imageUrls[0]} className="rounded-xl"></img>
                            <div className="p-4 bg-gray-50 rounded-lg shadow-sm">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">Høydepunkter:</h3>
                <ul className="list-disc list-inside space-y-1 text-gray-700">
                    {event.perks.map((perk, index) => (
                    <li key={index} className="text-sm">{perk}</li>
                    ))}
                </ul>
                </div>
              </div>
              <p className="p-4">{event.description}</p>
              </>
            )}

            <div className="-mt-px flex divide-x divide-gray-200">
              <div className="flex w-0 flex-1">
                <a
                  onClick={(e) => {
                    e.stopPropagation();
                    openModalWithEvent(event);
                }}
                  className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                >
                  <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  Opprett event
                </a>
              </div>
              <div className="-ml-px flex w-0 flex-1">
                <a
                onClick={(e) => {
                  e.stopPropagation();
                  window.location.href="/template-event/"+event.id
              }}
                  className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                >
                  <InboxStackIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  Detaljert visning
                </a>
              </div>
            </div>
          </div>
        </li>
      );
    })}
    </ul>
    {selectedEvent && (
          <CreateTemplateEvent
            isOpen={isCreateTemplateEventOpen}
            onClose={() => setIsCreateTemplateEventOpen(false)}
            templateEvent={selectedEvent} // Passer det valgte eventet til modalen
          />
        )}
    </>
  )
}
