// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage"; // Legg til denne linjen

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDzlACJvD6YpSy2u-dpElIiAKbOXz3JgUY",
  authDomain: "dityevents.firebaseapp.com",
  projectId: "dityevents",
  storageBucket: "dityevents.appspot.com",
  messagingSenderId: "667305791465",
  appId: "1:667305791465:web:af4adff4ecb547bb2ac578",
  measurementId: "G-3EH2YVFX8E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); // Legg til denne linjen
const provider = new GoogleAuthProvider();

export { db, app, auth, provider, storage };

