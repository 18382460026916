import {
    ArrowPathIcon,
    CloudArrowUpIcon,
    Cog6ToothIcon,
    CheckBadgeIcon,
    SparklesIcon,
    UserGroupIcon,
    ArrowPathRoundedSquareIcon
  } from '@heroicons/react/20/solid'
  import demoImage from '../../images/demoImage.png';
  import personIcon3 from '../../images/icons/person3.png'

  
  const features = [
    {
      name: 'Ferdige templates.',
      description: 'Få inspirasjon til sosiale aktiviteter med 2-klikks eventmaler',
      icon: CheckBadgeIcon,
    },
    {
      name: 'Med kraft fra AI.',
      description: 'Gjør planleggingen mer effektiv enn noen gang med våre AI-funksjoner.',
      icon: SparklesIcon,
    },
    {
      name: 'Sømløs integrasjon.',
      description: 'Få full kontroll på alt som skjer med integrasjon til Microsoft Teams og Google Kalender.',
      icon: ArrowPathRoundedSquareIcon,
    },
    {
      name: 'Planlegg mer flere.',
      description: 'Samle kollegaer for å skape et magisk arrangement i samarbeid.',
      icon: UserGroupIcon,
    },
  ]
  
  export default function FeatureSection() {
    return (
      <div className="bg-gray-100 py-12 sm:py-16 grid grid-cols-1 lg:grid-cols-2 m-8">
        <div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-base font-semibold leading-7 text-[#267E7A]" >Alt på ett sted</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">For en bedre jobbhverdag</p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Skap en hverdag fylt med glede, spenning og samhold - med Dity Events.
            </p>
          </div>
        </div>
        <div className="relative overflow-hidden pt-16">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <img
              src={demoImage}
              alt="App screenshot"
              className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
              width={2432}
              height={1442}
            />
            <div className="relative" aria-hidden="true">
              <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-gray-100 pt-[7%]" />
            </div>
          </div>
        </div>
        </div>
        <div className="mx-auto max-w-7xl px-6 ">
        <img
            className="mx-28 h-40 mb-16"
            src={personIcon3}
            alt="Your Company"
          />
          <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:px-8 lg:max-w-none lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="inline font-semibold text-gray-900">
                  <feature.icon className="absolute left-1 top-1 h-5 w-5 text-[#267E7A]" aria-hidden="true" />
                  {feature.name}
                </dt>{' '}
                <dd className="inline">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    )
  }
  