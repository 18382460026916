import React, { useEffect, useState, FormEvent, Fragment } from 'react';
import { XMarkIcon, CalendarIcon, PaperClipIcon, TagIcon, UserCircleIcon } from '@heroicons/react/20/solid';
import { collection, query, where, onSnapshot, orderBy, doc, updateDoc, addDoc, getDoc, arrayUnion } from 'firebase/firestore';
import '../../styles/inboxPage.css';
import '../../styles/designs.css';
import { useData } from '../../DataContext';
import EmptyMessages from './EmptyMessages';
import { db, app } from "../../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { SparklesIcon } from '@heroicons/react/24/outline';
import { mirage } from 'ldrs'
mirage.register("message-animation")


function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface MessageCorrespondenceProps {
  currentConversationId: string;
}

interface MessageWithUserInfo {
  id: string;
  senderId: string;
  text: string;
  timestamp: string;
  senderName?: string;
  imageUrl?: string;
}

interface AIResponse {
  generatedMessage: string[];
}


  function MessageCorrespondence({ currentConversationId }: MessageCorrespondenceProps) {
  const [newMessage, setNewMessage] = useState('');
  const { eventData, eventId, setEventData, setEventId, conversations, userData, userId } = useData();
  const [currentMessages, setCurrentMessages] = useState<MessageWithUserInfo[]>([]);
  const [subject, setSubject] = useState('');
  const [awaitingGeneration, setAwaitingGeneration] = useState(false);

  // Funksjon for å hente brukerinformasjon
async function fetchUser(senderId: any) {
  const docRef = doc(db, "users", senderId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const userData = docSnap.data();
    return {
      name: userData.personalInfo.name,
      imageUrl: userData.personalInfo.profilePicture
    };
  } else {
    return null;
  }
}


  // Oppdater useEffect i MessageCorrespondence
useEffect(() => {
  const fetchMessages = async () => {
    const currentConversation = conversations.find((conversation: any) => conversation.id === currentConversationId);
    if (currentConversation && currentConversation.messages) {
      setSubject(currentConversation.subject)
      const messagesWithUserInfo = await Promise.all(currentConversation.messages.map(async (message: any) => {
        const senderInfo = await fetchUser(message.senderId);
        return { ...message, senderName: senderInfo?.name, imageUrl: senderInfo?.imageUrl };
      }));
      setCurrentMessages(messagesWithUserInfo);
      setSubject(currentConversation.subject)
    } else {
      setCurrentMessages([]);
    }
  };

  if (currentConversationId) {
    fetchMessages();
  }
    setEventId(localStorage.getItem("currentEventId"))
}, [currentConversationId, conversations]);

const sendMessage = async (event: FormEvent) => {
  event.preventDefault();

  if (!newMessage.trim()) return; 

  try {
    const newMessageData = {
      senderId: userId,
      text: newMessage,
      timestamp: new Date().toISOString()
    };

    setNewMessage('');
    const conversationRef = doc(db, "conversations", currentConversationId);
    await updateDoc(conversationRef, {
      messages: arrayUnion(newMessageData),
      lastUpdated: new Date().toISOString()
    });

  } catch (error) {
    console.error("Error sending message:", error);
    // Her kan du vise en feilmelding til brukeren
  }
};


  function formatLastSeen(lastSeenDateTime: any) {
    if (!lastSeenDateTime) return "N/A";

    const lastSeenDate = new Date(lastSeenDateTime).getTime(); // Konverter til millisekunder
    const now = new Date().getTime(); // Konverter til millisekunder
    const timeDiff = now - lastSeenDate; // Differansen i millisekunder

    const minutes = Math.floor(timeDiff / 60000); // Konverter til minutter
    const hours = Math.floor(minutes / 60); // Konverter til timer
    const days = Math.floor(hours / 24); // Konverter til dager

    if (days > 0) {
        return `${days} dager siden`;
    } else if (hours > 0) {
        return `${hours} timer siden`;
    } else if (minutes > 0) {
        return `${minutes} minutter siden`;
    } else {
        return "Akkurat nå";
    }
}

const handleTriggerFunctionClick = async () => {
  setAwaitingGeneration(true);
  const functions = getFunctions(app, 'europe-west1');
  const triggerFunction = httpsCallable(functions, 'aiGenerateMessage');
  console.log(eventId)
  console.log(currentConversationId)
  console.log(userId)

  try {
      const result = await triggerFunction({ eventReferenceId: (localStorage.getItem('currentEventId')), conversationReferenceId: currentConversationId, userReferenceId: userId });
      const data = result.data as AIResponse; // Type-casting til AIResponse
      setNewMessage(data.generatedMessage.join('\n'));
      setAwaitingGeneration(false);
  } catch (error) {
      console.error('Error calling function:', error);
  }
};


  return (
    <div className="form-container">
      {currentMessages.length === 0 ? (
        <EmptyMessages />
      ) : ("")}
    


    <ul role="list" className="space-y-6">
      {currentMessages.map((message, index) => (
        <li key={index} className={`relative flex pb-4 ${message.senderId === userId ? 'message-sent' : 'message-received'}`}>
          <img
            src={message.imageUrl}
            alt=""
            className="h-12 w-12 flex-none rounded-full m-4"
          />
          <div className={`flex-auto rounded-xl p-3  ${message.senderId === userId ? 'message-sent-color' : 'message-received-color'}`}>
            <div className="flex justify-between">
              <div className=" font-semibold text-gray-900">
                {message.senderName}
              </div>
              <time dateTime={message.timestamp} className="text-sm text-gray-700">
                {formatLastSeen(message.timestamp)}
              </time>
            </div>
            <p className="mt-1 text-sm text-gray-900">{message.text}</p>
          </div>
        </li>
      ))}
    </ul>
        
    <div className="form-bottom relative">
    <form onSubmit={sendMessage} className="relative rounded-lg mt-12 bg-[#D9D9D9] m-2">
      <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-[#227E7B] focus-within:ring-1 focus-within:ring-[#227E7B] ">
        <label htmlFor="title" className="sr-only ">
          Emne
        </label>
        <input
          type="text"
          name="title"
          id="title"
          value={subject}
          className="block w-full border-0 pt-2 text-lg font-medium text-gray-400 focus:ring-0  bg-[#D9D9D9] mb-2"
          placeholder="Emne"
          disabled
        />
        <label htmlFor="description" className="sr-only">
          Beskrivelse
        </label>
        <textarea
          rows={2}
          name="description"
          id="description"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          className="block w-full resize-none border-0 py-0 text-gray-900 placeholder:text-gray-500 focus:ring-0 h-36 sm:text-sm sm:leading-6  bg-[#D9D9D9]"
          placeholder="Skriv en ny melding ..."
          defaultValue={''}
        />

        {/* Spacer element to match the height of the toolbar */}
        <div aria-hidden="true">
          <div className="py-2">
            <div className="h-2" />
          </div>
          <div className="h-px" />
          <div className="py-2">
            <div className="py-px">
              <div className="h-2" />
            </div>
          </div>
        </div>
      </div>

      

      <div className="absolute inset-x-px bottom-0">
       
        <div className="flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
          <div className="flex">
            <button
              type="button"
              className="group -my-2 -ml-2 inline-flex items-center rounded-full px-3 py-2 text-left text-gray-400"
            >
              <PaperClipIcon className="-ml-1 mr-2 h-5 w-5 group-hover:text-gray-500" aria-hidden="true" />
              <span className="text-sm italic text-gray-500 group-hover:text-gray-600">Legg til en fil</span>
            </button>
          </div>
          <div>
            <button
              onClick={handleTriggerFunctionClick}
              /* className="mr-4 inline-flex items-center rounded-md bg-[#3B4058] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                */
              className='buttonTernaryGray mr-4'
            >
               {/* {awaitingGeneration ? (
                <div role="status" className="flex justify-center items-center">
                Vennligst vent <message-animation
                  size="70"
                  speed="3" 
                  color="white" 
                ></message-animation>
                </div>
              ) : (
              <>
                <SparklesIcon className='w-5 h-5 text-white'></SparklesIcon>
                Generer melding
         
              </>
              )} */}
              <SparklesIcon className='w-3 h-3 text-white'></SparklesIcon>
                Generer melding
            </button>
            <button
              type="submit"
              /* className="rounded-md bg-[#227E7B] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                */ 
              className='buttonSecondaryGreen'
            >
              Send
            </button>
            
          </div>
        </div>
      </div>
    </form>
    </div>

    </div>
    
  );
}

export default MessageCorrespondence;
